// ** MUI Imports
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

// ** Helper Imports
import Colors from "../../Helper/Colors";

const CsButton = (props: LoadingButtonProps & { label: string }) => {
  // Props
  const { label, sx, variant, size }: any = props;

  return (
    <LoadingButton
      {...props}
      variant={variant}
      size={size ? size : "large"}
      sx={[
        sx,
        variant === "contained" && {
          background:
            "linear-gradient(180deg, rgba(57,200,40,1) 0%, rgba(40,157,26,1) 100%)",
        },
        variant === "outlined" && {
          border: `0.125rem solid ${Colors.green} !important`,
          color: Colors.green,
          "&:hover": {
            bgcolor: Colors.green,
            color: Colors.white,
          },
        },
        {
          "& .MuiLoadingButton-loadingIndicator": { color: Colors.white },
          borderRadius: "8px",
          position: "relative",
          zIndex: "2",
          // "&:after": {
          //   transition: "all .2s ease-out",
          //   content: '""',
          //   position: "absolute",
          //   left: "0.7rem",
          //   bottom: "-0.525rem",
          //   border: `0.125rem solid ${Colors.green}`,
          //   display: "inline-block",
          //   borderLeft: "0",
          //   borderTop: "0",
          //   borderBottomRightRadius: "8px",
          //   width: "calc(100% - 5px)",
          //   height: "calc(100% - 5px)",
          //   zIndex: "1",
          // },
          // "&:hover:after": {
          //   bottom: "0",
          //   left: "6px",
          //   width: "calc(100% - 12px)",
          //   height: "calc(100% - 35px)",
          // },
          transition: "all .3s ease-in-out",
          boxShadow: `rgba(255, 255, 255, 1) 6px 6px, rgb(31, 166, 15) 7px 7px`,
          "&:hover": {
            boxShadow: `none`,
          }
        },
      ]}
    >
      {label}
    </LoadingButton >
  );
};

export default CsButton;


// // ** MUI Imports
// import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

// // ** Helper Imports
// import Colors from "../../Helper/Colors";
// import { Box } from "@mui/material";

// const CsButton = (props: LoadingButtonProps & { label: string }) => {
//   // Props
//   const { label, sx, variant, size }: any = props;

//   return (
//     <Box sx={[
//       sx,
//       {
//         width: "fit-content",
//         "& .MuiLoadingButton-loadingIndicator": { color: Colors.white },
//         borderRadius: "8px",
//         position: "relative",
//         zIndex: "2",
//         "&:after": {
//           transition: "all 0.2s ease-out",
//           content: '""',
//           position: "absolute",
//           bottom: "-0.525rem",
//           border: `0.125rem solid ${Colors.green}`,
//           display: "inline-block",
//           borderLeft: "0",
//           borderTop: "0",
//           borderBottomRightRadius: "8px",
//           zIndex: "1",
//           left: "0.7rem",
//           width: "calc(100% - 5px)",
//           height: "calc(100% - 5px)",
//         },
//         "&:hover:after": {
//           bottom: "0",
//           left: "6px",
//           width: "calc(100% - 12px)",
//           height: "calc(100% - 35px)",
//         },
//         "&:hover .MuiLoadingButton-root": {
//           bgcolor: Colors.green,
//           color: Colors.white,
//           ...(variant === "outlined" && {
//             border: `0.125rem solid ${Colors.green} !important`,
//           }),
//         },
//       }]}>

//       <LoadingButton
//         {...props}
//         variant={variant}
//         size={size ? size : "large"}
//         sx={[
//           variant === "contained" && {
//             marginTop: "0",
//             background:
//               "linear-gradient(180deg, rgba(57,200,40,1) 0%, rgba(40,157,26,1) 100%)",
//           },
//           variant === "outlined" && {
//             marginTop: "0",
//             border: `0.125rem solid ${Colors.green} !important`,
//             color: Colors.green,
//           },
//           { borderRadius: "8px" }
//         ]}
//       >
//         {label}
//       </LoadingButton>
//     </Box>
//   );
// };

// export default CsButton;
