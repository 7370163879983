// ** Helper Imports
import Colors from "../../../../Helper/Colors";

// ** MUI imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";

const Quotes = () => {
  // Hooks
  const aboutUsData = useSelector(about_banner_data);

  return (
    <Box sx={{ position: "relative", my: "5rem" }}>
      <CsImgBox
        src={"/images/Pages/BlackPaper.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "10rem",
          bottom: "-3.2rem",
          display: { xs: "none", sm: "block" },
          zIndex: -1,
        }}
      />
      <Box sx={{ bgcolor: Colors.green }}>
        <Container sx={{ my: "5rem", py: "3rem" }}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={4} xl={6} sx={{ position: "relative" }}>
              <Fade direction="right" triggerOnce>
                <Box
                  component={"img"}
                  src={aboutUsData?.single_quote?.image && `${UrlHelper.imgPath}${aboutUsData?.single_quote?.image}`}
                  alt={
                    aboutUsData?.single_quote?.image_ALT_text
                      ? aboutUsData?.single_quote?.image_ALT_text
                      : "Quotes"
                  }
                  sx={{
                    position: { sm: "absolute" },
                    left: 0,
                    bottom: { sm: "-14rem", md: "-12.8rem" },
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Fade>
            </Grid>
            <Grid item xs={12} sm={6} lg={8} xl={6}>
              <Fade direction="left" triggerOnce>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  }}
                  component={"q"}
                >
                  {aboutUsData?.single_quote?.description}
                </Typography>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Quotes;
