// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const seoState: {
  seo_data: any;
  isLoading: boolean;
  logo_data: any;
  pages: any;
  msg: { open: boolean; message: string; isError: boolean };
  isOpen: boolean;
  isShow: boolean;
  searchItems : any[]
} = {
  seo_data: [],
  isLoading: false,
  logo_data: {},
  pages: [],
  msg: { open: false, message: "", isError: false },
  isOpen: false,
  isShow: false,
  searchItems : []
};

const SeoSlice = createSlice({
  name: "seo",
  initialState: seoState,
  reducers: {
    setSeoData(state: any, action: any) {
      state.seo_data = action.payload;
    },
    setLogoData(state: any, action: any) {
      state.logo_data = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    setPages(state: any, action: any) {
      state.pages = action.payload;
    },
    setMsg(state: any, action: any) {
      state.msg = action.payload;
    },
    setIsOpen(state: any, aciton: any) {
      state.isOpen = aciton.payload;
    },
    setIsShow(state: any, action: any) {
      state.isShow = action.payload;
    },
    setSearchItems(state: any, action: any) {
      state.searchItems = action.payload;
    },
  },
});

export const {
  setSeoData,
  setLoading,
  setLogoData,
  setPages,
  setMsg,
  setIsOpen,
  setIsShow,
  setSearchItems
}: any = SeoSlice.actions;

export default SeoSlice.reducer;

export const seo_data = (state: any) => state.seo.seo_data;

export const logo_data = (state: any) => state.seo.logo_data;

export const pages = (state: any) => state.seo.pages;

export const message = (state: any) => state.seo.msg;

export const is_loading = (state: any) => state.seo.isLoading;

export const is_open = (state: any) => state.seo.isOpen;

export const search_items = (state: any) => state.seo.searchItems;

export const is_show = (state: any) => state.seo.isShow;
