// Created by Shyam Radadiya
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from '@mui/material/styles';

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import {
  ratio_data,
  setRatioData,
} from "../../../../Store/Product/DetailsPage";
import { getProductRatio } from "../../../../Store/Product/DetailsPage/DetailsAction";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Marquee = () => {
  // State
  const [position, setPosition] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(window.scrollY);

  // Hooks
  const dispatch = useDispatch();
  const ratioData = useSelector(ratio_data);
  const directionRef = useRef<number>(1);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!mobileScreen) {
      const updateMarquee = () => {
        setPosition((prevPosition) => prevPosition + directionRef.current * 0.5 + directionRef.current);
      };

      const marqueeAnimation = setInterval(updateMarquee, 20);
      const handleWheel = (event: any) => {
        if (event.deltaY < 0) {
          directionRef.current = 1;
        } else if (event.deltaY > 0) {
          directionRef.current = -1;
        }
      };

      window.addEventListener("wheel", handleWheel);
      return () => {
        clearInterval(marqueeAnimation);
        window.removeEventListener("wheel", handleWheel);
      };
    }
  }, [mobileScreen]);

  useEffect(() => {
    if (mobileScreen) {
      const updateMarquee = () => setPosition((prevPosition) => prevPosition + directionRef.current * 1 + directionRef.current);
      const marqueeAnimation = setInterval(updateMarquee, 25);

      const handleScroll = () => {
        if (scrollY > window.scrollY) {
          directionRef.current = 1;
        } else if (scrollY < window.scrollY) {
          directionRef.current = -1;
        }
        setScrollY(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        clearInterval(marqueeAnimation);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [mobileScreen, scrollY]);

  useEffect(() => {
    if (contentRef.current) {
      const scrollWidth = contentRef.current.scrollWidth;
      const initialPosition = -(scrollWidth / 4); // Adjust this value to start from the center
      if (position === 0) {
        setPosition(initialPosition);
      } else if (Math.abs(position) >= scrollWidth / 2) {
        setPosition(0);
        dispatch(setRatioData([...ratioData, ...ratioData]));
      } 

      // set initial
      if (directionRef.current && !mobileScreen && position > 0 && position < 1) {
        setPosition(initialPosition);
      }

      if (directionRef.current && mobileScreen && position > 0 && position < 0.6) {
        setPosition(initialPosition);
      }

      contentRef.current.style.transform = `translateX(${position}px)`;
    }
  }, [position, ratioData]);

  useEffect(() => {
    if (contentRef.current) {
      const initialPosition = -(contentRef.current.scrollWidth / 4);
      contentRef.current.style.transform = `translateX(${initialPosition}px)`;
      setPosition(initialPosition);
    }
  }, [ratioData]);

  useEffect(() => {
    if (Array.isArray(ratioData) && ratioData?.length === 0) {
      getProductRatio(dispatch);
    }
  }, [dispatch, ratioData]);

  const handleClickWrapper = (item: string | undefined) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (item) {
      navigate(`/product/${item}`);
    }
  };

  return (
    <>
      {Array.isArray(ratioData) && ratioData?.length > 0 && (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            my: 5,
            bgcolor: Colors.bgcolor,
            color: Colors.green,
          }}
        >
          <Box
            id="marqueeContainer"
            ref={contentRef}
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            {[...ratioData, ...ratioData, ...ratioData, ...ratioData, ...ratioData, ...ratioData, ...ratioData, ...ratioData]?.filter((f: any) => f.priceRatio)?.map((item: any, index: number) => {
              return (
                <Box
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 3, my: 1 }}
                  key={index} onClick={handleClickWrapper(item.url_slug)}
                >
                  <Typography
                    sx={{ mr: 1, fontSize: "1.4rem", whiteSpace: "nowrap", color: item?.priceRatio > 0 ? 'green' : 'red' }}
                  >
                    {`${item?.product_name ? item.product_name : 'Unknown'} (${item?.priceRatio ? item?.priceRatio.toFixed(2) : '0.00'}%)`}
                  </Typography>
                  <CsIcon
                    icon={
                      item?.priceRatio > 0
                        ? "icon-park-solid:up-one"
                        : "icon-park-solid:down-one"
                    }
                    fontSize={'1.5rem'}
                    color={item?.priceRatio > 0 ? Colors.green : "red"}
                  />
                  <Divider
                    orientation="vertical"
                    sx={{ borderWidth: ".0625rem", height: "2.1875rem", ml: 2 }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Marquee;
