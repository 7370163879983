// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const bannerState: { bannerData: any } = {
  bannerData: {},
};

const BannerSlice = createSlice({
  name: "banner",
  initialState: bannerState,
  reducers: {
    getProductBanner(state: any, action: any) {
      state.bannerData = action.payload;
    },
  },
});

export const { getProductBanner }: any = BannerSlice.actions;

export default BannerSlice.reducer;

export const product_banner_data = (state: any) =>
  state.product.banner.bannerData;
