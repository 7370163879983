// ** Third Party Imports
import axios from "axios";

// ** Helper Imports
import UrlHelper from "../Url";
import AppUtils from "../AppUtils";

const apiCall: any = async (data: any) => {
  let response: any;

  const localstoreData: any = AppUtils.getLocalStorage("VORA_F_CUST");

  const header = {
    Authorization: `Bearer ${localstoreData?.token ?? ""}`,
    ...(data.isMultipart && { "Content-Type": "multipart/form-data" }),
  };

  try {
    response = await axios({
      method: data.method,
      url: data?.isCustom
        ? `${UrlHelper.serverUrl}/customer/api/${data.url}`
        : `${UrlHelper.serverUrl}/api/${data.url}`,
      headers: header,
      data: data.data,
    });
  } catch (e: any) {
    console.log(":::service catch::::", e);

    response = e;
  }

  if (response?.status === 200 && response?.data?.status) {
    return response.data;
  } else {
    console.log(
      response?.response?.data?.message &&
        response?.response?.data?.message !== undefined
        ? response?.response?.data?.message
        : "Something went wrong !"
    );
  }

  if (data.isLogin) return response?.data;
  else return response?.response?.data;
};

export default apiCall;
