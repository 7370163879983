// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const bannerState: { bannerData: any } = {
  bannerData: {},
};

const BannerSlice = createSlice({
  name: "banner",
  initialState: bannerState,
  reducers: {
    getBannerData(state: any, action: any) {
      state.bannerData = action.payload;
    },
  },
});

export const { getBannerData, setLoading }: any = BannerSlice.actions;

export default BannerSlice.reducer;

export const banner_data = (state: any) => state.home.banner.bannerData;
