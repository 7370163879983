// ** React Imports
import React, { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Third party Imports
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsButton from "../../../../components/CsButton";

// ** Store Imports
import { pages } from "../../../../Store/SEO";
import { blog_data } from "../../../../Store/Blogs";
import { getBlogs } from "../../../../Store/Blogs/BlogsAction";

// Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const BlogCards = () => {
  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages);
  const allBlogs: any = useSelector(blog_data);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isCurrBlg, setIsCurrBlg] = React.useState(false)
  const [filteredBlogs, setFilterdBlogs] = React.useState<any[]>([])

  useEffect(() => {
    getBlogs(
      { search: "", status: 0, is_publish: true, startToken: 1, endToken: 4 },
      dispatch,
      true
    );
  }, [id]);

  useEffect(() => {
    if (allBlogs && allBlogs.length > 0) {
      if (allBlogs.some((items: any) => items?.url_slug === id)) {
        setIsCurrBlg(true)
        setFilterdBlogs(allBlogs.filter((item: any) => item?.url_slug !== id))
      } else {
        setIsCurrBlg(false)
        setFilterdBlogs(allBlogs)
      }
    }
  }, [allBlogs])

  return (
    <Box sx={{ width: '80%', m: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ color: Colors.text, fontSize: { xs: "2rem", sm: "3rem" } }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            Similar Posts
          </Fade>
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 5,
          backgroundImage: `linear-gradient(90deg, rgba(40,157,26,1) 0%, rgba(255,255,255,1) 80%)`,
          width: "100%",
          height: "2px",
        }}
      />
      <Grid container spacing={4} sx={{ mb: 4, overflow: "hidden" }}>
        {Array.isArray(filteredBlogs) &&
          filteredBlogs?.length > 0 &&
          filteredBlogs?.map((item: any, index: number) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Fade triggerOnce direction="up">
                  <Box
                    sx={{
                      border: `0.0625rem solid ${Colors.green}`,
                      borderRadius: "1.25rem",
                      p: "1rem",
                      bgcolor: Colors.bgcolor,
                      overflow: "hidden",
                      minHeight: "400px"
                    }}
                  >
                    <Fade triggerOnce direction="up">
                      <Box
                        component={"img"}
                        src={item?.blog_image && `${UrlHelper.imgPath}${item?.blog_image}`}
                        alt={
                          item?.blog_image_alt_text
                            ? item?.blog_image_alt_text
                            : item?.blog_title
                        }
                        sx={{
                          width: "100%",
                          height: "15rem",
                          borderRadius: "1.25rem 1.25rem 0 0",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          my: 1,
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "0.25rem",
                            bgcolor: Colors.green,
                            color: Colors.white,
                            py: "0.2rem",
                            px: "0.4rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          {item?.tag_id?.tag_name}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CsIcon
                            icon={"uil:calender"}
                            fontSize={15}
                            color={Colors.descTxt}
                          />
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              ml: 0.5,
                              color: Colors.descTxt,
                            }}
                          >
                            {moment(item?.createdAt).format("MMMM-DD-YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: Colors.black,
                            fontSize: "1.2rem",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item?.blog_title}
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.descTxt,
                            fontSize: "0.9rem",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            my: 1,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item?.blog_description,
                          }}
                        />
                        <Zoom triggerOnce>
                          <Button
                            size="small"
                            sx={{
                              mt: 1,
                              color: Colors.black,
                              textTransform: "none",
                            }}
                            onClick={() => navigate(`/blogs/${item?.url_slug}`)}
                            endIcon={<CsIcon icon={"ep:right"} />}
                          >
                            Read More
                          </Button>
                        </Zoom>
                      </Box>
                    </Fade>
                  </Box>
                </Fade>
              </Grid>
            );
          })}
      </Grid>

      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Zoom triggerOnce>
          <CsButton
            label="View All"
            variant="contained"
            sx={{ mb: 2, textTransform: "none" }}
            onClick={() =>
              navigate(
                `/${allPages?.find((item: any) => item?.default_key === "Blogs")
                  ?.url_slug
                }`
              )
            }
          />
        </Zoom>
      </Box>
    </Box>
  );
};

export default BlogCards;
