// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Custom Imports
import CsButton from "../../../../components/CsButton";
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { quality_data } from "../../../../Store/HomePage/Quality";
import { fetchQualityData } from "../../../../Store/HomePage/Quality/QualityAction";
import { pages } from "../../../../Store/SEO";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const Quality = () => {
  // Hooks
  const data = useSelector(quality_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allPages = useSelector(pages);

  useEffect(() => {
    fetchQualityData(dispatch);
  }, []);

  return (
    <>
      <Box
        sx={{
          fontSize: { xs: "1.5rem", sm: "2rem" },
          fontWeight: 600,
          textAlign: "center",
          my: 4,
        }}
      >
        <Typography
          component={Fade}
          delay={1e3}
          cascade
          damping={1e-1}
          triggerOnce
          sx={{
            "& > span": { fontSize: { xs: "1.5rem", sm: "2rem" }, fontWeight: 600 },
            whiteSpace: "unset !important",
          }}
        >
          {data?.title}
        </Typography>
      </Box>
      <Box sx={{ position: "relative" }}>
        <CsImgBox
          src={"/images/Pages/BrownDot.png"}
          imgSx={{ width: { xs: "1rem", sm: "2rem" }, height: "auto" }}
          boxSx={{ right: "35%", top: "5rem" }}
        />
        <CsImgBox
          src={"/images/Pages/BlackPaper.png"}
          imgSx={{ width: { xs: "1.5rem", sm: "3rem" }, height: "auto" }}
          boxSx={{
            position: "relative",
            left: "1rem",
            width: "max-content",
          }}
        />
        <Fade direction="left" triggerOnce className="quality_img">
          <CsImgBox
            src={data?.image && `${UrlHelper.imgPath}${data?.image}`}
            alt={
              data?.thumbnail_image_ALT_text
                ? data?.thumbnail_image_ALT_text
                : "Quality"
            }
            imgSx={{ width: "auto", height: { xs: "10rem", sm: "20rem" } }}
            boxSx={{
              position: "relative",
              left: { xs: "-0.3125rem", sm: "-1rem" },
            }}
          />
        </Fade>
        <CsImgBox
          src={"/images/Pages/Leaf3.png"}
          imgSx={{ width: { xs: "3rem", sm: "5rem" }, height: "auto" }}
          boxSx={{ right: 0, top: 0 }}
        />

        <Box
          className="quiality_content_wrap"
          sx={{
            width: { xs: "auto", md: "30rem" },
            position: "relative",
            px: 4,
            mt: { xs: "0", sm: "0", md: "-10rem" },
            mr: { xs: "auto", sm: "10%", md: "20%" },
            ml: "auto",
          }}
        >
          <CsImgBox
            src={"/images/Pages/BlurLeaf.png"}
            imgSx={{ width: "7rem", height: "auto" }}
            boxSx={{
              right: { xs: "-2rem", md: "-5rem" },
              top: "-1rem",
              display: { xs: "none", md: "block" },
            }}
          />
          <Fade direction="right" triggerOnce>
            <Typography
              sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
            >
              {data?.sub_title}
            </Typography>
          </Fade>
          <Fade direction="up" triggerOnce>
            <Typography
              sx={{
                fontWeight: 400,
                color: Colors.text,
                fontSize: "0.9rem",
                mt: 2,
              }}
            >
              {data?.description}
            </Typography>
          </Fade>
          <Zoom triggerOnce>
            <CsButton
              label={"Join Now"}
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={() =>
                navigate(
                  `/${allPages?.find(
                    (item: any) => item?.default_key === "ContactUs"
                  )?.url_slug
                  }`
                )
              }
            />
          </Zoom>
        </Box>
      </Box>
    </>
  );
};

export default Quality;
