// ** React Imports
import { useEffect } from "react";

// ** Components Imports
import BlogList from "./BlogList/BlogList";

// ** Third Party Imports
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";

// ** Store Imports
import { fetchBlogSetting } from "../../Store/Blogs/BlogsAction";
import { blog_setting_data } from "../../Store/Blogs";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";

const Blogs = () => {
  // Hooks
  const data = useSelector(blog_setting_data);
  const seoData = useSelector(seo_data);
  const dispatch = useDispatch();

  const userIp = async () => {
    const res = await AppUtils.getIp();

    return res;
  };

  useEffect(() => {
    if (Object.keys(data).length <= 0) {
      fetchBlogSetting(dispatch);
    }
  }, [data]);

  useEffect(() => {
    AppUtils.setVisitorData(userIp(), "blog_page", "Blog", "BLOG_PAGE");
    AppUtils.setDailyVisitor({ pageKey: 'blog' });
  }, []);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "Blogs" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <meta name="keywords" content={item?.meta_keywords} />
                )}
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <script type="application/ld+json">
                    {item?.meta_schema}
                  </script>
                )}
              </Helmet>
            )
        )}
      {
        data && data?.blogs && data?.blogs?.title && data?.blogs?.image &&
        <CsHeader
          title={data?.blogs?.title}
          src={`${UrlHelper.imgPath}${data?.blogs?.image}`}
        />
      }
      <BlogList />
    </>
  );
};

export default Blogs;
