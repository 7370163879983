// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getFAQData } from ".";

export const fetchFAQData = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getFaqList",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getFAQData(res?.data));
  }
};
