// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

// ** Third Party Imports
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsTextField from "../../components/CsTextField";
import CsButton from "../../components/CsButton";

// ** Store Imports
import { logo_data, setMsg } from "../../Store/SEO";
import { changePassword } from "../../Store/Auth/AuthAction";

// ** Icon Imports
import CsIcon from "../../components/Icon";

// ** Helper Imports
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";
import Colors from "../../Helper/Colors";

interface Values {
  new_password: string;
  confirm_password: string;
}

const ChangePassword = () => {
  // State
  const [showPass, setShowPass] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  // Hooks
  const logoData = useSelector(logo_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues: Values = {
    new_password: "",
    confirm_password: "",
  };

  const schema = yup.object({
    new_password: yup
      .string()
      .trim()
      .required("Please enter new password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        "New password Must contain a minimum of 8 characters, at least 1 uppercase character, 1 lowercase character, 1 number, and 1 special case character"
      ),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password")], "Passwords must match")
      .required("Please enter confirm password"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: Values) => {
      setLoading(true);
      const res = await changePassword({ new_password: values.new_password.trim(), confirm_password: values.confirm_password.trim(), token: id }, dispatch);
      if (res?.status) {
        navigate("/login");
      }
      setLoading(false);
    },
  });

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          sx={{
            px: 4,
            py: 2,
            borderRadius: "0.5rem",
            width: { sm: "22rem" },
            textAlign: "center",
            boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
            zIndex: "99",
          }}
        >
          <Box
            component={"img"}
            src={
              AppUtils.checkValue(logoData?.logo) && logoData?.logo
                ? `${UrlHelper.imgPath}${logoData?.logo}`
                : "/images/icons/LOGO.svg"
            }
            alt={
              AppUtils.checkValue(logoData?.logo_alt_text)
                ? logoData?.logo_alt_text
                : "Vora Spices Logo"
            }
            sx={{ width: "10rem", height: "auto", mb: 2 }}
          />
          <Typography
            variant="h5"
            sx={{
              mb: 1,
              letterSpacing: "0.18px",
              fontWeight: 600,
              fontSize: "1.5rem !important",
              textAlign: "left",
            }}
          >
            Set New Password 🔒
          </Typography>
          <Typography
            sx={{
              mb: 2,
              letterSpacing: "0.18px",
              fontWeight: 600,
              textAlign: "left",
              color: Colors.descTxt,
            }}
          >
            Your new password must be different from previously used passwords
          </Typography>
          <Box component={"form"} noValidate onSubmit={formik.handleSubmit}>
            <CsTextField
              autoFocus
              fullWidth
              sx={{ mb: 2 }}
              id="new_password"
              placeholder="Enter New Password"
              label="New Password"
              type={showPass ? "text" : "password"}
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <CsIcon
                        icon={
                          showPass ? "mdi:eye-outline" : "mdi:eye-off-outline"
                        }
                        fontSize={30}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.new_password &&
                Boolean(formik.errors.new_password)
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
            />
            <CsTextField
              autoFocus
              fullWidth
              sx={{ mb: 2 }}
              id="confirm_password"
              placeholder="Enter Confirm Password"
              label="Confirm Password"
              type={isShow ? "text" : "password"}
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setIsShow(!isShow)}>
                      <CsIcon
                        icon={
                          isShow ? "mdi:eye-outline" : "mdi:eye-off-outline"
                        }
                        fontSize={30}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.confirm_password &&
                Boolean(formik.errors.confirm_password)
              }
              helperText={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
            />
            <CsButton
              label="Set New Password"
              type="submit"
              sx={{ textTransform: "none", mb: 1 }}
              fullWidth
              variant="contained"
              loading={loading}
            />
          </Box>
        </Card>
      </Box>
      <Box
        component={"img"}
        src="/images/icons/VoraSpices.svg"
        alt="Logo Image"
        sx={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: "50%",
          top: "50%",
          transform: "translateY(-50%)",
          filter: "blur(4px)",
        }}
      />
    </Box>
  );
};

export default ChangePassword;
