// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const blogState: {
  allBlogs: any;
  hasMore: boolean;
  isLoading: boolean;
  detailsData: Object;
  blogSetting: Object;
  record_count: number
} = {
  allBlogs: [],
  hasMore: true,
  isLoading: false,
  detailsData: {},
  blogSetting: {},
  record_count: 0
};

const BlogSlice = createSlice({
  name: "blog",
  initialState: blogState,
  reducers: {
    fetchBlogs(state: any, action: any) {
      const { data, isInitial } = action.payload
      state.allBlogs = isInitial ? data : [...state.allBlogs, ...data];
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getBlogDetails(state: any, action: any) {
      state.detailsData = action.payload;
    },
    getBlogSetting(state: any, action: any) {
      state.blogSetting = action.payload;
    },
    setRecordCount(state: any, action: any) {
      state.record_count = action.payload;
    },
  },
});

export const {
  setLoading,
  fetchBlogs,
  setHasMore,
  setIsLoading,
  getBlogSetting,
  getBlogDetails,
  setRecordCount
}: any = BlogSlice.actions;

export default BlogSlice.reducer;

export const blog_data = (state: any) => state.blog.allBlogs;

export const hash_more = (state: any) => state.blog.hasMore;

export const all_products = (state: any) => state.blog.allBlogs;

export const is_loading = (state: any) => state.blog.isLoading;

export const blog_details_data = (state: any) => state.blog.detailsData;

export const blog_setting_data = (state: any) => state.blog.blogSetting;

export const blog_record_count = (state: any) => state.blog.record_count;
