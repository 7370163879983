// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getAboutBannerData } from ".";
export const fetchAboutBannerData = async (dispatch: any) => {
  const res = await apiCall({
    url: "aboutUs/getAboutUsData",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getAboutBannerData(res?.data));
  }

  return res;
};
