// ** MUI Imports
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// ** Store Imports
import { logo_data, pages } from "../../../Store/SEO";
import { is_open, setIsOpen } from "../../../Store/SEO";

// ** Icon Imports
import CsIcon from "../../../components/Icon";

// ** Styles Imports
import "../../../index.css";

// ** Helper Imports
import Colors from "../../../Helper/Colors";
import AppUtils from "../../../Helper/AppUtils";
import UrlHelper from "../../../Helper/Url";

const Sidebar = () => {
  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages);
  const logoData = useSelector(logo_data);
  const dispatch = useDispatch();
  const isOpen = useSelector(is_open);

  return (
    <>
      <Drawer
        open={isOpen}
        PaperProps={{
          sx: {
            width: "100%",
            height: "-webkit-fill-available",
            backgroundColor: "unset",
          },
        }}
        anchor={"top"}
        transitionDuration={800}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
            backgroundColor: "#fff",
          }}
        >
          <Box
            component={"img"}
            src={
              AppUtils.checkValue(logoData?.logo) && logoData?.logo
                ? `${UrlHelper.imgPath}${logoData?.logo}`
                : "/images/icons/LOGO.svg"
            }
            alt={
              AppUtils.checkValue(logoData?.logo_alt_text)
                ? logoData?.logo_alt_text
                : "Vora Spices Logo"
            }
            sx={{ width: "10rem", height: "auto", my: 1, cursor: "pointer" }}
            onClick={() => {              
              const homeRoute = allPages && allPages.length > 0 && allPages.find((pages: any) => pages.default_key === 'Home').url_slug              
              if(homeRoute != "/"){
                navigate(`/${homeRoute}`)
              }else {
                navigate(`/`)
              }
              dispatch(setIsOpen(false));
            }}
          />
          <IconButton onClick={() => dispatch(setIsOpen(false))}>
            <CsIcon icon={"iconamoon:close"} fontSize={35} />
          </IconButton>
        </Box>
        <List sx={{ bgcolor: "#ffffffb8", height: "100vh", overflow: "auto" }}>
          {Array.isArray(allPages) &&
            allPages?.length > 0 &&
            allPages?.map((item: any, i: number) => {
              return (
                <ListItem key={i}>
                  <ListItemButton
                    sx={{
                      transition: "0.3s ease",
                      py: 0,
                      width: "100%",
                      "&:hover": {
                        pl: 4,
                        bgcolor: "transparent",
                        "& > p": {
                          backgroundPosition: "0 100%",
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(
                        item?.url_slug === "/" ? "/" : `/${item?.url_slug}`
                      );
                      dispatch(setIsOpen(false));
                    }}
                  >
                    <Typography
                      sx={[
                        {
                          background: `linear-gradient(to right, ${Colors.green}, ${Colors.green} 50%, ${Colors.green} 50%, ${Colors.text} 50%)`,
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundSize: "200% 100%",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",

                          // backgroundPosition: index ? "0 100%" : "100%",

                          backgroundPosition: "100%",
                          transition: "background-position 500ms ease",
                          fontWeight: 700,
                          fontSize: { xs: "1.5625rem", sm: "2.5rem" },

                          // "&:hover": {
                          //   // backgroundPosition: "100% !important",

                          //   backgroundPosition: "0 100%",
                          // },
                        },
                      ]}

                      // onMouseEnter={() => setIndex(true)}
                      // onMouseLeave={() => setIndex(false)}
                    >
                      {item?.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
