// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Third Party Imports
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Custom Imports
import Journy from "./Components/Journy";
import Signature from "./Components/Signature";
import Welcome from "./Components/Welcome";
import Flavors from "./Components/Flavors";
import Products from "./Components/Products";
import Quality from "./Components/Quality";
import Certificates from "./Components/Certificates";
import Process from "./Components/Process";
import MarketSpecific from "./Components/MarketSpecific";
import FAQ from "./Components/FAQ";
import Marquee from "./Components/Marquee";

// ** Store Imports
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";

const Home = () => {
  const seoData = useSelector(seo_data);

  const userIp = async () => {
    const res = await AppUtils.getIp();

    return res;
  };

  useEffect(() => {
    AppUtils.setVisitorData(userIp(), "home_page", "Home", "HOME_PAGE");
    AppUtils.setDailyVisitor({ pageKey: 'home' });
  }, []);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "Home" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
                  <meta name="keywords" content={item?.meta_keywords} />
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <script type="application/ld+json">
                    {item?.meta_schema}
                  </script>
                )}
              </Helmet>
            )
        )}
      <Box>
        <Box sx={{ overflowX: "hidden" }}>
          <Welcome />
          <Process />
          <Journy />
          <Marquee />
          <Flavors />
          <MarketSpecific />
          <Signature />
          <Products />
          <Quality />
          <Certificates />
          <FAQ />
        </Box>
      </Box>
    </>
  );
};

export default Home;
