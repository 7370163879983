// ** Third Party Imports
import { combineReducers } from "redux";

// ** Slice Imports
import BannerSlice from "./Banner";
import Certificate from "./Certificate";
import FAQ from "./FAQ";
import Flavors from "./Flavors";
import Journey from "./Journey";
import Process from "./Process";
import Signature from "./Signature";
import Quality from "./Quality";
import Product from "./Product";

// Combine the reducers
const HomePageReducer: any = combineReducers({
  banner: BannerSlice,
  certificate: Certificate,
  faq: FAQ,
  flavors: Flavors,
  journey: Journey,
  process: Process,
  product: Product,
  quality: Quality,
  signature: Signature,
});

export default HomePageReducer;
