// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

// ** Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  isMobile,
  isTablet,
  isDesktop,
  isMacOs,
  isSmartTV,
  osName,
  isIPad13,
  isIPhone13,
} from "react-device-detect";
import { publicIpv4 } from "public-ip";
import {jwtDecode} from 'jwt-decode'

// ** Custom Imports
import CsTextField from "../../components/CsTextField";
import CsButton from "../../components/CsButton";

// ** Store Imports
import { logo_data, setMsg } from "../../Store/SEO";
import { fetchCusomerById, loginUser } from "../../Store/Auth/AuthAction";

// ** Icon Imports
import CsIcon from "../../components/Icon";

// ** Helper Imports
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";

// ** Helper Imports
import Colors from "../../Helper/Colors";

interface Value {
  email: string;
  password: string;
}

const Login = () => {
  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  // Hooks
  const logoData = useSelector(logo_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values: Value = {
    email: "",
    password: "",
  };

  const Schema = yup.object({
    email: yup
      .string()
      .required("Please enter email")
      .email("Please enter valid email"),
    password: yup.string().required("Please enter password"),
  });

  const formik = useFormik({
    initialValues: values,
    validationSchema: Schema,
    onSubmit: async (values: Value) => {
      setLoading(true);
      const res = await loginUser(
        {
          ...values,
          device_type: isMobile
            ? "mobile"
            : isTablet
              ? "tablet"
              : isSmartTV
                ? "smart tv"
                : isDesktop
                  ? "desktop"
                  : isMacOs
                    ? "mac"
                    : isIPad13
                      ? "iPad"
                      : isIPhone13
                        ? "iPhone"
                        : "",
          ip_address: ipAddress,
          operating_system: osName,
          panel: "Frontend",
        },
        dispatch
      );

      if (res?.status) {
        dispatch(setMsg({ open: true, message: res?.message, isError: false }));
        navigate(`/`);
        setLoading(false);
      } else {
        dispatch(setMsg({ open: true, message: res?.message, isError: true }));
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const getAsyncIpAddress = async () => {
      try {
        const pIP = await publicIpv4();
        setIpAddress(pIP || "");
      } catch (e: any) {
        setIpAddress("");
      }
    };

    getAsyncIpAddress();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = decodeURIComponent(searchParams.get("token") || '');

    const fetchAutoUser = async () => {
      if (token) {
        try {
          const decoded: any = jwtDecode(token);
          if (decoded) {
            const res = await fetchCusomerById({ id: decoded?.id, token: token }, dispatch);
            console.log('res',res)
            res?.status && navigate('/');
          }
        } catch (error) {
          console.error('Token decoding error:', error);
        }
      }
    }

    token && fetchAutoUser();
  }, [navigate, dispatch]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          sx={{
            px: 4,
            py: 2,
            borderRadius: "0.5rem",
            width: { sm: "22rem" },
            textAlign: "center",
            boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
            zIndex: "99",
          }}
        >
          <Box
            component={"img"}
            src={
              AppUtils.checkValue(logoData?.logo) && logoData?.logo
                ? `${UrlHelper.imgPath}${logoData?.logo}`
                : "/images/icons/LOGO.svg"
            }
            alt={
              AppUtils.checkValue(logoData?.logo_alt_text)
                ? logoData?.logo_alt_text
                : "Vora Spices Logo"
            }
            sx={{ width: "10rem", height: "auto", mb: 2 }}
          />
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              letterSpacing: "0.18px",
              fontWeight: 600,
              fontSize: "1.5rem !important",
              textAlign: "left",
            }}
          >
            {`Login 👋🏻`}
          </Typography>
          <Box
            component={"form"}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <CsTextField
              autoFocus
              fullWidth
              sx={{ mb: 4 }}
              id="email"
              placeholder="Enter email"
              label="Email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <CsTextField
              autoFocus
              fullWidth
              id="password"
              placeholder="Enter password"
              label="Password"
              type={showPass ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <CsIcon
                        icon={
                          showPass ? "mdi:eye-outline" : "mdi:eye-off-outline"
                        }
                        fontSize={30}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Typography
              sx={{
                color: Colors.green,
                cursor: "pointer",
                textAlign: "right",
                my: 2,
              }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </Typography>
            <CsButton
              fullWidth
              label="Login"
              type="submit"
              variant="contained"
              sx={{ mb: 2, textTransform: "none" }}
              // onClick={() => formik.handleSubmit()}
              loading={loading}
            />
          </Box>
        </Card>
      </Box>
      <Box
        component={"img"}
        src="/images/icons/VoraSpices.svg"
        alt="Logo Image"
        sx={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: "50%",
          top: "50%",
          transform: "translateY(-50%)",
          filter: "blur(4px)",
        }}
      />
    </Box>
  );
};

export default Login;
