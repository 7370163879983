// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getCustomSolutionData } from ".";

export const fetchCustomSolutionData = async (dispatch: any) => {
  const res = await apiCall({
    url: "customSolutions/getCustomSolutionsData",
    data: {},
    method: "get",
  });

  if (res?.status) {
    dispatch(getCustomSolutionData(res?.data));
  }

  return res;
};
