// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const certificateState: { certificateData: any } = {
  certificateData: {},
};

const CertificateSlice = createSlice({
  name: "certificate",
  initialState: certificateState,
  reducers: {
    getCertificateData(state: any, action: any) {
      state.certificateData = action.payload;
    },
  },
});

export const { getCertificateData, setLoading }: any = CertificateSlice.actions;

export default CertificateSlice.reducer;

export const certificate_data = (state: any) =>
  state.home.certificate.certificateData;
