// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";

// ** Store Immports
import { certificate_data } from "../../../../Store/HomePage/Certificate";
import { fetchCertificateData } from "../../../../Store/HomePage/Certificate/CertificateAction";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";
import Colors from "../../../../Helper/Colors";

const randomcolor = [
  "#D07335",
  "#525458",
  "#C28324",
  "#50B944",
  "#D26549",
  "#7A934E",
  "#ECA84B",
  "#23252A",
];

const Certificates = () => {
  // Hooks
  const data = useSelector(certificate_data);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCertificateData(dispatch);
  }, []);

  return (
    <Box sx={{ position: "relative", mt: 8 }}>
      <Box
        sx={{
          fontSize: { xs: "1.5rem", sm: "2rem" },
          fontWeight: 600,
          textAlign: "center",
          mt: 4,
          mb: 6,
        }}
      >
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          Our Certifications
        </Fade>
      </Box>
      <CsImgBox
        src={"/images/Pages/BlackPaper.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{
          position: "absolute",
          width: "fit-content",
          pl: { sm: 4 },
          display: { xs: "none", sm: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/YellowDotGrp.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{ top: "4rem", right: 0 }}
      />
      <CsImgBox
        src={"/images/Pages/Leaf1.png"}
        imgSx={{ width: "5rem", height: "auto" }}
        boxSx={{ top: "12rem", left: 0, display: { xs: "none", sm: "block" } }}
      />
      <CsImgBox
        src={"/images/Pages/BlackPaper.png"}
        imgSx={{ width: "2rem", height: "auto" }}
        boxSx={{
          bottom: 0,
          left: "4rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/Leaf3.png"}
        imgSx={{ width: "6rem", height: "auto" }}
        boxSx={{
          bottom: 0,
          right: "0rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          px: { xs: 4, md: 12 },
          gap: "1.2rem",
        }}
      >
        {Array.isArray(data) &&
          data?.length > 0 &&
          data?.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: {
                  xs: "100%",
                  sm: "calc(50% - 16px)",
                  md: "calc(25% - 16px)",
                  lg: "calc(20% - 16px)",
                },
                mb: 3,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Fade direction="up" triggerOnce style={{ height: "100%" }}>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: Colors.green,
                        color: Colors.white,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                        fontSize: "1rem",
                      },
                    },
                    arrow: {
                      sx: {
                        color: Colors.green,
                      },
                    },
                  }}
                  arrow
                  placement="top"
                  title={item?.logo_title || ""}
                >
                  <Card
                    sx={{
                      textAlign: "center",
                      borderRadius: "50%",
                      p: 3,
                      mx: "auto",
                      cursor: "pointer",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                      transition: "all ease-in-out 0.3s",
                      "&:hover": {
                        transform: "scale(1.03)",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                        color: Colors.white,
                      },
                    }}
                    onClick={() =>
                      window.open(
                        `${UrlHelper.imgPath}${item.pdf}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <Box
                      component="img"
                      src={item?.logo && `${UrlHelper.imgPath}${item?.logo}`}
                      alt={item?.logo_ALT_text || item?.logo_title}
                      sx={{
                        width: "9rem",
                        height: "9rem",
                        bgcolor: Colors.white,
                        objectFit: "contain",
                        m: "auto",
                      }}
                    />
                  </Card>
                </Tooltip>
              </Fade>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Certificates;
