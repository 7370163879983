// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getCertificateData } from ".";

export const fetchCertificateData = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getCertificationsList",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getCertificateData(res?.data));
  }
};
