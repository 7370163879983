// ** React Imports
import { useState } from "react";

// ** MUI imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helpers Imports
import UrlHelper from "../../../../Helper/Url";
import AppUtils from "../../../../Helper/AppUtils";

const EthicalSources = () => {
  // State
  const [isMore, setIsMore] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  // Hooks
  const aboutUsData = useSelector(about_banner_data);

  // child card
  const ChildCard = ({ index }: { index: number }) => {
    return (
      <Box
        sx={{
          borderRadius: "3rem",
          p: "1rem",
          pb: "3rem",
          border: "0.0625rem solid gray",
          position: "relative",
          mb: "3rem",
          bgcolor: Colors.white,
        }}
      >
        <Box
          component={"img"}
          src={aboutUsData?.fifth_second_section?.[index]?.image && `${UrlHelper.imgPath}${aboutUsData?.fifth_second_section?.[index]?.image}`}
          alt={
            aboutUsData?.fifth_second_section?.[index]?.image_ALT_text
              ? aboutUsData?.fifth_second_section?.[index]?.image_ALT_text
              : "Ethical Sources"
          }
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: "15rem",
            borderRadius: "2.5rem",
            objectFit: "cover",
          }}
        />
        <Box sx={{ p: { xs: "0.5rem", md: "1rem" } }}>
          <Typography
            sx={{
              color: Colors.text,
              fontSize: { xs: "1.2rem", md: "1.5rem" },
              px: "1rem",
            }}
          >
            {aboutUsData?.fifth_second_section?.[index]?.title}
          </Typography>
          {AppUtils.checkValue(
            aboutUsData?.fifth_second_section?.[index]?.description
          ) && (
              <Typography
                sx={{
                  color: Colors.descTxt,
                  fontSize: "0.9rem",
                  px: "1rem",
                }}
              >
                {isMore?.open && isMore?.index === index
                  ? aboutUsData?.fifth_second_section?.[index]?.description
                  : aboutUsData?.fifth_second_section?.[index]?.description?.length > 100 ? `${aboutUsData?.fifth_second_section?.[
                    index
                  ]?.description.slice(0, 100)}...` : aboutUsData?.fifth_second_section?.[index]?.description}
              </Typography>
            )}{
            aboutUsData?.fifth_second_section?.[index]?.description?.length > 100 &&
            <LoadingButton
              size="small"
              sx={{
                color: Colors.green,
                borderBottom: `0.0625rem solid ${Colors.green}`,
                textTransform: "none",
                mx: "1rem",
                borderRadius: "0",
                mt: 1,
              }}
              onClick={() => setIsMore({ open: !isMore?.open, index: index })}
            >
              Read {isMore?.open && isMore?.index === index ? "Less" : "More"}
            </LoadingButton>
          }
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ position: "relative", my: "5rem" }}>
      <CsImgBox
        src={"/images/Pages/BlurBrownDot.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          left: "2rem",
          top: "6rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/EthSrcVector.png"}
        imgSx={{ width: "15rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "0",
          top: "6rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <Box sx={{ width: { xs: "95%", sm: "95%", lg: "80%" }, m: "2rem auto" }}>
        <Fade direction="right" triggerOnce>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "3rem",
            }}
          >
            <Box
              sx={{
                width: "4rem",
                height: "0.25rem",
                mr: "0.5rem",
                bgcolor: Colors.green,
              }}
            ></Box>
            <Typography
              sx={{
                color: Colors.text,
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
            >
              {aboutUsData?.fifth_second_section_title}
            </Typography>
          </Box>
        </Fade>
        <Fade direction="up" triggerOnce>
          <Grid container spacing={5} sx={{ alignItems: "center" }}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <ChildCard index={0} />
                <ChildCard index={2} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ height: "100%", pt: { xs: "0rem !important" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <ChildCard index={1} />
              </Box>
            </Grid>
          </Grid>
        </Fade>
      </Box>
    </Box>
  );
};

export default EthicalSources;
