// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";

// ** Custom Imports
import CsIcon from "../../../../components/Icon";
import CsImgBox from "../../../../components/CsImgBox";
import Map from "../Map";

// ** Third Party Imports
import { Fade, JackInTheBox } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { contact_data } from "../../../../Store/ContactUsPage/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import AppUtils from "../../../../Helper/AppUtils";

const ContactInfo = () => {
  // Hooks
  const data: any = useSelector(contact_data);

  return (
    <Box sx={{ position: "relative", my: 4, mb: 10 }}>
      <Fade direction="up" triggerOnce>
        <Card sx={{ width: "100%", height: "30rem", my: "0.625rem" }}>
          <Map />
        </Card>
      </Fade>
      <JackInTheBox triggerOnce>
        <Typography
          sx={{
            color: Colors.text,
            textAlign: "center",
            fontSize: { sm: "3rem", xs: "2rem" },
          }}
        >
          Contact Info
        </Typography>
      </JackInTheBox>
      <CsImgBox
        src={"/images/Pages/Leaf1.png"}
        imgSx={{ width: "6rem", height: "6rem", zIndex: 0 }}
        boxSx={{ top: "33rem" }}
      />
      <CsImgBox
        src={"/images/Pages/BlackPaperGrp3.png"}
        imgSx={{ width: "6rem", height: "auto", zIndex: 0 }}
        boxSx={{ top: "50rem" }}
      />
      <CsImgBox
        src={"/images/Pages/Leaf3.png"}
        imgSx={{ width: "6rem", height: "auto", zIndex: 0 }}
        boxSx={{
          top: "31rem",
          right: "0",
          display: { xs: "none", sm: "block" },
        }}
      />

      <Box sx={{ width: "80%", m: "auto" }}>
        <Grid
          container
          spacing={4}
          gap={6}
          sx={{ justifyContent: "center", my: { xs: 2, sm: 5 } }}
        >
          <Grid item xs={13} sm={5.5}>
            <Fade direction={"left"} triggerOnce style={{ height: "100%" }} >
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "relative",
                  p: "1rem",
                  border: `1px solid ${Colors.border}`,
                  borderTopRightRadius: "2.25rem",
                  height: "100%"
                }}
              >
                <Box
                  component={"img"}
                  src="/images/icons/Fold.svg"
                  alt="Paper Fold"
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: { xs: "-2px", md: "-1px" },
                    width: "auto",
                    height: "5rem",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: Colors.green,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <CsIcon
                    icon="ic:baseline-email"
                    color="white"
                    fontSize={40}
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1.6rem", mb: "1rem" }}>
                    Email Address
                  </Typography>
                  <Box sx={[{ textAlign: "center" }]}>
                    {Array.isArray(data?.contact_info?.email) &&
                      data?.contact_info?.email?.length > 0 &&
                      data?.contact_info?.email?.map((val: any, i: number) => {
                        return (
                          <Box key={i} sx={{ my: 1 }}>
                            <Typography
                              sx={{
                                color: Colors.descTxt,
                                transition: "all .02s ease-in-out",
                                cursor: "pointer",
                                "&:hover": {
                                  color: Colors.green,
                                  transform: "scale(1.01)",
                                },
                              }}
                              onClick={() =>
                                window.open(
                                  `mailto:${val}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              {val}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={13} sm={5.5}>
            <Fade direction={"right"} triggerOnce style={{ height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "relative",
                  p: "1rem",
                  border: `1px solid ${Colors.border}`,
                  borderTopRightRadius: "2.25rem",
                  height: "100%"
                }}
              >
                <Box
                  component={"img"}
                  src="/images/icons/Fold.svg"
                  alt="Paper Fold"
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: { xs: "-2px", md: "-1px" },
                    width: "auto",
                    height: "5rem",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: Colors.green,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <CsIcon
                    icon="ic:sharp-watch-later"
                    color="white"
                    fontSize={40}
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1.6rem", mb: "1rem" }}>
                    {data?.contact_info?.hours_of_operation?.title}
                  </Typography>
                  <Box sx={[{ textAlign: "center" }]}>
                    <Box>
                      <Typography sx={{ color: Colors.descTxt }}>
                        {data?.contact_info?.hours_of_operation?.hours}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={13} sm={5.5}>
            <Fade direction={"left"} triggerOnce style={{ height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "relative",
                  p: "1rem",
                  border: `1px solid ${Colors.border}`,
                  borderTopRightRadius: "2.25rem",
                  height: "100%"
                }}
              >
                <Box
                  component={"img"}
                  src="/images/icons/Fold.svg"
                  alt="Paper Fold"
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: { xs: "-2px", md: "-1px" },
                    width: "auto",
                    height: "5rem",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: Colors.green,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <CsIcon icon="eva:link-2-fill" color="white" fontSize={40} />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1.6rem", mb: "1rem" }}>
                    {data?.contact_info?.social_media?.title || "Social Links"}
                  </Typography>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {AppUtils.checkValue(
                      data?.contact_info?.social_media?.fb_link
                    ) && (
                        <IconButton
                          sx={{
                            borderRadius: "100%",
                            mr: "0.625rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgb(226,246,222)",
                            p: 1,
                          }}
                          onClick={() =>
                            window.open(
                              data?.contact_info?.social_media?.fb_link,
                              "_blank",
                              "noopener noreferrer"
                            )
                          }
                        >
                          <CsIcon
                            icon="basil:facebook-solid"
                            color={Colors.green}
                            fontSize={30}
                          />
                        </IconButton>
                      )}

                    {AppUtils.checkValue(
                      data?.contact_info?.social_media?.twitter_link
                    ) && (
                        <IconButton
                          sx={{
                            borderRadius: "100%",
                            mr: "0.625rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgb(226,246,222)",
                            p: 1,
                          }}
                          onClick={() =>
                            window.open(
                              data?.contact_info?.social_media?.twitter_link,
                              "_blank",
                              "noopener noreferrer"
                            )
                          }
                        >
                          <CsIcon
                            icon="prime:twitter"
                            color={Colors.green}
                            fontSize={30}
                          />
                        </IconButton>
                      )}

                    {AppUtils.checkValue(
                      data?.contact_info?.social_media?.insta_link
                    ) && (
                        <IconButton
                          sx={{
                            borderRadius: "100%",
                            mr: "0.625rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgb(226,246,222)",
                            p: 1,
                          }}
                          onClick={() =>
                            window.open(
                              data?.contact_info?.social_media?.insta_link,
                              "_blank",
                              "noopener noreferrer"
                            )
                          }
                        >
                          <CsIcon
                            icon="lets-icons:insta-duotone-line"
                            color={Colors.green}
                            fontSize={30}
                          />
                        </IconButton>
                      )}

                    {AppUtils.checkValue(
                      data?.contact_info?.social_media?.linkedin_link
                    ) && (
                        <IconButton
                          sx={{
                            borderRadius: "100%",
                            mr: "0.625rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgb(226,246,222)",
                            p: 1,
                          }}
                          onClick={() =>
                            window.open(
                              data?.contact_info?.social_media?.linkedin_link,
                              "_blank",
                              "noopener noreferrer"
                            )
                          }
                        >
                          <CsIcon
                            icon="mdi:linkedin"
                            color={Colors.green}
                            fontSize={30}
                          />
                        </IconButton>
                      )}
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Grid>
          {Array.isArray(data?.contact_info?.locations) &&
            data?.contact_info?.locations?.length > 0 &&
            data?.contact_info?.locations?.map((card: any, index: number) => {
              return (
                <Grid item xs={13} sm={5.5} key={index}>
                  <Fade
                    direction={index % 2 === 0 ? "right" : "left"}
                    triggerOnce
                    style={{ height: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        p: "1rem",
                        border: `0.0625rem solid ${Colors.border}`,
                        borderTopRightRadius: "2.25rem",
                        height: "100%"
                      }}
                    >
                      <Box
                        component={"img"}
                        src="/images/icons/Fold.svg"
                        alt="Paper Fold"
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: { xs: "-2px", md: "-1px" },
                          width: "auto",
                          height: "5rem",
                        }}
                      />
                      <Box
                        sx={{
                          bgcolor: Colors.green,
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          p: 2,
                          mt: 1,
                          mb: 4,
                        }}
                      >
                        <CsIcon
                          icon="subway:location"
                          color="white"
                          fontSize={40}
                        />
                      </Box>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography sx={{ fontSize: "1.6rem", mb: "1rem" }}>
                          {card?.location_title}
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.descTxt,
                            cursor: "pointer",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              color: Colors.green,
                              transform: "scale(1.01)",
                            },
                          }}
                          onClick={() =>
                            window.open(
                              `http://maps.google.com/?q=${card?.location}`,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          {String(card?.location)}
                        </Typography>
                      </Box>
                    </Box>
                  </Fade>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactInfo;
