// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// ** Third Party Imports
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

// ** Custom Imports
import CsImgBox from "../../../components/CsImgBox";

// ** Icon Imports
import CsIcon from "../../../components/Icon";

// ** Store Imports
import { blog_data, blog_record_count, blog_setting_data, hash_more } from "../../../Store/Blogs";
import { getBlogs } from "../../../Store/Blogs/BlogsAction";

// ** Helper Imports
import Colors from "../../../Helper/Colors";
import UrlHelper from "../../../Helper/Url";

const BlogList = () => {

  // Hooks
  const navigate = useNavigate();
  const blogData = useSelector(blog_data);
  const totalRecords = useSelector(blog_record_count)
  const data: any = useSelector(blog_setting_data);
  const isMore = useSelector(hash_more);
  const dispatch = useDispatch();

  useEffect(() => {
    getBlogs(
      { search: "", status: 0, is_publish: true, startToken: 1, endToken: 10 },
      dispatch,
      true
    );
  }, []);

  const fetchMoreData = () => {
    getBlogs(
      {
        search: "",
        status: 0,
        is_publish: true,
        startToken: Math.ceil(blogData?.length / 10) + 1,
        endToken: 10,
      },
      dispatch,
      false
    );
  };

  return (
    <>
      <Box sx={{ width: "80%", m: "auto" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ color: Colors.text, fontSize: { xs: "2rem", sm: "4rem" } }}
          >
            <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
              {data?.blogs?.main_title}
            </Fade>
          </Typography>
          <CsImgBox
            src={"/images/Pages/MaroonDot.png"}
            imgSx={{ width: "3rem", height: "auto" }}
            boxSx={{
              position: "relative",
              display: { xs: "none", sm: "block" },
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 5,
            backgroundImage: `linear-gradient(90deg, rgba(40,157,26,1) 0%, rgba(255,255,255,1) 80%)`,
            width: "100%",
            height: "0.125rem",
          }}
        />
        <Box>
          <InfiniteScroll
            dataLength={blogData?.length}
            next={fetchMoreData}
            hasMore={isMore}
            loader={<></>}
          >
            <Grid container spacing={4} sx={{ mb: 4, overflow: "hidden" }}>
              {Array.isArray(blogData) &&
                blogData?.length > 0 &&
                blogData?.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Fade triggerOnce direction="up">
                        <Box
                          sx={{
                            border: `0.0625rem solid ${Colors.green}`,
                            borderRadius: "1.25rem",
                            p: "1rem",
                            bgcolor: Colors.bgcolor,
                            overflow: "hidden",
                            minHeight: '355px'
                          }}
                        >
                          <Fade triggerOnce direction="up">
                            <Box
                              component={"img"}
                              className="recipesCard_img "
                              src={item?.blog_image && `${UrlHelper.imgPath}${item?.blog_image}`}
                              alt={
                                data?.blog_image_alt_text
                                  ? data?.blog_image_alt_text
                                  : item?.blog_title
                              }
                              sx={{
                                width: "100%",
                                height: "13rem",
                                borderRadius: "1.25rem 1.25rem 0 0",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                my: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "0.25rem",
                                  bgcolor: Colors.green,
                                  color: Colors.white,
                                  py: "0.2rem",
                                  px: "0.4rem",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {item?.tag_id?.tag_name}
                              </Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <CsIcon
                                  icon={"uil:calender"}
                                  fontSize={15}
                                  color={Colors.descTxt}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    ml: 0.5,
                                    color: Colors.descTxt,
                                  }}
                                >
                                  {moment(item?.createdAt).format(
                                    "MMMM-DD-YYYY"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  color: Colors.black,
                                  fontSize: "1.2rem",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {item?.blog_title}
                              </Typography>
                              <Typography
                                sx={{
                                  color: Colors.descTxt,
                                  fontSize: "0.9rem",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  my: 1,
                                  " & > p": {
                                    m: 0,
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item?.blog_description,
                                }}
                              />

                              <Zoom triggerOnce>
                                <Button
                                  size="small"
                                  sx={{
                                    mt: 1,
                                    color: Colors.black,
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    navigate(`/blogs/${item?.url_slug}`)
                                  }
                                  endIcon={<CsIcon icon={"ep:right"} />}
                                >
                                  Read More
                                </Button>
                              </Zoom>
                            </Box>
                          </Fade>
                        </Box>
                      </Fade>
                    </Grid>
                  );
                })}
            </Grid>
            {
              totalRecords > blogData.length &&
              <Box width={'100%'} display={'flex'} my={5}>
                <Button
                  size="large"
                  sx={{
                    mx: 'auto',
                    color: Colors.green,
                    textTransform: "none",
                  }}
                  onClick={() => fetchMoreData()}
                  endIcon={<CsIcon icon={"ic:baseline-downloading"} />}
                >
                  Load Mode
                </Button>
              </Box>
            }
          </InfiniteScroll>
        </Box>
      </Box>
      <CsImgBox
        src="/images/Pages/BlackPaperGrp3.png"
        imgSx={{ width: "6rem", height: "auto" }}
        boxSx={{ position: "relative", textAlign: "right", mb: 2 }}
      />
    </>
  );
};

export default BlogList;
