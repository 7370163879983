// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Custom Imports
import Colors from "../../../../Helper/Colors";
import CsImgBox from "../../../../components/CsImgBox";

// ** Third Party Imports
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";

const ServiceOfferings = () => {
  // State
  const [isShow, setIsShow] = useState<boolean>(false);

  // Hooks
  const data: any = useSelector(custom_solution_data);

  return (
    <>
      <Box sx={{ mx: 5, widht: { xs: "95%", sm: "95%", lg: "80%" } }}>
        <Typography
          sx={{
            color: Colors.text,
            fontSize: { xs: "2rem", sm: "3rem" },
            my: 2,
          }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {data?.first_section?.main_title}
          </Fade>
        </Typography>
        <CsImgBox
          src={"/images/Pages/Top1.png"}
          imgSx={{ width: "4rem", height: "auto" }}
          boxSx={{ left: 0, display: { xs: "none", sm: "block" } }}
        />
        <Slide triggerOnce direction="down">
          <Typography sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>
            {data?.first_section?.main_sub_title}
          </Typography>
        </Slide>
        <Fade triggerOnce direction="up">
          <Typography
            sx={{ color: Colors.descTxt, fontSize: { sm: "1rem", xs: "0.9rem" }, my: "20px" }}
          >
            {data?.first_section?.main_description}
          </Typography>
        </Fade>
      </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Fade triggerOnce direction="left">
            <Box
              component={"img"}
              src={data?.first_section?.image && `${UrlHelper.imgPath}${data?.first_section?.image}`}
              alt={
                data?.first_section?.image_ALT_text
                  ? data?.first_section?.image_ALT_text
                  : "Service Offerings"
              }
              sx={{
                width: "100%",
                height: "auto`",
              }}
            />
          </Fade>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <CsImgBox
            src={"/images/Pages/Leaf3.png"}
            imgSx={{ width: "5rem", height: "auto" }}
            boxSx={{
              position: "relative",
              height: "max-content",
              textAlign: "right",
            }}
          />

          <Box sx={{ px: { xs: 4, sm: 8 } }}>
            <Slide triggerOnce direction="down">
              <Typography sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>
                {data?.first_section?.title}
              </Typography>
            </Slide>
            <Fade triggerOnce direction="up">
              <Typography
                variant="body1"
                sx={{ color: Colors.descTxt, my: "20px" }}
              >
                {data?.first_section?.description.length > 299
                  ? isShow
                    ? data?.first_section?.description
                    : data?.first_section?.description.length > 299 
                    ? `${data?.first_section?.description.slice(0, 200)}...` 
                    : data?.first_section?.description
                  : data?.first_section?.description}
              </Typography>
            </Fade>
            {data?.first_section?.description.length > 299 && (
              <Zoom triggerOnce>
                <LoadingButton
                  size="small"
                  sx={{
                    color: Colors.green,
                    borderBottom: `0.0625rem solid ${Colors.green}`,
                    textTransform: "none",
                    borderRadius: "0",
                  }}
                  onClick={() => setIsShow(!isShow)}
                >
                  Read {isShow ? "Less" : "More"}
                </LoadingButton>
              </Zoom>
            )}
          </Box>
          <CsImgBox
            src={"/images/Pages/BlackPaperGrp3.png"}
            imgSx={{ width: "8rem", height: "auto" }}
            boxSx={{
              position: "relative",
              height: "max-content",
              textAlign: "right",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceOfferings;
