// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const processState: { processData: any } = {
  processData: {},
};

const ProcessSlice = createSlice({
  name: "process",
  initialState: processState,
  reducers: {
    getProcessData(state: any, action: any) {
      state.processData = action.payload;
    },
  },
});

export const { getProcessData, setLoading }: any = ProcessSlice.actions;

export default ProcessSlice.reducer;

export const process_data = (state: any) => state.home.process.processData;
