// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getProductDetails, setIsSubmited, setRatioData, setIsLoading, setAllCategories } from ".";

export const fetchProductDetails = async (passData: any, dispatch: any) => {
  const res = await apiCall({
    url: "product/getProductByUrlSlug",
    data: passData,
    method: "post",
  });

  if (res?.status) {
    dispatch(getProductDetails(res?.data));
  }

  return res;
};

export const setProductInquiryForm = async (passData: any) => {
  const res = await apiCall({
    url: "product/submitProductInquiries",
    data: passData,
    method: "post",
  });

  return res;
};

export const cloneInquiry = async (passData: any, dispatch: any) => {
  const res = await apiCall({
    url: "product/cloneInquiry",
    data: passData,
    method: "post",
  });

  if (res?.status) {
    dispatch(setIsSubmited(true));
  }

  return res;
};

export const getProductRatio = async (dispatch: any) => {
  const res = await apiCall({
    url: "product/getProductPriceRatio",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(setRatioData(res?.data));
  }

  return res;
};

export const fetchAllCategories = async (dispatch: any) => {
  dispatch(setIsLoading(true));
  try {
    const res = await apiCall({
      url: "category/categoryDropDown",
      data: {},
      method: "POST",
      isHideTostMsg: true,
      isAdmin: true,
    });

    if (res?.status) {
      dispatch(setAllCategories(res?.data));
    }
    return res;

  } catch (error) {
    console.error('Error :: ', error)
    return false
  } finally {
    dispatch(setIsLoading(false));
  }
};