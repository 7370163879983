// ** React Imports
import { useState } from "react";

// ** MUI imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";
import Colors from "../../../../Helper/Colors";

const OurPromise = () => {
  // State
  const [isShow, setIsShow] = useState<boolean>(false);

  // Hooks
  const aboutUsData = useSelector(about_banner_data);

  return (
    <Box sx={{ position: "relative", my: "5rem" }}>
      <CsImgBox
        src={"/images/Pages/OurPromiseVector.png"}
        imgSx={{ width: "10rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          left: "0",
          top: "0",
          display: { xs: "none", sm: "block" },
        }}
      />
      <Grid container sx={{ alignItems: "flex-end" }} className="our-promise-sec">
        <Grid item md={6}>
          <Box
            sx={{
              pl: { xs: "1rem", md: "1.5rem", lg: "10rem" },
              px: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { xs: 3 , md: "7rem" }
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Fade direction="left" triggerOnce>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "4rem",
                        height: "0.25rem",
                        mr: "0.5rem",
                        bgcolor: Colors.green,
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        color: Colors.text,
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                      }}
                    >
                      {aboutUsData?.sixth_section?.title}
                    </Typography>
                  </Box>
                </Fade>

                <Fade direction="up" triggerOnce>
                  <Typography
                    sx={{
                      color: Colors.descTxt,
                      fontSize: "0.9rem",
                    }}
                  >
                    {isShow
                      ? aboutUsData?.sixth_section?.description
                      : aboutUsData?.sixth_section?.description.length > 200
                        ? `${aboutUsData?.sixth_section?.description.slice(0, 200)}...`
                        : aboutUsData?.sixth_section?.description}
                  </Typography>
                </Fade>
                {aboutUsData?.sixth_section?.description?.length > 200 &&
                  <Zoom triggerOnce direction="up">
                    <LoadingButton
                      size="small"
                      sx={{
                        color: Colors.green,
                        borderBottom: `0.0625rem solid ${Colors.green}`,
                        textTransform: "none",
                        mt: 1,
                        borderRadius: "0",
                      }}
                      onClick={() => setIsShow(!isShow)}
                    >
                      Read {isShow ? "Less" : "More"}
                    </LoadingButton>
                  </Zoom>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Fade direction="right" triggerOnce>
            <Box
              component={"img"}
              src={aboutUsData?.sixth_section?.image && `${UrlHelper.imgPath}${aboutUsData?.sixth_section?.image}`}
              alt={
                aboutUsData?.sixth_section?.image_ALT_text
                  ? aboutUsData?.sixth_section?.image_ALT_text
                  : "Our Promise"
              }
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurPromise;
