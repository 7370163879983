// Created By MK

// ** Third Party Imports
import { publicIpv4 } from "public-ip";
import { setDailyVisitor, setVisitor } from "../../Store/HomePage/Banner/BannerAction";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import moment from 'moment';

const AppUtils = {
  // encode the string function
  decodeStr: (str: any) => {
    return CryptoJS.AES.decrypt(
      str && str && str !== null && str !== undefined && str,
      "VORA_F_CUST"
    )?.toString(CryptoJS.enc.Utf8);
  },

  // decode the string function
  encodeStr: (str: string) =>
    CryptoJS.AES.encrypt(str, "VORA_F_CUST")?.toString(),

  // getLoccalStorage Data
  setLocalStorage: (key: string, val: any) => localStorage.setItem(key, val),

  // getLoccalStorage Data
  removeLocalStorageItem: (key: string) => localStorage.removeItem(key),

  // setLoccalStorage Data
  getLocalStorage: (key: string) => {
    const data: any =
      typeof window !== "undefined" && localStorage.getItem(key);

    return (
      data &&
      data !== null &&
      data !== undefined &&
      JSON.parse(AppUtils.decodeStr(data))
    );
  },

  setLocalStorageItemWithTimestamp: (key: any, value: any, expiryInMilliseconds: any) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiryInMilliseconds,
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  getLocalStorageItemWithTimestamp: (key: any) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },

  // get user details
  getUserDtls: () => {
    const data = AppUtils.getLocalStorage("VORA_F_CUST");

    return data && data !== undefined && data !== null
      ? {
        ...data,
      }
      : false;
  },

  // apiError handle
  apiErrorHandling: (formik: any, fields: string[], error: any) => {
    let isError = true;
    if (typeof error !== "undefined" && error !== null) {
      for (let i = 0; i < fields.length; i++) {
        if (
          typeof error[fields[i]] !== "undefined" &&
          error[fields[i]] !== null &&
          error[fields[i]] !== ""
        ) {
          formik.setFieldTouched(fields[i], true, false);
          formik.setFieldError(fields[i], error[fields[i]]);
          isError = false;
        }
      }
    }

    return isError;
  },

  roundOff: (value: number, decimals: number) => {
    return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
  },

  // check any value
  checkValue: (field: any) => {
    let isData = false;
    if (typeof field !== "undefined" && field !== null) {
      if (typeof field === "string" && field?.trim() !== "") {
        isData = true;
      } else if (typeof field === "boolean") {
        isData = true;
      } else if (typeof field === "number") {
        isData = true;
      } else if (typeof field === "object") {
        isData = true;
      }
    }

    return isData;
  },

  checkFieldValue: (data: any, field: any) => {
    let isData = false;
    if (
      AppUtils.checkValue(data) &&
      typeof data?.[field] !== "undefined" &&
      data?.[field] !== null
    ) {
      if (typeof data?.[field] === "string" && data?.[field]?.trim() !== "") {
        isData = true;
      } else if (typeof data?.[field] === "boolean") {
        isData = true;
      } else if (typeof data?.[field] === "number") {
        isData = true;
      } else if (typeof data?.[field] === "object") {
        isData = true;
      }
    }

    return isData;
  },

  //for mobile number setup
  mobileNumberFormate: (value: string) => {
    let mNumString = value;
    let formatted = "";
    if (typeof mNumString !== "undefined" && mNumString !== null) {
      mNumString = mNumString.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";

      //for set number formate 3-3-4
      block1 = mNumString.substring(0, 3);
      if (block1?.length === 3 && mNumString?.length > 3) {
        block1 = "(" + block1 + ")-";
      }
      block2 = mNumString.substring(3, 6);
      if (block2?.length === 3 && mNumString?.length > 6) {
        block2 = block2 + "-";
      }
      block3 = mNumString.substring(6, 10);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }

      formatted = block1 + block2 + block3;
      // }
    }
    return formatted;
  },

  // Create random string
  generateRandomString() {
    const result = Math.random().toString(36).substring(2, 7);
    return result;
  },

  getFileNameFromUrl: (url: any) => {
    if (typeof url == "string") {
      const urlArr = url.split("/");
      if (Array.isArray(urlArr) && urlArr.length > 0)
        return urlArr[urlArr.length - 1];
    }

    return "";
  },

  getOperatingSystem: () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/Android/i)) {
      return "android";
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return "ios";
    } else if (userAgent.match(/Windows NT/i)) {
      return "windows";
    } else if (userAgent.match(/Macintosh|Mac OS X/i)) {
      return "mac";
    } else if (userAgent.match(/Linux/i)) {
      return "linux";
    } else {
      return "unknown";
    }
  },

  getAsyncIpAddress: async () => {
    try {
      const pIP = await publicIpv4();
      return pIP ? pIP : "";
    } catch (e) {
      return "";
    }
  },

  getIpAddress: () => {
    return new Promise((resolve, reject) => {
      AppUtils.getAsyncIpAddress()
        .then((val: string) => {
          resolve(val);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  getIp: async () => {
    return await AppUtils.getIpAddress().then((res: any) => {
      return res;
    });
  },

  setVisitorData: async (
    user_ip: any,
    page: string,
    page_name: string,
    cookie_name: string
  ) => {
    if (!AppUtils.checkValue(Cookies.get(cookie_name))) {
      const data = {
        ip: await user_ip,
        page: page,
        page_name: page_name,
        userAgent: AppUtils.getOperatingSystem(),
      };
      const res = await setVisitor(data);

      if (res?.status) {
        Cookies.set(cookie_name, JSON.stringify(data));
      }
    }
  },

  setDailyVisitor: async ({ pageKey }: { pageKey: 'home' | 'all_product' | 'blog' | 'about_us' | 'contact_us' | 'market_insights' | 'custom_solution' }) => {
    const pages: any = {
      home: 'Home',
      all_product: 'All Products',
      blog: 'Blogs',
      about_us: 'About Us',
      contact_us: 'Contact Us',
      market_insights: 'Market Insights',
      custom_solution: 'Custom Solution',
    };

    const currentDate = moment().format('YYYY-MM-DD');
    const storedVisitor = AppUtils.getLocalStorage(pageKey)
    let shouldCallApi = true;

    if (storedVisitor) {
      const { pageKey: storedPageKey, visitDate } = storedVisitor;
      if (pageKey === storedPageKey && visitDate === currentDate) {        
        shouldCallApi = false;
      }
    }
    
    if (shouldCallApi) {
      const ip = await AppUtils.getIp();
      const request = {
        ip: ip,
        page: pageKey,
        page_name: pages[pageKey],
        system: AppUtils.getOperatingSystem(),
        visitDate: currentDate
      };

      await setDailyVisitor(request);

      AppUtils.setLocalStorage(pageKey, AppUtils.encodeStr(JSON.stringify({ pageKey, visitDate: currentDate })));
    }
  },
};

export default AppUtils;
