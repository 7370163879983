// ** Third Party Imports
import { combineReducers } from "redux";

// ** Rerducer imports
import ContactSlice from "./Section";

// Combine the reducers
const ContactUsPageReducer: any = combineReducers({
  section: ContactSlice,
});

export default ContactUsPageReducer;
