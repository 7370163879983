// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice imports
import { getContactData } from ".";

export const fetchContactUs = async (dispatch: any) => {
  const res = await apiCall({
    url: "contactUs/getContactInfo",
    data: {},
    method: "get",
  });

  if (res?.status) {
    dispatch(getContactData(res?.data));
  }

  return res;
};
