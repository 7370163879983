// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getProductBanner } from ".";

export const fetchProductBanner = async (dispatch: any) => {
  const res = await apiCall({
    url: "product/getBannerSection",
    data: {},
    method: "get",
  });

  if (res?.status) {
    dispatch(getProductBanner(res?.data));
  }

  return res;
};
