// ** React Imports
import { useState, useRef, useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Third party Imports
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { product_data } from "../../../../Store/HomePage/Product";
import { fetchProductData } from "../../../../Store/HomePage/Product/ProductAction";
import { getProductDetails } from "../../../../Store/Product/DetailsPage";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Styles Impoerts
import "swiper/css";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import AppUtils from "../../../../Helper/AppUtils";
import UrlHelper from "../../../../Helper/Url";

const randomcolor = [
  "#D07335",
  "#525458",
  "#C28324",
  "#50B944",
  "#D26549",
  "#7A934E",
  "#ECA84B",
  "#23252A",
];

const Products = () => {
  // State
  const [bgcolor, setBgColor] = useState<string>("");

  const handleHover = () => {
    const randomIndex = Math.floor(Math.random() * randomcolor.length);
    setBgColor(randomcolor[randomIndex]);
  };

  // Hooks
  const swiperRef = useRef<any>(null);
  const navigate = useNavigate();
  const data = useSelector(product_data);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProductData(dispatch);
  }, []);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && <Box
        sx={{
          bgcolor: bgcolor,
          transition: "background-color 0.5s ease",
        }}
      >
        {/* <Container maxWidth="lg"> */}
        <Box sx={{ width: '80%', m: 'auto' }}>
          <Box
            sx={{
              bgcolor: bgcolor,
              transition: "background-color 0.5s ease",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  fontWeight: 500,
                  width: "100%",
                  pl: { sm: 8 },
                  textAlign: "center",
                  color: AppUtils.checkValue(bgcolor)
                    ? Colors.white
                    : Colors.black,
                  mt: 2,
                }}
              >
                <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                  Featured Products
                </Fade>
              </Typography>
              <CsImgBox
                src={"/images/Pages/BlackPaperGrp2.png"}
                imgSx={{ width: "4rem", height: "auto" }}
                boxSx={{
                  position: "relative",
                  width: "fit-content",
                  display: { xs: "none", sm: "block" },
                }}
              />
            </Box>
            <Box sx={{ width: '100%', m: 'auto' }}>
              <Box
                ref={swiperRef}
                component={Swiper}
                spaceBetween={20}
                sx={{ mt: 4 }}
                breakpoints={{
                  568: { slidesPerView: 1 },
                  660: { slidesPerView: 2 },
                  768: { slidesPerView: 3 },
                  915: { slidesPerView: 4 },
                }}
              >
                {data?.map((item: any, index: number) => {                  
                  if (index < 6)
                    return (
                      <SwiperSlide key={index}>
                        <Fade direction="up" triggerOnce>
                          <Box
                            sx={{
                              bgcolor: Colors.white,
                              border: `0.0625rem solid ${Colors.lightGrey}`,
                              p: "1rem",
                              borderRadius: "0.5rem",
                              mt: index % 2 === 0 ? 0 : 6,
                              cursor: "pointer",
                            }}
                            onMouseEnter={handleHover}
                          >
                            <Typography
                              sx={{
                                fontSize: "1.3rem",
                                color: Colors.black,
                                fontWeight: 500,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                overflow: "hidden",
                              }}
                            >
                              {item?.product_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                color: Colors.descTxt,
                                fontWeight: 400,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.product_short_description,
                              }}
                            />
                            <IconButton
                              sx={{
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgb(226,246,222)",
                                p: 1,
                                mt: 2,
                              }}
                              onClick={() => {
                                dispatch(getProductDetails({}));
                                navigate(`/product/${item?.url_slug}`);
                              }}
                            >
                              <CsIcon
                                icon={"mdi:arrow-right"}
                                color={Colors.green}
                                fontSize={30}
                              />
                            </IconButton>

                            <Box sx={{ textAlign: "end" }}>
                              <Box
                                component={"img"}
                                src={item?.product_image && `${UrlHelper.imgPath}${item?.product_image}`}
                                alt={
                                  item?.product_image_alt_text
                                    ? item?.product_image_alt_text
                                    : item?.product_name
                                }
                                sx={{
                                  width: 120,
                                  height: 110,
                                  objectFit: "cover",
                                  borderRadius: "0.5rem",
                                }}
                              />
                            </Box>
                          </Box>
                        </Fade>
                      </SwiperSlide>
                    );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", py: 2 }}>
                <IconButton
                  onClick={() => {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slidePrev();
                    }
                  }}
                >
                  <CsIcon
                    icon={"bi:arrow-left"}
                    color={
                      AppUtils.checkValue(bgcolor) ? Colors.white : Colors.black
                    }
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideNext();
                    }
                  }}
                >
                  <CsIcon
                    icon={"bi:arrow-right"}
                    color={
                      AppUtils.checkValue(bgcolor) ? Colors.white : Colors.black
                    }
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </Container> */}
      </Box>}
    </>
  );
};

export default Products;
