// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** MUI Imports
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash/debounce';

// ** Store Imports
import { logo_data, pages, search_items } from "../../Store/SEO";
import { fetchLogoData, globalSearch } from "../../Store/SEO/SEOAction";

// ** Custom Imports
import Sidebar from "./Components/Sidebar";
import CsAutoComplete from "../../components/CsAutoComplete";
import CsButton from "../../components/CsButton";
import Profile from "./Components/Profile";
import Whatsapp from "./Components/Whatsapp";

// ** Store Imports
import { auth_data } from "../../Store/Auth";
import { is_open, setIsOpen } from "../../Store/SEO";

// ** Icon Imports
import CsIcon from "../../components/Icon";

// ** Helper Imports
import Colors from "../../Helper/Colors";
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";
import { Tooltip, Typography } from "@mui/material";

const Navbar = () => {
  // State
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const [value, setValue] = useState<any>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menu, setMenu] = useState<Element | null>(null);
  const [search, setSearch] = useState<string>('')

  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages)
  const logoData = useSelector(logo_data);
  const authUser = useSelector(auth_data);
  const isOpen = useSelector(is_open);
  const searchItems = useSelector(search_items)

  const dispatch = useDispatch();

  // Media Query
  const sm = useMediaQuery("(max-width: 650px)");

  const dashboardURL = process.env.REACT_APP_DASHBOARD_URL

  useEffect(() => {
    fetchLogoData(dispatch);
  }, []);

  const debouncedSetSearch = useCallback(
    debounce((val: string) => {
      setSearch(val);
    }, 300),
    []
  );

  const fetchSearchCallback = useCallback(() => globalSearch({ search: search, startToken: 1, endToken: 20 }, dispatch), [search]);

  useEffect(() => {
    fetchSearchCallback()
  }, [fetchSearchCallback])

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          bgcolor: "#fff",
          zIndex:
            isOpen || Boolean(menu) || isMenu || Boolean(anchorEl)
              ? "99"
              : "9999",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: { sm: "80%" },
            mx: { sm: "auto" },
            px: { xs: 1, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={"img"}
                src={
                  AppUtils.checkValue(logoData?.logo)
                    ? `${UrlHelper.imgPath}${logoData?.logo}`
                    : "/images/icons/LOGO.svg"
                }
                alt={
                  AppUtils.checkValue(logoData?.logo_alt_text)
                    ? logoData?.logo_alt_text
                    : "Vora Spices Logo"
                }
                sx={{ width: "10rem", height: "auto", cursor: "pointer" }}
                onClick={() => {
                  const homeRoute = allPages && allPages.length > 0 && allPages.find((pages: any) => pages.default_key === 'Home').url_slug
                  if (allPages && allPages.length > 0 && homeRoute != "/") {
                    navigate(`/${homeRoute}`)
                  } else {
                    navigate(`/`)
                  }
                }}
              />
              <IconButton
                onClick={() => dispatch(setIsOpen(!isOpen))}
                sx={{ ml: { xs: 0, sm: 2 } }}
              >
                <CsIcon icon={"ic:round-menu"} fontSize={30} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Collapse in={isSearch && !sm} orientation="horizontal">
                <CsAutoComplete
                  onOpen={() => setIsMenu(true)}
                  onClose={() => setIsMenu(false)}
                  setSearch={debouncedSetSearch}
                  label={"Search..."}
                  size="small"
                  sx={{ width: 250 }}
                  options={searchItems ?? []}
                  value={value}
                  getOptionLabel={(option: any) => option?.label || ""}
                  onChange={(e: any, value: any) => {
                    setValue(value);
                    navigate(value?.url_slug === '/' ? value?.url_slug : '/' + value?.url_slug);
                    setIsSearch(false);
                  }}
                />
              </Collapse>
              <IconButton
                sx={{ ml: { xs: 0, sm: 2 } }}
                onClick={(event: any) => {
                  if (sm) {
                    setAnchorEl(Boolean(anchorEl) ? null : event.currentTarget);
                  } else {
                    setIsSearch(!isSearch);
                  }
                  setValue(null);
                }}
              >
                <CsIcon
                  icon={
                    isSearch || Boolean(anchorEl)
                      ? "gg:close-o"
                      : "iconamoon:search"
                  }
                />
              </IconButton>

              {AppUtils.checkValue(authUser) &&
                Object?.keys(authUser)?.length > 0 ? (
                <>
                  {/* <Tooltip
                    title={'Dashboard'}
                    arrow
                    componentsProps={{
                      arrow: { sx: { color: Colors.green } },
                      tooltip: {
                        sx: { bgcolor: Colors.green, fontSize: "1rem" },
                      },
                    }}
                  > */}
                  <Box sx={{ px: 2, borderRadius: 2, display: 'flex', alignItems: 'center', cursor: 'pointer', '&:hover': { background: "#efefef" } }} onClick={() => window.open(`${dashboardURL}/login?token=${encodeURIComponent(authUser?.token)}`)}>
                    <IconButton>
                      <CsIcon icon={"radix-icons:dashboard"} />
                    </IconButton>
                    <Typography color={'#000000'} sx={{ display: { sm: 'block', xs: 'none' } }}>Dashboard</Typography>
                  </Box>
                  {/* </Tooltip> */}
                  <Profile menu={menu} setMenu={setMenu} />
                </>
              ) : !sm ? (
                <CsButton
                  variant="outlined"
                  label="Login"
                  size="small"
                  sx={{ ml: { xs: 0, sm: 2 } }}
                  onClick={() => navigate("/login")}
                />
              ) : (
                <IconButton
                  sx={{ background: "rgb(226,246,222)", ml: 2 }}
                  onClick={() => navigate("/login")}
                >
                  <CsIcon icon={"line-md:log-in"} color={Colors.green} />
                </IconButton>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0 0 10px 0 lightgrey",
          },
        }}
      >
        <MenuItem sx={{ bgcolor: `${Colors.white} !important` }} disableRipple>
          <CsAutoComplete
            onOpen={() => setIsMenu(true)}
            onClose={() => setIsMenu(false)}
            size="small"
            label={"Search Products..."}
            sx={{ width: 250 }}
            options={allPages ?? []}
            value={value}
            getOptionLabel={(option: any) => option?.title || ""}
            onChange={(e: any, value: any) => {
              setValue(value);
              navigate(value?.url_slug === "/" ? "/" : `/${value?.url_slug}`);
              setAnchorEl(null);
            }}
          />
        </MenuItem>
      </Menu>
      <Sidebar />
      <Whatsapp data={logoData} allPages={allPages} />
    </>
  );
};

export default Navbar;
