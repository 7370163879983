// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const FAQState: { FAQData: any } = {
  FAQData: {},
};

const FAQSlice = createSlice({
  name: "faq",
  initialState: FAQState,
  reducers: {
    getFAQData(state: any, action: any) {
      state.FAQData = action.payload;
    },
  },
});

export const { getFAQData, setLoading }: any = FAQSlice.actions;

export default FAQSlice.reducer;

export const FAQ_data = (state: any) => state.home.faq.FAQData;
