// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const productState: {
  productData: any;
  allProducts: any;
  hasMore: boolean;
  isLoading: boolean;
  typeOfSpices: any[];
  certifications: any[];
  footerPoducts: any[];
  record_count: number
} = {
  productData: {},
  allProducts: [],
  hasMore: true,
  isLoading: false,
  typeOfSpices: [],
  certifications: [],
  footerPoducts: [],
  record_count: 0
};

const ProductSlice = createSlice({
  name: "product",
  initialState: productState,
  reducers: {
    getProductData(state: any, action: any) {
      state.productData = action.payload;
    },
    fetchProducts(state: any, action: any) {
      const { isInitial, data } = action.payload;
      if (isInitial) {
        state.allProducts = data;
        if(state.footerPoducts.length === 0){
          state.footerPoducts = data
        }
      } else {
        state.allProducts = [...state.allProducts, ...data];
      }
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getTypeofSpices: (state, action) => {
      state.typeOfSpices = action.payload;
    },
    getCertifications: (state, action) => {
      state.certifications = action.payload;
    },
    setRecordCount: (state, action) => {
      state.record_count = action.payload;
    },
  },
});

export const {
  getProductData,
  setLoading,
  fetchProducts,
  setHasMore,
  setIsLoading,
  getTypeofSpices,
  getCertifications,
  setRecordCount
}: any = ProductSlice.actions;

export default ProductSlice.reducer;

export const product_data = (state: any) => state.home.product.productData;

export const hash_more = (state: any) => state.home.product.hasMore;

export const all_products = (state: any) => state.home.product.allProducts;

export const is_loading = (state: any) => state.home.product.isLoading;

export const type_of_spices = (state: any) => state.home.product.typeOfSpices;

export const footer_product = (state: any) => state.home.product.footerPoducts;

export const prodcut_record_count = (state: any) => state.home.product.record_count;

export const certifications_data = (state: any) =>
  state.home.product.certifications;
