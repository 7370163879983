// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Third party Imports
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import ServiceOfferings from "./Components/ServiceOfferings";
import BenefitsSection from "./Components/BenefitsSection";
import B2BSolutions from "./Components/B2BSolutions";
import ProcessOverview from "./Components/ProcessOverview";
import Partnerships from "./Components/Partnerships";
import Logistical from "./Components/Logistical";
import DeeperAnalysis from "../MarketInsights/Components/DeeperAnalysis";

// ** Store Imports
import { fetchCustomSolutionData } from "../../Store/CustomSolution/Section/SectionAction";
import { custom_solution_data } from "../../Store/CustomSolution/Section";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";

const CustomSolutions = () => {
  const userIp = async () => {
    const res = await AppUtils.getIp();

    return res;
  };

  // Hooks
  const dispatch = useDispatch();
  const seoData = useSelector(seo_data);

  useEffect(() => {
    fetchCustomSolutionData(dispatch);
    AppUtils.setVisitorData(
      userIp(),
      "custom_solution",
      "Custom solution",
      "CUSTOM_SOLUTION"
    );
    AppUtils.setDailyVisitor({ pageKey: 'custom_solution' });
  }, []);

  const bannerData = useSelector(custom_solution_data);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "CustomSolutions" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
              </Helmet>
            )
        )}

      <Box sx={{ overflowX: "hidden" }}>
        {bannerData && bannerData?.page_title && bannerData?.banner &&
          <CsHeader
            title={bannerData?.page_title}
            src={`${UrlHelper.imgPath}${bannerData?.banner}`}
          />
        }
        <ServiceOfferings />
        <ProcessOverview />
        <BenefitsSection />
        <B2BSolutions />
        <Partnerships />
        <Logistical />
        <DeeperAnalysis />
      </Box>
    </>
  );
};

export default CustomSolutions;
