// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Components Imports
import Quotes from "./Components/Quotes";
import EthicalSources from "./Components/EthicalSources";
import JourneyBegins from "./Components/JourneyBegins";
import OurMission from "./Components/OurMission";
import OurPromise from "./Components/OurPromise";
import SustainingPlanets from "./Components/SustainingPlanet";
import VisionFuture from "./Components/VisionFuture";
import DeeperAnalysis from "../AllProducts/Components/DeeperAnalysis";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";

// ** Store Imports
import { fetchAboutBannerData } from "../../Store/AboutUsPage/Banner/BannerAction";
import { about_banner_data } from "../../Store/AboutUsPage/Banner";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";

const AboutUs = () => {
  // Hooks
  const dispatch = useDispatch();
  const bannerData = useSelector(about_banner_data);
  const seoData = useSelector(seo_data);
  const aboutUsSeo = seoData.find(
    (item: any) => item.default_key === "AboutUs"
  );


  useEffect(() => {
    fetchAboutBannerData(dispatch);
  }, [dispatch]);

  const userIp = async () => {
    const res = await AppUtils.getIp();
    return res;
  };

  useEffect(() => {
    AppUtils.setVisitorData(userIp(), "about_page", "About us", "ABOUT_PAGE");
    AppUtils.setDailyVisitor({ pageKey: 'about_us' });
  }, []);

  return (
    <>
      {Object.keys(aboutUsSeo).length > 0 && (
        <Helmet>
          <meta name="description" content={aboutUsSeo?.meta_description} />
          <title>{aboutUsSeo?.page_title}</title>
          {AppUtils.checkValue(aboutUsSeo?.meta_keywords) && (
            <meta name="keywords" content={aboutUsSeo?.meta_keywords} />
          )}
          {AppUtils.checkValue(aboutUsSeo?.meta_keywords) && (
            <script type="application/ld+json">
              {aboutUsSeo?.meta_schema}
            </script>
          )}
        </Helmet>
      )}
      <Box sx={{ overflowX: "hidden" }}>{
        bannerData && bannerData?.banner &&
        <CsHeader
          title={bannerData?.page_title}
          src={bannerData?.banner && `${UrlHelper.imgPath}${bannerData?.banner}`}
        />
      }
        <JourneyBegins />
        <OurMission />
        <VisionFuture />
        <SustainingPlanets />
        <EthicalSources />
        <OurPromise />
        <Quotes />
        <DeeperAnalysis />
      </Box>
    </>
  );
};

export default AboutUs;
