// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import Recipes from "./Components/Recipes";
import DeeperAnalysis from "./Components/DeeperAnalysis";

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Store Imports
import { blog_setting_data } from "../../Store/Blogs";
import { fetchBlogSetting, getBlogs } from "../../Store/Blogs/BlogsAction";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";

const MarketInsights = () => {
  // Hooks
  const data: any = useSelector(blog_setting_data);
  const seoData: any = useSelector(seo_data);
  const dispatch = useDispatch();

  const userIp = async () => {
    const res = await AppUtils.getIp();

    return res;
  };

  useEffect(() => {
    AppUtils.setVisitorData(
      userIp(),
      "market_insights",
      "Market Insights",
      "MARKET_INSIGHTS"
    );
    AppUtils.setDailyVisitor({ pageKey: 'market_insights' });
  }, []);

  useEffect(() => {
    if (Object.keys(data).length <= 0) {
      fetchBlogSetting(dispatch);
    }
  }, [data]);

  useEffect(() => {
    getBlogs(
      { search: "", status: 0, is_publish: true, startToken: 1, endToken: 6 },
      dispatch,
      true
    );
  }, []);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "MarketInsights" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <meta name="keywords" content={item?.meta_keywords} />
                )}
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <script type="application/ld+json">
                    {item?.meta_schema}
                  </script>
                )}
              </Helmet>
            )
        )}
      <Box sx={{ overflowX: "hidden" }}>
        {
          data && data?.marketInsights && data?.marketInsights?.title && data?.marketInsights?.image &&
          <CsHeader
            title={data?.marketInsights?.title}
            src={`${UrlHelper.imgPath}${data?.marketInsights?.image}`}
          />
        }
        <Recipes />
        <DeeperAnalysis />
      </Box>
    </>
  );
};

export default MarketInsights;
