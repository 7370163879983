// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Third party Imports
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import BlogCards from "./Components/BlogCards";
import Details from "./Components/Details";
import DeeperAnalysis from "../AllProducts/Components/DeeperAnalysis";

// ** Store Imports
import { blog_details_data, blog_setting_data } from "../../Store/Blogs";
import { fetchBlogSetting } from "../../Store/Blogs/BlogsAction";

// ** Helper Import
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";

const BlogDetails = () => {
  const data: any = useSelector(blog_setting_data);
  const blog_detail = useSelector(blog_details_data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(data).length <= 0) {
      fetchBlogSetting(dispatch);
    }
  }, [data]);

  return (
    <>

      <Helmet>
        <meta name="description" content={blog_detail?.seo?.meta_description} />
        <title>{blog_detail?.seo?.page_title}</title>
        {AppUtils.checkValue(blog_detail?.seo?.meta_keywords) && (
          <meta name="keywords" content={blog_detail?.seo?.meta_keywords} />
        )}
        {AppUtils.checkValue(blog_detail?.seo?.meta_keywords) && (
          <script type="application/ld+json">
            {blog_detail?.seo?.meta_schema}
          </script>
        )}
      </Helmet>

      <Box sx={{ overflowX: "hidden" }}>
        {
          data && data?.blogDetails && data?.blogDetails?.title && data?.blogDetails?.image &&
          <CsHeader
            title={data?.blogDetails?.title}
            src={`${UrlHelper.imgPath}${data?.blogDetails?.image}`}
          />
        }
        <Details />
        <BlogCards />
        <DeeperAnalysis />
      </Box>
    </>
  );
};

export default BlogDetails;
