// ** Third Party Imports
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// ** Custom Imports
import LayoutWrapper from "./Layout";

// ** Store Imports
import Store from "./Store";

const App = () => {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <LayoutWrapper />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
