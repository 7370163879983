// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

// ** Third Party Imports
import { Fade, Slide } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const B2BSolutions = () => {
  // Hooks
  const data: any = useSelector(custom_solution_data);

  return (
    <Box>
      <Fade triggerOnce direction="up">
        <Box
          component={"img"}
          src={ data?.center_image && `${UrlHelper.imgPath}${data?.center_image}`}
          alt={
            data?.center_image_alt_text
              ? data?.center_image_alt_text
              : "B2B Solutions"
          }
          sx={{
            mt: "5rem",
            width: "100%",
            height: "auto",
          }}
        />
      </Fade>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", md: "row" },
          width: "80%",
          m: "auto",
        }}
      >
        <Box>
          <Typography
            sx={{ color: Colors.text, fontSize: { xs: "2rem", sm: "3rem" } }}
          >
            <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
              {data?.center_title}
            </Fade>
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Slide triggerOnce direction="down">
              <Typography
                sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, mb: 1 }}
              >
                {data?.center_sub_title}
              </Typography>
            </Slide>
            <Fade triggerOnce direction="up">
              <Typography sx={{ color: Colors.descTxt, fontSize: "0.9rem" }}>
                {data?.center_sub_description}
              </Typography>
            </Fade>
          </Box>
        </Box>
        <CsImgBox
          src={"/images/Pages/B2BRight.png"}
          imgSx={{ width: "8rem", height: "auto" }}
          boxSx={{ right: 0 }}
        />
      </Box>
      <Box sx={{ width: "80%", m: "auto" }}>
        <Grid container spacing={4} sx={{ my: 4, justifyContent: "center" }}>
          {Array.isArray(data?.forth_section) &&
            data?.forth_section?.length > 0 &&
            data?.forth_section?.map((item: any, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={5} md={3}>
                  <Fade triggerOnce direction="up">
                    <Card
                      key={index}
                      sx={{
                        borderRadius: "1.25rem",
                        mt: { sm: index % 2 === 0 ? "5rem" : 0 },
                      }}
                    >
                      <Fade triggerOnce direction="up">
                        <Box
                          component={"img"}
                          src={item?.image && `${UrlHelper.imgPath}${item?.image}`}
                          alt={
                            data?.center_image_alt_text
                              ? data?.center_image_alt_text
                              : "B2B Solutions"
                          }
                          sx={{
                            width: "100%",
                            height: "8.75rem",
                            objectFit: "cover",
                          }}
                        />
                        <CardContent>
                          <Typography sx={{ fontSize: "1.5rem" }}>
                            {item?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              color: Colors.descTxt,
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {item?.description}
                          </Typography>
                        </CardContent>
                      </Fade>
                    </Card>
                  </Fade>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default B2BSolutions;
