// ** Slice Imports
import { fetchAuthUser, setIsLoading } from ".";

// ** Helpers Imports
import apiCall from "../../Helper/Service";
import AppUtils from "../../Helper/AppUtils";

export const loginUser = async (passData: any, dispatch: any) => {
  const res = await apiCall({
    url: "auth/signIn/",
    data: passData,
    method: "post",
    isCustom: true,
  });

  if (res?.status && res?.status_code === 200) {
    AppUtils.setLocalStorage(
      "VORA_F_CUST",
      AppUtils.encodeStr(JSON.stringify(res?.data))
    );
    dispatch(fetchAuthUser(res?.data));
  }

  return res;
};

export const changePassword = async (passData: any, dispatch: any) => {
  try {
    dispatch(setIsLoading(true))
    const res = await apiCall({
      url: "auth/setPassword",
      data: passData,
      method: "post",
      isCustom: true,
    });

    return res;
  } catch (error) {
    return false
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const forgotPassword = async (passData: any) => {
  const res = await apiCall({
    url: "auth/forgotPassword",
    data: passData,
    method: "post",
    isCustom: true,
  });

  return res;
};


export const fetchCusomerById = async (passData: any, dispatch: any) => {
  const { id, token } = passData
  const res = await apiCall({
    url: "auth/getCustomerById",
    data: { id },
    method: "post",
    isLogin: false,
    isCustom: true
  });

  if (res?.status && res?.data && res?.data) {
    const updatedRes = { ...res?.data, token: token }
    AppUtils.setLocalStorage(
      "VORA_F_CUST",
      AppUtils.encodeStr(JSON.stringify(updatedRes))
    );
    dispatch(fetchAuthUser(updatedRes));
  }

  return res;
};
