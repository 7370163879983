// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const BenefitsSection = () => {
  // Hooks
  const data: any = useSelector(custom_solution_data);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "1.5rem", sm: "2rem" },
          mb: "2rem",
          mt: "4rem",
          textAlign: "center",
        }}
      >
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          {data?.third_section?.title}
        </Fade>
      </Typography>
      <Box sx={{ textAlign: "center", my: "10px" }}>
        <Fade triggerOnce direction="up">
          <Box
            component={"img"}
            className="benefits_sec_img"
            src={data?.third_section?.image && `${UrlHelper.imgPath}${data?.third_section?.image}`}
            alt={
              data?.third_section?.image_ALT_text
                ? data?.third_section?.image_ALT_text
                : "Benifits"
            }
            sx={{
              width: { xs: "100%", md: "auto" },
              height: { sm: "550px" },
              textAlign: "center",
            }}
          />
        </Fade>
      </Box>
      <Box sx={{ width: '80%', m: 'auto' }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {Array.isArray(data?.third_section?.sub_sections) &&
            data?.third_section?.sub_sections?.length > 0 &&
            data?.third_section?.sub_sections?.map(
              (card: any, index: number) => (
                <Grid item xs={12} md={6} key={index}>
                  <Fade triggerOnce direction="up">
                    <Card
                      sx={{
                        border: `0.0625rem solid${Colors.border} `,
                        borderRadius: "1.25rem",
                        p: "1.25rem",
                        overflow: "unset !important",
                        mt: "1.875rem",
                      }}
                    >
                      <Fade triggerOnce direction="up">
                        <Typography
                          sx={{
                            color: Colors.text,
                            fontSize: {
                              xs: "2.2rem",
                              sm: "2.5rem",
                              md: "3rem",
                            },
                            fontWeight: "bold !important",
                            mt: { xs: "-48px", sm: "-53px", md: "-59px" },
                            background: "-webkit-linear-gradient(#eee, #333)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          {index >= 9 ? index + 1 : `0${index + 1}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.text,
                            fontSize: {
                              xs: "1.2rem",
                              sm: "1.5rem",
                              md: "2rem",
                            },
                            my: "1rem",
                          }}
                        >
                          {card.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.descTxt,
                            my: "1rem",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {card.description}
                        </Typography>
                      </Fade>
                    </Card>
                  </Fade>
                </Grid>
              )
            )}
        </Grid>
      </Box>
    </Box>
  );
};

export default BenefitsSection;
