import { useState, useEffect } from "react";

export default function useWindowSize() {
    const getSize = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    };

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}


export const useIsInViewport = (ref: HTMLElement | any) => {
    const [isInViewport, setIsInViewport] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting), { threshold: 0.1 });

        ref.current && observer.observe(ref.current);

        return () => ref.current && observer.unobserve(ref.current)
    }, [ref]);

    return isInViewport;
};

