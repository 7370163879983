// ** Service Imports
import apiCall from "../../Helper/Service";

// ** Slice Imports
import { setSeoData, setLoading, setLogoData, setPages, setSearchItems } from ".";

export const fetchSeoData = async (dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "SEO/getAllSEOSetting",
    data: { search: "" },
    method: "post",
  });

  res?.status && dispatch(setSeoData(res?.data));

  dispatch(setLoading(false));

  return res;
};

export const fetchLogoData = async (dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "settings/getSettingData",
    data: {},
    method: "get",
  });

  res?.status && dispatch(setLogoData(res?.data));

  dispatch(setLoading(false));

  return res;
};

export const fetchPages = async (dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "settings/getAllPageBanner",
    data: {},
    method: "get",
  });

  const myArr =
    Array.isArray(res?.data) &&
    res?.data?.length > 0 &&
    res?.data.map((item: any, index: number) => {
      return {
        title: index === 0 ? "Home" : item?.title,
        ...item,
      };
    });

  res?.status && dispatch(setPages(myArr));

  dispatch(setLoading(false));

  return res;
};

export const verifyEmail = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "product/verifyEmail",
    data: passData,
    method: "post",
  });

  dispatch(setLoading(false));

  return res;
};

export const globalSearch = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "SEO/globalSearch",
    data: passData,
    method: "post",
  });

  if (res?.status) {
    dispatch(setSearchItems(res?.data))
  }

  dispatch(setLoading(false));

  return res;
};
