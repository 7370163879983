// ** Service Imports
import apiCall from "../../Helper/Service";

export const submitInquiry = async (passData: any) => {
    const res = await apiCall({
        url: "quickInquiry/addQuickInquiry",
        data: passData,
        method: "post",
    });

    return res;
};
