// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// ** Pages Imports
import Home from "../pages/Home";
import Product from "../pages/product";
import AboutUs from "../pages/About";
import AllProducts from "../pages/AllProducts";
import CustomSolutions from "../pages/CustomSolutions";
import MarketInsights from "../pages/MarketInsights";
import Blogs from "../pages/Blogs";
import ContactUs from "../pages/ContactUs";
import BlogDetails from "../pages/BlogDetails";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Error404 from "../pages/404";
import ChangePassword from "../pages/ChangePassword";
import ResetPassword from "../pages/ResetPassword";

// ** Third Party Imports
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// ** Custom Imports
import BlankLayout from "../Layout/BlankLayout";
import Default from "../Layout/Default";

// ** Store Imports
import { pages, setMsg } from "../Store/SEO";
import { fetchPages } from "../Store/SEO/SEOAction";

// ** Helper Imports
import Colors from "../Helper/Colors";
import AppUtils from "../Helper/AppUtils";

const components = (defaultKey: string) => {
  switch (defaultKey) {
    case "Home":
      return <Default children={<Home />} />;
    case "AboutUs":
      return <Default children={<AboutUs />} />;
    case "CustomSolutions":
      return <Default children={<CustomSolutions />} />;
    case "MarketInsights":
      return <Default children={<MarketInsights />} />;
    case "Blogs":
      return <Default children={<Blogs />} />;
    case "AllProducts":
      return <Default children={<AllProducts />} />;
    case "ContactUs":
      return <Default children={<ContactUs />} />;
    default:
      return null;
  }
};

const AllRoutes = () => {
  // State
  const [loading, setLoading] = useState<boolean>(true);

  // Hooks
  const allPages = useSelector(pages);
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();

  const getPages = async () => {
    const res = await fetchPages(dispatch);
    if (res?.status) {
      setLoading(false);
    } else {
      dispatch(setMsg({ open: true, message: res?.message, isError: true }));
    }
  };

  useEffect(() => {
    getPages();
  }, []);

  useEffect(() => {
    if (location?.pathname === '/') {
      if (allPages && allPages.length > 0) {
        const homeRoute = allPages.find((pages: any) => pages.default_key === 'Home').url_slug
        if(homeRoute != "/"){
          navigate(`/${homeRoute}`)
        }
      } else {
        getPages()
      }
    }
  }, [allPages.length, location?.pathname])

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: Colors.green }} />
          <Typography sx={{ my: 1 }}>Loading...</Typography>
        </Box>
      ) : (
        <Routes>
          {Array.isArray(allPages) &&
            allPages?.length > 0 &&
            allPages?.map((item: any, index: number) => {
              return (
                <Route
                  key={index}
                  path={`/${item?.url_slug}`}
                  element={components(item?.default_key)}
                />
              );
            })}

          <Route
            path={"/blogs/:id"}
            element={<Default children={<BlogDetails />} />}
          />
          <Route
            path={"/login"}
            element={<BlankLayout children={<Login />} />}
          />
          <Route
            path={"/forgot-password"}
            element={<BlankLayout children={<ForgotPassword />} />}
          />
          <Route
            path={"/reset-password/:id"}
            element={
              <BlankLayout
                children={<BlankLayout children={<ResetPassword />} />}
              />
            }
          />
          <Route
            path={"/change-password/:id"}
            element={
              <BlankLayout
                children={<BlankLayout children={<ChangePassword />} />}
              />
            }
          />
          <Route
            path={"/product/:id"}
            element={<Default children={<Product />} />}
          />
           {/* <Route path={`/`} element={components("Home")} /> */}
          <Route path="*" element={<Default children={<Error404 />} />} />
        </Routes>
      )}
    </>
  );
};

export default AllRoutes;

{
  /* <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/customSolutions" element={<CustomSolutions />} />
      <Route path="/marketInsights" element={<MarketInsights />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:id" element={<BlogDetails />} />
      <Route path="/allProducts:id" element={<Product />} />
      <Route path="/allProducts" element={<AllProducts />} />
    <Route path="/contactUs" element={<ContactUs />} /> */
}
