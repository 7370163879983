// ** Third Party Imports
import { combineReducers } from "redux";

// ** Slice Imports
import CustomSolutionSlice from "./Section";

// Combine the reducers
const CustomSolutionReducer: any = combineReducers({
  section: CustomSolutionSlice,
});

export default CustomSolutionReducer;
