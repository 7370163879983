// ** Third Party Imports
import { combineReducers } from "redux";

// ** Slice Imports
import AboutBannerSlice from "./Banner";

// Combine the reducers
const AboutUsPageReducer: any = combineReducers({
  aboutBanner: AboutBannerSlice,
});

export default AboutUsPageReducer;
