// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { Bounce } from "react-awesome-reveal";

// ** Custom Imports
import Password from "./Password";

const ResetPassword = () => {
  // State
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          component={"img"}
          src="/Congrats.gif"
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            filter: "blur(0.125rem)",
          }}
        />
        <Box sx={{ zIndex: "99" }}>
          <Bounce style={{ width: "-webkit-fill-available" }}>
            <Typography variant="h2" sx={{ wordBreak: "break-word" }}>
              🎉 Congratulations! 🎉
            </Typography>
            <Typography
              sx={{ fontSize: "1.3rem", my: 2, wordBreak: "break-word" }}
            >
              Your email has been verified successfully
            </Typography>
            <Typography sx={{ fontSize: "0.9rem", my: 2 }}>
              Your account credentials have been sent to your email. Please
              check your inbox to proceed.
            </Typography>
          </Bounce>
        </Box>
      </Box>

      {open && <Password open={open} setOpen={setOpen} />}
    </>
  );
};

export default ResetPassword;
