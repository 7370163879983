// ** Third Party Imports
import { configureStore } from "@reduxjs/toolkit";

// ** Slice Imports
import HomePageSlice from "./HomePage";
import AboutUsPageSlice from "./AboutUsPage";
import ContactUsPageSlice from "./ContactUsPage";
import SEOSlice from "./SEO";
import CustomSolutionReducer from "./CustomSolution";
import ProductDetailsReducer from "./Product";
// import BlogDetailsReducer from "./BlogPage";
import BlogsSlice from "./Blogs";
import AuthSlice from "./Auth";
import InquiryPopUpReducer from "./InquieryPopup";

const Store = configureStore({
  reducer: {
    home: HomePageSlice,
    about: AboutUsPageSlice,
    contact: ContactUsPageSlice,
    seo: SEOSlice,
    customSolution: CustomSolutionReducer,
    product: ProductDetailsReducer,
    // blog: BlogDetailsReducer,
    blog: BlogsSlice,
    auth: AuthSlice,
    inquiryPopUp: InquiryPopUpReducer,
  },
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
