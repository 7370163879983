// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const flavorsState: { flavorsData: any; allFold: boolean } = {
  flavorsData: {},
  allFold: true,
};

const FlavorsSlice = createSlice({
  name: "flavors",
  initialState: flavorsState,
  reducers: {
    getFlavorsData(state: any, action: any) {
      state.flavorsData = action.payload;
    },
    setAllFold(state: any, action: any) {
      state.allFold = action.payload;
    },
  },
});

export const { getFlavorsData, setLoading, setAllFold }: any =
  FlavorsSlice.actions;

export default FlavorsSlice.reducer;

export const flavors_data = (state: any) => state.home.flavors.flavorsData;

export const all_fold = (state: any) => state.home.flavors.allFold;
