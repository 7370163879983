// ** React imports
import { useEffect, useState } from "react";

// ** MUI imports
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import Colors from "../../../../Helper/Colors";
import CsIcon from "../../../../components/Icon";

// ** Store Imports
import { fetchFAQData } from "../../../../Store/HomePage/FAQ/FAQAction";
import { FAQ_data } from "../../../../Store/HomePage/FAQ";

const FAQ = () => {
  // State
  const [isOpen, setIsOpen] = useState<number>();

  // Hooks
  const data = useSelector(FAQ_data);
  const dispatch = useDispatch();

  const handleAccordianChange = (panel: any) => (event: any, isExpanded: any) =>
    setIsOpen(isExpanded ? panel : null);

  useEffect(() => {
    fetchFAQData(dispatch);
  }, []);

  return (
    <Box sx={{ width: "80%", mx: "auto", my: 4 }}>
      <Box
        sx={{
          color: Colors.text,
          textAlign: "center",
          mb: 3,
          fontSize: { xs: "1.5rem", sm: "2rem" },
          fontWeight: 500,
        }}
      >
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          {data?.title}
        </Fade>
      </Box>

      {Array.isArray(data?.faq) &&
        data?.faq.length > 0 &&
        data?.faq.map((item: any, i: number) => {
          return (
            <Fade direction="up" triggerOnce key={i}>
              <Accordion
                sx={{
                  my: "0 !important",
                  borderRadius: "0.625rem !important",
                  bgcolor: Colors.bgcolor,
                  overflow: "hidden",
                  border: `0.0625rem solid ${Colors.green}`,
                  borderBottom:
                    i === data?.faq?.length - 1
                      ? `0.0625rem solid ${Colors.green}`
                      : "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.01)",
                  },
                }}
                expanded={isOpen === i}
                onChange={handleAccordianChange(i)}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<CsIcon icon={"mingcute:down-line"} />}
                >
                  <Typography sx={{ fontWeight: 600, color: Colors.text }}>
                    {item?.title}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ "& > p": { color: Colors.text } }}>
                  {item?.description}
                </AccordionDetails>
              </Accordion>
            </Fade>
          );
        })}
    </Box>
  );
};

export default FAQ;
