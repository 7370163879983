// ** MUI Imports
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

// ** Colors Imports
import Colors from "../../Helper/Colors";
import CsAutoComplete from "../CsAutoComplete";

const CsSelect = (props: any) => {
  // Props
  const { label, placeholder, error, helperText, sx, options, value, onChange, id, name, isCountrySelect = false } = props;

  return (
    <>
      <Box sx={sx}>
        <Box
          sx={{
            border: `0.0625rem solid ${error ? Colors.error : Colors.black}`,
            borderRadius: "0.9375rem",
            p: 2,
          }}
        >
          <Typography
            sx={{
              color: error ? Colors.error : Colors.descTxt,
              fontSize: "0.9rem",
              textAlign: "left",
            }}
          >
            {label}
          </Typography>
          <CsAutoComplete
            id={id}
            name={name}
            options={options}
            sx={{
              '& > div > div':{
                height: isCountrySelect ? "43.5px" : "45.5px",
              },
              // ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
              //   height: isCountrySelect ? "43.5px" : "45.5px",
              // },
              ".css-xl4503": {
                border: "0.0625rem solid #000",
                borderRadius: "12px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "0.0625rem solid #000",
                borderRadius: "12px",
              },
              '&:hover & > div > div': {
                border: 'transparent !important',
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />
        </Box>
        {error && (
          <FormHelperText sx={{ color: Colors.error, pl: 1 }}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

export default CsSelect;
