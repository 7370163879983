import { createSlice } from "@reduxjs/toolkit";

const InquiryPopUpScilce = createSlice({
    name: "inquiryPopUp",
    initialState: {
        open: false
    },
    reducers: {
        setOpen(state, action) {
            state.open = action.payload
        }
    }
})

export const { setOpen } = InquiryPopUpScilce.actions

export default InquiryPopUpScilce.reducer

export const inquiry_popup_open = (state: any) => state.inquiryPopUp.open