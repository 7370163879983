// ** MUI Imports
import MuiBox, { BoxProps } from "@mui/material/Box";
import { StepIconProps } from "@mui/material/StepIcon";
import { styled, useTheme } from "@mui/material/styles";

// ** Custom Icon Import
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

// Styled Box component
const Box = styled(MuiBox)<BoxProps>(() => ({
  width: 15,
  height: 15,
  borderWidth: 3,
  borderRadius: "50%",
  borderStyle: "solid",
}));

const CustomDot = (props: StepIconProps) => {
  // ** Props
  const { active, completed, error } = props;

  // ** Hooks
  const theme = useTheme();

  if (error) {
    return (
      <CsIcon
        icon="mdi:alert"
        fontSize={20}
        color={theme.palette.error.main}
        transform="scale(1.2)"
      />
    );
  } else if (completed) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderColor: active ? Colors.green : Colors.white,
          borderWidth: "2px",
        }}
      >
        {active && (
          <Box
            sx={{
              bgcolor: Colors.green,
              borderColor: Colors.green,
              width: "1px",
              height: "1px",
            }}
          />
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderColor: active ? Colors.green : Colors.white,
          borderWidth: "2px",
        }}
      >
        {active && (
          <Box
            sx={{
              bgcolor: Colors.green,
              borderColor: Colors.green,
              width: "1px",
              height: "1px",
            }}
          />
        )}
      </Box>
    );
  }
};

export default CustomDot;
