const countryOptions = [
  {
    short_code: "IN",
    country_name: "India",
    currency: "Indian Rupee",
    symbol: "₹",
    currency_short: "INR",
  },
  {
    short_code: "US",
    country_name: "United States",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "CA",
    country_name: "Canada",
    currency: "Canadian Dollar",
    symbol: "CA$",
    currency_short: "CAD",
  },
  {
    short_code: "AF",
    country_name: "Afghanistan",
    currency: "Afghani",
    symbol: "؋",
    currency_short: "AFN",
  },
  {
    short_code: "AL",
    country_name: "Albania",
    currency: "Albanian Lek",
    symbol: "Lek",
    currency_short: "ALL",
  },
  {
    short_code: "DZ",
    country_name: "Algeria",
    currency: "Algerian Dinar",
    symbol: "د.ج",
    currency_short: "DZD",
  },
  {
    short_code: "AS",
    country_name: "American Samoa",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "AD",
    country_name: "Andorra",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "AO",
    country_name: "Angola",
    currency: "Angolan Kwanza",
    symbol: "Kz",
    currency_short: "AOA",
  },
  {
    short_code: "AI",
    country_name: "Anguilla",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "AQ",
    country_name: "Antarctica",
    currency: "No universal currency",
    symbol: "",
    currency_short: "N/A",
  },
  {
    short_code: "AG",
    country_name: "Antigua and Barbuda",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "AR",
    country_name: "Argentina",
    currency: "Argentine Peso",
    symbol: "$",
    currency_short: "ARS",
  },
  {
    short_code: "AM",
    country_name: "Armenia",
    currency: "Armenian Dram",
    symbol: "֏",
    currency_short: "AMD",
  },
  {
    short_code: "AW",
    country_name: "Aruba",
    currency: "Aruban Florin",
    symbol: "ƒ",
    currency_short: "AWG",
  },
  {
    short_code: "AU",
    country_name: "Australia",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "AT",
    country_name: "Austria",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "AZ",
    country_name: "Azerbaijan",
    currency: "Azerbaijani Manat",
    symbol: "₼",
    currency_short: "AZN",
  },
  {
    short_code: "BS",
    country_name: "Bahamas",
    currency: "Bahamian Dollar",
    symbol: "B$",
    currency_short: "BSD",
  },
  {
    short_code: "BH",
    country_name: "Bahrain",
    currency: "Bahraini Dinar",
    symbol: "ب.د",
    currency_short: "BHD",
  },
  {
    short_code: "BD",
    country_name: "Bangladesh",
    currency: "Bangladeshi Taka",
    symbol: "৳",
    currency_short: "BDT",
  },
  {
    short_code: "BB",
    country_name: "Barbados",
    currency: "Barbadian Dollar",
    symbol: "Bds$",
    currency_short: "BBD",
  },
  {
    short_code: "BY",
    country_name: "Belarus",
    currency: "Belarusian Ruble",
    symbol: "Br",
    currency_short: "BYN",
  },
  {
    short_code: "BE",
    country_name: "Belgium",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "BZ",
    country_name: "Belize",
    currency: "Belize Dollar",
    symbol: "BZ$",
    currency_short: "BZD",
  },
  {
    short_code: "BJ",
    country_name: "Benin",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "BM",
    country_name: "Bermuda",
    currency: "Bermudian Dollar",
    symbol: "BD$",
    currency_short: "BMD",
  },
  {
    short_code: "BT",
    country_name: "Bhutan",
    currency: "Bhutanese Ngultrum",
    symbol: "Nu.",
    currency_short: "BTN",
  },
  {
    short_code: "BO",
    country_name: "Bolivia",
    currency: "Bolivian Boliviano",
    symbol: "Bs.",
    currency_short: "BOB",
  },
  {
    short_code: "BQ",
    country_name: "Bonaire, Sint Eustatius and Saba",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "BA",
    country_name: "Bosnia and Herzegovina",
    currency: "Convertible Mark",
    symbol: "KM",
    currency_short: "BAM",
  },
  {
    short_code: "BW",
    country_name: "Botswana",
    currency: "Botswana Pula",
    symbol: "P",
    currency_short: "BWP",
  },
  {
    short_code: "BV",
    country_name: "Bouvet Island",
    currency: "No universal currency",
    symbol: "",
    currency_short: "N/A",
  },
  {
    short_code: "BR",
    country_name: "Brazil",
    currency: "Brazilian Real",
    symbol: "R$",
    currency_short: "BRL",
  },
  {
    short_code: "IO",
    country_name: "British Indian Ocean Territory",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "BN",
    country_name: "Brunei Darussalam",
    currency: "Brunei Dollar",
    symbol: "B$",
    currency_short: "BND",
  },
  {
    short_code: "BG",
    country_name: "Bulgaria",
    currency: "Bulgarian Lev",
    symbol: "лв",
    currency_short: "BGN",
  },
  {
    short_code: "BF",
    country_name: "Burkina Faso",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "BI",
    country_name: "Burundi",
    currency: "Burundian Franc",
    symbol: "FBu",
    currency_short: "BIF",
  },
  {
    short_code: "CV",
    country_name: "Cabo Verde",
    currency: "Cape Verdean Escudo",
    symbol: "$",
    currency_short: "CVE",
  },
  {
    short_code: "KH",
    country_name: "Cambodia",
    currency: "Cambodian Riel",
    symbol: "៛",
    currency_short: "KHR",
  },
  {
    short_code: "CM",
    country_name: "Cameroon",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "KY",
    country_name: "Cayman Islands",
    currency: "Cayman Islands Dollar",
    symbol: "CI$",
    currency_short: "KYD",
  },
  {
    short_code: "CF",
    country_name: "Central African Republic",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "TD",
    country_name: "Chad",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "CL",
    country_name: "Chile",
    currency: "Chilean Peso",
    symbol: "$",
    currency_short: "CLP",
  },
  {
    short_code: "CN",
    country_name: "China",
    currency: "Chinese Yuan",
    symbol: "¥",
    currency_short: "CNY",
  },
  {
    short_code: "CX",
    country_name: "Christmas Island",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "CC",
    country_name: "Cocos (Keeling) Islands",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "CO",
    country_name: "Colombia",
    currency: "Colombian Peso",
    symbol: "$",
    currency_short: "COP",
  },
  {
    short_code: "KM",
    country_name: "Comoros",
    currency: "Comorian Franc",
    symbol: "CF",
    currency_short: "KMF",
  },
  {
    short_code: "CG",
    country_name: "Congo",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "CD",
    country_name: "Congo, Democratic Republic of the",
    currency: "Congolese Franc",
    symbol: "FC",
    currency_short: "CDF",
  },
  {
    short_code: "CK",
    country_name: "Cook Islands",
    currency: "New Zealand Dollar",
    symbol: "$",
    currency_short: "NZD",
  },
  {
    short_code: "CR",
    country_name: "Costa Rica",
    currency: "Costa Rican Colon",
    symbol: "₡",
    currency_short: "CRC",
  },
  {
    short_code: "HR",
    country_name: "Croatia",
    currency: "Croatian Kuna",
    symbol: "kn",
    currency_short: "HRK",
  },
  {
    short_code: "CU",
    country_name: "Cuba",
    currency: "Cuban Peso",
    symbol: "₱",
    currency_short: "CUP",
  },
  {
    short_code: "CW",
    country_name: "Curacao",
    currency: "Netherlands Antillean Guilder",
    symbol: "ƒ",
    currency_short: "ANG",
  },
  {
    short_code: "CY",
    country_name: "Cyprus",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "CZ",
    country_name: "Czech Republic",
    currency: "Czech Koruna",
    symbol: "Kč",
    currency_short: "CZK",
  },
  {
    short_code: "DK",
    country_name: "Denmark",
    currency: "Danish Krone",
    symbol: "kr",
    currency_short: "DKK",
  },
  {
    short_code: "DJ",
    country_name: "Djibouti",
    currency: "Djiboutian Franc",
    symbol: "Fdj",
    currency_short: "DJF",
  },
  {
    short_code: "DM",
    country_name: "Dominica",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "DO",
    country_name: "Dominican Republic",
    currency: "Dominican Peso",
    symbol: "RD$",
    currency_short: "DOP",
  },
  {
    short_code: "EC",
    country_name: "Ecuador",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "EG",
    country_name: "Egypt",
    currency: "Egyptian Pound",
    symbol: "E£",
    currency_short: "EGP",
  },
  {
    short_code: "SV",
    country_name: "El Salvador",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "GQ",
    country_name: "Equatorial Guinea",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "ER",
    country_name: "Eritrea",
    currency: "Eritrean Nakfa",
    symbol: "Nfk",
    currency_short: "ERN",
  },
  {
    short_code: "EE",
    country_name: "Estonia",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "SZ",
    country_name: "Eswatini",
    currency: "Swazi Lilangeni",
    symbol: "L",
    currency_short: "SZL",
  },
  {
    short_code: "ET",
    country_name: "Ethiopia",
    currency: "Ethiopian Birr",
    symbol: "Br",
    currency_short: "ETB",
  },
  {
    short_code: "FK",
    country_name: "Falkland Islands (Malvinas)",
    currency: "Falkland Islands Pound",
    symbol: "FK£",
    currency_short: "FKP",
  },
  {
    short_code: "FO",
    country_name: "Faroe Islands",
    currency: "Danish Krone",
    symbol: "kr",
    currency_short: "DKK",
  },
  {
    short_code: "FJ",
    country_name: "Fiji",
    currency: "Fiji Dollar",
    symbol: "FJ$",
    currency_short: "FJD",
  },
  {
    short_code: "FI",
    country_name: "Finland",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "FR",
    country_name: "France",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "GF",
    country_name: "French Guiana",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "PF",
    country_name: "French Polynesia",
    currency: "CFP Franc",
    symbol: "F",
    currency_short: "XPF",
  },
  {
    short_code: "TF",
    country_name: "French Southern Territories",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "GA",
    country_name: "Gabon",
    currency: "Central African CFA Franc",
    symbol: "CFA",
    currency_short: "XAF",
  },
  {
    short_code: "GM",
    country_name: "Gambia",
    currency: "Gambian Dalasi",
    symbol: "D",
    currency_short: "GMD",
  },
  {
    short_code: "GE",
    country_name: "Georgia",
    currency: "Georgian Lari",
    symbol: "₾",
    currency_short: "GEL",
  },
  {
    short_code: "DE",
    country_name: "Germany",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "GH",
    country_name: "Ghana",
    currency: "Ghanaian Cedi",
    symbol: "GH₵",
    currency_short: "GHS",
  },
  {
    short_code: "GI",
    country_name: "Gibraltar",
    currency: "Gibraltar Pound",
    symbol: "£",
    currency_short: "GIP",
  },
  {
    short_code: "GR",
    country_name: "Greece",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "GL",
    country_name: "Greenland",
    currency: "Danish Krone",
    symbol: "kr",
    currency_short: "DKK",
  },
  {
    short_code: "GD",
    country_name: "Grenada",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "GP",
    country_name: "Guadeloupe",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "GU",
    country_name: "Guam",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "GT",
    country_name: "Guatemala",
    currency: "Guatemalan Quetzal",
    symbol: "Q",
    currency_short: "GTQ",
  },
  {
    short_code: "GG",
    country_name: "Guernsey",
    currency: "British Pound Sterling",
    symbol: "£",
    currency_short: "GBP",
  },
  {
    short_code: "GN",
    country_name: "Guinea",
    currency: "Guinean Franc",
    symbol: "FG",
    currency_short: "GNF",
  },
  {
    short_code: "GW",
    country_name: "Guinea-Bissau",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "GY",
    country_name: "Guyana",
    currency: "Guyanaese Dollar",
    symbol: "GY$",
    currency_short: "GYD",
  },
  {
    short_code: "HT",
    country_name: "Haiti",
    currency: "Haitian Gourde",
    symbol: "G",
    currency_short: "HTG",
  },
  {
    short_code: "HM",
    country_name: "Heard Island and McDonald Islands",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "VA",
    country_name: "Holy See (Vatican City State)",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "HN",
    country_name: "Honduras",
    currency: "Honduran Lempira",
    symbol: "L",
    currency_short: "HNL",
  },
  {
    short_code: "HK",
    country_name: "Hong Kong",
    currency: "Hong Kong Dollar",
    symbol: "HK$",
    currency_short: "HKD",
  },
  {
    short_code: "HU",
    country_name: "Hungary",
    currency: "Hungarian Forint",
    symbol: "Ft",
    currency_short: "HUF",
  },
  {
    short_code: "IS",
    country_name: "Iceland",
    currency: "Icelandic Krona",
    symbol: "kr",
    currency_short: "ISK",
  },
  {
    short_code: "ID",
    country_name: "Indonesia",
    currency: "Indonesian Rupiah",
    symbol: "Rp",
    currency_short: "IDR",
  },
  {
    short_code: "IR",
    country_name: "Iran, Islamic Republic of",
    currency: "Iranian Rial",
    symbol: "﷼",
    currency_short: "IRR",
  },
  {
    short_code: "IQ",
    country_name: "Iraq",
    currency: "Iraqi Dinar",
    symbol: "ع.د",
    currency_short: "IQD",
  },
  {
    short_code: "IE",
    country_name: "Ireland",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "IM",
    country_name: "Isle of Man",
    currency: "British Pound Sterling",
    symbol: "£",
    currency_short: "GBP",
  },
  {
    short_code: "IL",
    country_name: "Israel",
    currency: "Israeli New Shekel",
    symbol: "₪",
    currency_short: "ILS",
  },
  {
    short_code: "IT",
    country_name: "Italy",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "JM",
    country_name: "Jamaica",
    currency: "Jamaican Dollar",
    symbol: "J$",
    currency_short: "JMD",
  },
  {
    short_code: "JP",
    country_name: "Japan",
    currency: "Japanese Yen",
    symbol: "¥",
    currency_short: "JPY",
  },
  {
    short_code: "JE",
    country_name: "Jersey",
    currency: "British Pound Sterling",
    symbol: "£",
    currency_short: "GBP",
  },
  {
    short_code: "JO",
    country_name: "Jordan",
    currency: "Jordanian Dinar",
    symbol: "JD",
    currency_short: "JOD",
  },
  {
    short_code: "KZ",
    country_name: "Kazakhstan",
    currency: "Kazakhstani Tenge",
    symbol: "₸",
    currency_short: "KZT",
  },
  {
    short_code: "KE",
    country_name: "Kenya",
    currency: "Kenyan Shilling",
    symbol: "KSh",
    currency_short: "KES",
  },
  {
    short_code: "KI",
    country_name: "Kiribati",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "KP",
    country_name: "Korea, Democratic People's Republic of",
    currency: "North Korean Won",
    symbol: "₩",
    currency_short: "KPW",
  },
  {
    short_code: "KR",
    country_name: "Korea, Republic of",
    currency: "South Korean Won",
    symbol: "₩",
    currency_short: "KRW",
  },
  {
    short_code: "KW",
    country_name: "Kuwait",
    currency: "Kuwaiti Dinar",
    symbol: "د.ك",
    currency_short: "KWD",
  },
  {
    short_code: "KG",
    country_name: "Kyrgyzstan",
    currency: "Kyrgyzstani Som",
    symbol: "с",
    currency_short: "KGS",
  },
  {
    short_code: "LA",
    country_name: "Lao People's Democratic Republic",
    currency: "Lao Kip",
    symbol: "₭",
    currency_short: "LAK",
  },
  {
    short_code: "LV",
    country_name: "Latvia",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "LB",
    country_name: "Lebanon",
    currency: "Lebanese Pound",
    symbol: "ل.ل",
    currency_short: "LBP",
  },
  {
    short_code: "LS",
    country_name: "Lesotho",
    currency: "Lesotho Loti",
    symbol: "L",
    currency_short: "LSL",
  },
  {
    short_code: "LR",
    country_name: "Liberia",
    currency: "Liberian Dollar",
    symbol: "L$",
    currency_short: "LRD",
  },
  {
    short_code: "LY",
    country_name: "Libya",
    currency: "Libyan Dinar",
    symbol: "ل.د",
    currency_short: "LYD",
  },
  {
    short_code: "LI",
    country_name: "Liechtenstein",
    currency: "Swiss Franc",
    symbol: "CHF",
    currency_short: "CHF",
  },
  {
    short_code: "LT",
    country_name: "Lithuania",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "LU",
    country_name: "Luxembourg",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MO",
    country_name: "Macao",
    currency: "Macanese Pataca",
    symbol: "MOP$",
    currency_short: "MOP",
  },
  {
    short_code: "MG",
    country_name: "Madagascar",
    currency: "Malagasy Ariary",
    symbol: "Ar",
    currency_short: "MGA",
  },
  {
    short_code: "MW",
    country_name: "Malawi",
    currency: "Malawian Kwacha",
    symbol: "MK",
    currency_short: "MWK",
  },
  {
    short_code: "MY",
    country_name: "Malaysia",
    currency: "Malaysian Ringgit",
    symbol: "RM",
    currency_short: "MYR",
  },
  {
    short_code: "MV",
    country_name: "Maldives",
    currency: "Maldivian Rufiyaa",
    symbol: "Rf",
    currency_short: "MVR",
  },
  {
    short_code: "ML",
    country_name: "Mali",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "MT",
    country_name: "Malta",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MH",
    country_name: "Marshall Islands",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "MQ",
    country_name: "Martinique",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MR",
    country_name: "Mauritania",
    currency: "Mauritanian Ouguiya",
    symbol: "UM",
    currency_short: "MRU",
  },
  {
    short_code: "MU",
    country_name: "Mauritius",
    currency: "Mauritian Rupee",
    symbol: "₨",
    currency_short: "MUR",
  },
  {
    short_code: "YT",
    country_name: "Mayotte",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MX",
    country_name: "Mexico",
    currency: "Mexican Peso",
    symbol: "$",
    currency_short: "MXN",
  },
  {
    short_code: "FM",
    country_name: "Micronesia, Federated States of",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "MD",
    country_name: "Moldova, Republic of",
    currency: "Moldovan Leu",
    symbol: "L",
    currency_short: "MDL",
  },
  {
    short_code: "MC",
    country_name: "Monaco",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MN",
    country_name: "Mongolia",
    currency: "Mongolian Tugrik",
    symbol: "₮",
    currency_short: "MNT",
  },
  {
    short_code: "ME",
    country_name: "Montenegro",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "MS",
    country_name: "Montserrat",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "MA",
    country_name: "Morocco",
    currency: "Moroccan Dirham",
    symbol: "DH",
    currency_short: "MAD",
  },
  {
    short_code: "MZ",
    country_name: "Mozambique",
    currency: "Mozambican Metical",
    symbol: "MT",
    currency_short: "MZN",
  },
  {
    short_code: "MM",
    country_name: "Myanmar",
    currency: "Burmese Kyat",
    symbol: "Ks",
    currency_short: "MMK",
  },
  {
    short_code: "NA",
    country_name: "Namibia",
    currency: "Namibian Dollar",
    symbol: "N$",
    currency_short: "NAD",
  },
  {
    short_code: "NR",
    country_name: "Nauru",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "NP",
    country_name: "Nepal",
    currency: "Nepalese Rupee",
    symbol: "रू",
    currency_short: "NPR",
  },
  {
    short_code: "NL",
    country_name: "Netherlands",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "NC",
    country_name: "New Caledonia",
    currency: "CFP Franc",
    symbol: "F",
    currency_short: "XPF",
  },
  {
    short_code: "NZ",
    country_name: "New Zealand",
    currency: "New Zealand Dollar",
    symbol: "$",
    currency_short: "NZD",
  },
  {
    short_code: "NI",
    country_name: "Nicaragua",
    currency: "Nicaraguan Cordoba",
    symbol: "C$",
    currency_short: "NIO",
  },
  {
    short_code: "NE",
    country_name: "Niger",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "NG",
    country_name: "Nigeria",
    currency: "Nigerian Naira",
    symbol: "₦",
    currency_short: "NGN",
  },
  {
    short_code: "NU",
    country_name: "Niue",
    currency: "New Zealand Dollar",
    symbol: "$",
    currency_short: "NZD",
  },
  {
    short_code: "NF",
    country_name: "Norfolk Island",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "MP",
    country_name: "Northern Mariana Islands",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "NO",
    country_name: "Norway",
    currency: "Norwegian Krone",
    symbol: "kr",
    currency_short: "NOK",
  },
  {
    short_code: "OM",
    country_name: "Oman",
    currency: "Omani Rial",
    symbol: "ر.ع.",
    currency_short: "OMR",
  },
  {
    short_code: "PK",
    country_name: "Pakistan",
    currency: "Pakistani Rupee",
    symbol: "₨",
    currency_short: "PKR",
  },
  {
    short_code: "PW",
    country_name: "Palau",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "PS",
    country_name: "Palestine, State of",
    currency: "Israeli New Shekel",
    symbol: "₪",
    currency_short: "ILS",
  },
  {
    short_code: "PA",
    country_name: "Panama",
    currency: "Panamanian Balboa",
    symbol: "B/.",
    currency_short: "PAB",
  },
  {
    short_code: "PG",
    country_name: "Papua New Guinea",
    currency: "Papua New Guinean Kina",
    symbol: "K",
    currency_short: "PGK",
  },
  {
    short_code: "PY",
    country_name: "Paraguay",
    currency: "Paraguayan Guarani",
    symbol: "₲",
    currency_short: "PYG",
  },
  {
    short_code: "PE",
    country_name: "Peru",
    currency: "Peruvian Nuevo Sol",
    symbol: "S/.",
    currency_short: "PEN",
  },
  {
    short_code: "PH",
    country_name: "Philippines",
    currency: "Philippine Peso",
    symbol: "₱",
    currency_short: "PHP",
  },
  {
    short_code: "PN",
    country_name: "Pitcairn",
    currency: "New Zealand Dollar",
    symbol: "$",
    currency_short: "NZD",
  },
  {
    short_code: "PL",
    country_name: "Poland",
    currency: "Polish Zloty",
    symbol: "zł",
    currency_short: "PLN",
  },
  {
    short_code: "PT",
    country_name: "Portugal",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "PR",
    country_name: "Puerto Rico",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "QA",
    country_name: "Qatar",
    currency: "Qatari Riyal",
    symbol: "ر.ق",
    currency_short: "QAR",
  },
  {
    short_code: "MK",
    country_name: "Republic of North Macedonia",
    currency: "Macedonian Denar",
    symbol: "ден",
    currency_short: "MKD",
  },
  {
    short_code: "RO",
    country_name: "Romania",
    currency: "Romanian Leu",
    symbol: "lei",
    currency_short: "RON",
  },
  {
    short_code: "RU",
    country_name: "Russian Federation",
    currency: "Russian Ruble",
    symbol: "₽",
    currency_short: "RUB",
  },
  {
    short_code: "RW",
    country_name: "Rwanda",
    currency: "Rwandan Franc",
    symbol: "FR",
    currency_short: "RWF",
  },
  {
    short_code: "RE",
    country_name: "Reunion",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "BL",
    country_name: "Saint Barthelemy",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "SH",
    country_name: "Saint Helena",
    currency: "Saint Helena Pound",
    symbol: "£",
    currency_short: "SHP",
  },
  {
    short_code: "KN",
    country_name: "Saint Kitts and Nevis",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "LC",
    country_name: "Saint Lucia",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "MF",
    country_name: "Saint Martin (French part)",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    currency: "East Caribbean Dollar",
    symbol: "EC$",
    currency_short: "XCD",
  },
  {
    short_code: "WS",
    country_name: "Samoa",
    currency: "Samoan Tala",
    symbol: "WS$",
    currency_short: "WST",
  },
  {
    short_code: "SM",
    country_name: "San Marino",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "ST",
    country_name: "Sao Tome and Principe",
    currency: "Sao Tome and Principe Dobra",
    symbol: "Db",
    currency_short: "STN",
  },
  {
    short_code: "SA",
    country_name: "Saudi Arabia",
    currency: "Saudi Riyal",
    symbol: "ر.س",
    currency_short: "SAR",
  },
  {
    short_code: "SN",
    country_name: "Senegal",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "RS",
    country_name: "Serbia",
    currency: "Serbian Dinar",
    symbol: "дин.",
    currency_short: "RSD",
  },
  {
    short_code: "SC",
    country_name: "Seychelles",
    currency: "Seychellois Rupee",
    symbol: "₨",
    currency_short: "SCR",
  },
  {
    short_code: "SL",
    country_name: "Sierra Leone",
    currency: "Sierra Leonean Leone",
    symbol: "Le",
    currency_short: "SLL",
  },
  {
    short_code: "SG",
    country_name: "Singapore",
    currency: "Singapore Dollar",
    symbol: "S$",
    currency_short: "SGD",
  },
  {
    short_code: "SX",
    country_name: "Sint Maarten (Dutch part)",
    currency: "Netherlands Antillean Guilder",
    symbol: "ƒ",
    currency_short: "ANG",
  },
  {
    short_code: "SK",
    country_name: "Slovakia",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "SI",
    country_name: "Slovenia",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "SB",
    country_name: "Solomon Islands",
    currency: "Solomon Islands Dollar",
    symbol: "SI$",
    currency_short: "SBD",
  },
  {
    short_code: "SO",
    country_name: "Somalia",
    currency: "Somali Shilling",
    symbol: "Sh",
    currency_short: "SOS",
  },
  {
    short_code: "ZA",
    country_name: "South Africa",
    currency: "South African Rand",
    symbol: "R",
    currency_short: "ZAR",
  },
  {
    short_code: "GS",
    country_name: "South Georgia and the South Sandwich Islands",
    currency: "No universal currency",
    symbol: "",
    currency_short: "N/A",
  },
  {
    short_code: "SS",
    country_name: "South Sudan",
    currency: "South Sudanese Pound",
    symbol: "SS£",
    currency_short: "SSP",
  },
  {
    short_code: "ES",
    country_name: "Spain",
    currency: "Euro",
    symbol: "€",
    currency_short: "EUR",
  },
  {
    short_code: "LK",
    country_name: "Sri Lanka",
    currency: "Sri Lankan Rupee",
    symbol: "රු",
    currency_short: "LKR",
  },
  {
    short_code: "SD",
    country_name: "Sudan",
    currency: "Sudanese Pound",
    symbol: "SDG",
    currency_short: "SDG",
  },
  {
    short_code: "SR",
    country_name: "Suriname",
    currency: "Surinamese Dollar",
    symbol: "SRD",
    currency_short: "SRD",
  },
  {
    short_code: "SJ",
    country_name: "Svalbard and Jan Mayen",
    currency: "Norwegian Krone",
    symbol: "kr",
    currency_short: "NOK",
  },
  {
    short_code: "SE",
    country_name: "Sweden",
    currency: "Swedish Krona",
    symbol: "kr",
    currency_short: "SEK",
  },
  {
    short_code: "CH",
    country_name: "Switzerland",
    currency: "Swiss Franc",
    symbol: "CHF",
    currency_short: "CHF",
  },
  {
    short_code: "SY",
    country_name: "Syrian Arab Republic",
    currency: "Syrian Pound",
    symbol: "£S",
    currency_short: "SYP",
  },
  {
    short_code: "TW",
    country_name: "Taiwan",
    currency: "New Taiwan Dollar",
    symbol: "NT$",
    currency_short: "TWD",
  },
  {
    short_code: "TJ",
    country_name: "Tajikistan",
    currency: "Tajikistani Somoni",
    symbol: "SM",
    currency_short: "TJS",
  },
  {
    short_code: "TZ",
    country_name: "Tanzania, United Republic of",
    currency: "Tanzanian Shilling",
    symbol: "TSh",
    currency_short: "TZS",
  },
  {
    short_code: "TH",
    country_name: "Thailand",
    currency: "Thai Baht",
    symbol: "฿",
    currency_short: "THB",
  },
  {
    short_code: "TL",
    country_name: "Timor-Leste",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "TG",
    country_name: "Togo",
    currency: "West African CFA Franc",
    symbol: "CFA",
    currency_short: "XOF",
  },
  {
    short_code: "TK",
    country_name: "Tokelau",
    currency: "New Zealand Dollar",
    symbol: "$",
    currency_short: "NZD",
  },
  {
    short_code: "TO",
    country_name: "Tonga",
    currency: "Tongan Pa'anga",
    symbol: "T$",
    currency_short: "TOP",
  },
  {
    short_code: "TT",
    country_name: "Trinidad and Tobago",
    currency: "Trinidad and Tobago Dollar",
    symbol: "TT$",
    currency_short: "TTD",
  },
  {
    short_code: "TN",
    country_name: "Tunisia",
    currency: "Tunisian Dinar",
    symbol: "د.ت",
    currency_short: "TND",
  },
  {
    short_code: "TR",
    country_name: "Turkey",
    currency: "Turkish Lira",
    symbol: "₺",
    currency_short: "TRY",
  },
  {
    short_code: "TM",
    country_name: "Turkmenistan",
    currency: "Turkmenistan Manat",
    symbol: "T",
    currency_short: "TMT",
  },
  {
    short_code: "TC",
    country_name: "Turks and Caicos Islands",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "TV",
    country_name: "Tuvalu",
    currency: "Australian Dollar",
    symbol: "$",
    currency_short: "AUD",
  },
  {
    short_code: "UG",
    country_name: "Uganda",
    currency: "Ugandan Shilling",
    symbol: "USh",
    currency_short: "UGX",
  },
  {
    short_code: "UA",
    country_name: "Ukraine",
    currency: "Ukrainian Hryvnia",
    symbol: "₴",
    currency_short: "UAH",
  },
  {
    short_code: "AE",
    country_name: "United Arab Emirates",
    currency: "United Arab Emirates Dirham",
    symbol: "د.إ",
    currency_short: "AED",
  },
  {
    short_code: "GB",
    country_name: "United Kingdom",
    currency: "British Pound Sterling",
    symbol: "£",
    currency_short: "GBP",
  },
  {
    short_code: "UM",
    country_name: "United States Minor Outlying Islands",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "UY",
    country_name: "Uruguay",
    currency: "Uruguayan Peso",
    symbol: "$U",
    currency_short: "UYU",
  },
  {
    short_code: "UZ",
    country_name: "Uzbekistan",
    currency: "Uzbekistan Som",
    symbol: "so'm",
    currency_short: "UZS",
  },
  {
    short_code: "VU",
    country_name: "Vanuatu",
    currency: "Vanuatu Vatu",
    symbol: "VT",
    currency_short: "VUV",
  },
  {
    short_code: "VE",
    country_name: "Venezuela",
    currency: "Venezuelan Bolivar",
    symbol: "Bs.S.",
    currency_short: "VES",
  },
  {
    short_code: "VN",
    country_name: "Vietnam",
    currency: "Vietnamese Dong",
    symbol: "₫",
    currency_short: "VND",
  },
  {
    short_code: "VG",
    country_name: "Virgin Islands, British",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "VI",
    country_name: "Virgin Islands, U.S.",
    currency: "US Dollar",
    symbol: "$",
    currency_short: "USD",
  },
  {
    short_code: "WF",
    country_name: "Wallis and Futuna",
    currency: "CFP Franc",
    symbol: "F",
    currency_short: "XPF",
  },
  {
    short_code: "EH",
    country_name: "Western Sahara",
    currency: "Moroccan Dirham",
    symbol: "DH",
    currency_short: "MAD",
  },
  {
    short_code: "YE",
    country_name: "Yemen",
    currency: "Yemeni Rial",
    symbol: "﷼",
    currency_short: "YER",
  },
  {
    short_code: "ZM",
    country_name: "Zambia",
    currency: "Zambian Kwacha",
    symbol: "ZK",
    currency_short: "ZMW",
  },
  {
    short_code: "ZW",
    country_name: "Zimbabwe",
    currency: "Zimbabwean Dollar",
    symbol: "Z$",
    currency_short: "ZWL",
  },
];

export default countryOptions;
