// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";

const authState: {
  authUser: any;
  isLoading: boolean;
  isScroll: boolean;
} = {
  authUser: AppUtils.getUserDtls() ? AppUtils.getUserDtls() : {},
  isLoading: false,
  isScroll: true,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    fetchAuthUser(state: any, action: any) {
      state.authUser = action.payload;
    },
    setIsLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    setIsScroll(state: any, action: any) {
      state.isScroll = action.payload;
    },
  },
});

export const { fetchAuthUser, setIsLoading, setIsScroll }: any =
  AuthSlice.actions;

export default AuthSlice.reducer;

export const auth_data = (state: any) => state.auth.authUser;

export const is_loading = (state: any) => state.auth.isLoading;

export const is_scroll = (state: any) => state.auth.isScroll;
