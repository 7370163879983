// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { Fade, JackInTheBox, Slide, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import moment from "moment";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";
import CsButton from "../../../../components/CsButton";

// ** Store Imports
import { pages } from "../../../../Store/SEO";
import { blog_data, blog_setting_data } from "../../../../Store/Blogs";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const Recipes = () => {
  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages);
  const data: any = useSelector(blog_setting_data);
  const blogData = useSelector(blog_data);

  return (
    <>
      <Box sx={{ width: '80%', m: 'auto' }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{ color: Colors.text, fontSize: { xs: "2rem", sm: "3rem" } }}
            >
              <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                {data?.marketInsights?.main_title}
              </Fade>
            </Typography>
            <Slide triggerOnce direction="down">
              <Typography
                sx={{
                  color: Colors.text,
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  mt: 2,
                }}
              >
                {data?.marketInsights?.sub_title}
              </Typography>
            </Slide>
            <Fade triggerOnce direction="up">
              <Typography
                sx={{ color: Colors.descTxt, fontSize: "0.9rem", my: "1rem" }}
              >
                {data?.marketInsights?.description}
              </Typography>
            </Fade>
          </Box>
          <CsImgBox
            src={"/images/Pages/MaroonDot.png"}
            imgSx={{ width: "3rem", height: "auto" }}
            boxSx={{
              position: "relative",
              display: { xs: "none", sm: "block" },
            }}
          />
        </Box>
        <JackInTheBox triggerOnce>
          <Typography
            sx={{
              color: Colors.text,
              fontSize: { xs: "1.5rem", sm: "2rem" },
              mt: 4,
              mb: 2,
            }}
          >
            {data?.marketInsights?.blog_title}
          </Typography>
        </JackInTheBox>
        <Grid container spacing={4} sx={{ mb: 4, overflow: "hidden" }}>
          {Array.isArray(blogData) &&
            blogData?.length > 0 &&
            blogData?.map((item: any, index: number) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Fade triggerOnce direction="up">
                    <Box
                      sx={{
                        border: `0.0625rem solid ${Colors.green}`,
                        borderRadius: "1.25rem",
                        p: "1rem",
                        bgcolor: Colors.bgcolor,
                        overflow: "hidden",
                      }}
                    >
                      <Fade triggerOnce direction="up">
                        <Box
                          component={"img"}
                          className="recipesCard_img"
                          src={item?.blog_image && `${UrlHelper.imgPath}${item?.blog_image}`}
                          alt={
                            item?.blog_image_alt_text
                              ? item?.blog_image_alt_text
                              : item?.blog_title
                          }
                          sx={{
                            width: "100%",
                            height: "13rem",
                            borderRadius: "1.25rem 1.25rem 0 0",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "0.25rem",
                              bgcolor: Colors.green,
                              color: Colors.white,
                              py: "0.2rem",
                              px: "0.4rem",
                              fontSize: "0.8rem",
                            }}
                          >
                            {item?.tag_id?.tag_name}
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CsIcon
                              icon={"uil:calender"}
                              fontSize={15}
                              color={Colors.descTxt}
                            />
                            <Typography
                              sx={{
                                fontSize: "0.8rem",
                                ml: 0.5,
                                color: Colors.descTxt,
                              }}
                            >
                              {moment(item?.createdAt).format("MMMM-DD-YYYY")}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: Colors.black,
                              fontSize: "1.2rem",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {item?.blog_title}
                          </Typography>
                          <Typography
                            sx={{
                              color: Colors.descTxt,
                              fontSize: "0.9rem",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              my: 1,
                              " & > p, h1": {
                                m: `0 !important`,
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.blog_description,
                            }}
                          />
                          <Zoom triggerOnce>
                            <Button
                              size="small"
                              sx={{
                                mt: 1,
                                color: Colors.black,
                                textTransform: "none",
                              }}
                              onClick={() =>
                                navigate(`/blogs/${item?.url_slug}`)
                              }
                              endIcon={<CsIcon icon={"ep:right"} />}
                            >
                              Read More
                            </Button>
                          </Zoom>
                        </Box>
                      </Fade>
                    </Box>
                  </Fade>
                </Grid>
              );
            })}
        </Grid>
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Zoom triggerOnce>
            <CsButton
              label="View All"
              variant="contained"
              onClick={() =>
                navigate(
                  `/${allPages?.find((item: any) => item?.default_key === "Blogs")
                    ?.url_slug
                  }`
                )
              }
            />
          </Zoom>
        </Box>
      </Box>
      <CsImgBox
        src="/images/Pages/BlackPaperGrp3.png"
        imgSx={{ width: "6rem", height: "auto" }}
        boxSx={{ position: "relative", textAlign: "right" }}
      />
    </>
  );
};

export default Recipes;
