// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const bannerState: { customSolutionData: any } = {
  customSolutionData: {},
};

const CustomSolutionSlice = createSlice({
  name: "section",
  initialState: bannerState,
  reducers: {
    getCustomSolutionData(state: any, action: any) {
      state.customSolutionData = action.payload;
    },
  },
});

export const { getCustomSolutionData }: any = CustomSolutionSlice.actions;

export default CustomSolutionSlice.reducer;

export const custom_solution_data = (state: any) =>
  state.customSolution.section.customSolutionData;
