// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";
import Colors from "../../../../Helper/Colors";

const JourneyBegins = () => {
  // State
  const [isShow, setIsShow] = useState<boolean>(false);

  // Hooks
  const bannerData = useSelector(about_banner_data);

  return (
    <Box sx={{ position: "relative", my: "2rem" }}>
      <Box sx={{ width: "80%", m: "auto" }}>
        <Box
          sx={{
            color: Colors.text,
            fontSize: { xs: "2rem", sm: "3rem" },
            width: "-webkit-fill-available",
            "& > div": {
              width: "-webkit-fill-available",
              whiteSpace: "unset !important",
            },
          }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {bannerData?.main_title}
          </Fade>
        </Box>

        <CsImgBox
          src={"/images/Pages/BlurBrownDot.png"}
          imgSx={{ width: "4rem", height: "auto" }}
          boxSx={{
            height: "max-content",
            left: "2rem",
            top: "18rem",
          }}
        />
        <CsImgBox
          src={"/images/Pages/BrownDot.png"}
          imgSx={{ width: "2rem", height: "auto" }}
          boxSx={{
            height: "max-content",
            left: "0",
            top: "22rem",
          }}
        />
        <Grid container spacing={2} sx={{ my: "3rem", alignItems: "center" }}>
          <Grid item xs={12} md={6} sx={{ position: "relative" }}>
            <CsImgBox
              src={"/images/Pages/BluarLeaf1.png"}
              imgSx={{ width: "10rem", height: "auto" }}
              boxSx={{
                height: "max-content",
                right: "0",
                top: "-3rem",
              }}
            />
            <Fade direction="left" triggerOnce>
              <Box
                component={"img"}
                src={bannerData?.first_section?.image && `${UrlHelper.imgPath}${bannerData?.first_section?.image}`}
                alt={
                  bannerData?.first_section?.image_ALT_text
                    ? bannerData?.first_section?.image_ALT_text
                    : "About Us Banner"
                }
                sx={{ width: "100%", height: "auto`" }}
              />
            </Fade>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box>
              <Fade direction="right" triggerOnce>
                <Typography sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>
                  {bannerData?.first_section?.title}
                </Typography>
              </Fade>
              <Fade direction="up" triggerOnce>
                <Typography
                  sx={{ color: Colors.descTxt, my: "20px", fontSize: "0.9rem" }}
                >
                  {isShow
                    ? bannerData?.first_section?.description
                    : bannerData?.first_section?.description.length > 200
                      ? `${bannerData?.first_section?.description.slice(0, 200)}...`
                      : bannerData?.first_section?.description}
                </Typography>
              </Fade>{
                bannerData?.first_section?.description.length > 200 &&
                <Zoom triggerOnce>
                  <LoadingButton
                    size="small"
                    sx={{
                      color: Colors.green,
                      borderBottom: `0.0625rem solid ${Colors.green}`,
                      textTransform: "none",
                      borderRadius: "0",
                    }}
                    onClick={() => setIsShow(!isShow)}
                  >
                    Read {isShow ? "Less" : "More"}
                  </LoadingButton>
                </Zoom>
              }
              <CsImgBox
                src={"/images/Pages/BrownDot.png"}
                imgSx={{ width: "2.5rem", height: "auto" }}
                boxSx={{
                  position: "relative",
                  height: "max-content",
                  textAlign: "right",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default JourneyBegins;
