// ** MUI Imports
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Fade, Slide } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const Partnerships = () => {
  // Hooks
  const data: any = useSelector(custom_solution_data);

  return (
    <Box sx={{ position: "relative", mb: 4 }}>
      <CsImgBox
        src={"/images/Pages/BlackPaperGrp3.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: 0,
          bottom: 0,
          display: { xs: "none", sm: "block" },
        }}
      />
      <Box sx={{ width: '80%', m: 'auto' }}>
        <Typography
          sx={{ fontSize: { xs: "2rem", sm: "3rem", color: Colors.black } }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {data?.sixth_section?.title}
          </Fade>
        </Typography>
        <Fade triggerOnce direction="up">
          <Card
            sx={{
              p: "1rem",
              borderRadius: "0.5rem",
              bgcolor: Colors.cream,
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              overflow: "unset",
              mt: "4rem",
            }}
          >
            <Fade triggerOnce direction="left">
              <Box
                component={"img"}
                src={data?.sixth_section?.image && `${UrlHelper.imgPath}${data?.sixth_section?.image}`}
                alt={
                  data?.sixth_section?.image_ALT_text
                    ? data?.sixth_section?.image_ALT_text
                    : "Partnerships"
                }
                sx={{
                  width: "auto",
                  height: "10rem",
                  position: "relative",
                  top: "-5rem",
                  zIndex: "9999",
                  mr: { sm: 4 },
                }}
              />
            </Fade>
            <Box sx={{ mt: { xs: "-3rem", sm: 0 } }}>
              <Slide triggerOnce direction="down">
                <Typography
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    color: Colors.black,
                  }}
                >
                  {data?.sixth_section?.description}
                </Typography>
              </Slide>
              <Fade triggerOnce direction="up">
                <Typography sx={{ fontSize: "0.9rem", color: Colors.descTxt }}>
                  {data?.sixth_section?.sub_description}
                </Typography>
              </Fade>
            </Box>
          </Card>
        </Fade>
      </Box>
    </Box>
  );
};

export default Partnerships;
