// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";
import { Fade, Zoom } from "react-awesome-reveal";
import ReactPlayer from "react-player/lazy";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Store Imports
import { banner_data } from "../../../../Store/HomePage/Banner";
import { fetchBannerData } from "../../../../Store/HomePage/Banner/BannerAction";
import { logo_data } from "../../../../Store/SEO";

// ** Styles Imports
import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";
import AppUtils from "../../../../Helper/AppUtils";

const Welcome = () => {
  // State
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(true);

  // Hooks
  const data = useSelector(banner_data);
  const dispatch = useDispatch();
  const logoData = useSelector(logo_data);

  useEffect(() => {
    fetchBannerData(dispatch);
  }, [dispatch]);

  return (
    <>
      {AppUtils.checkValue(data) &&
        ((data?.video_type === 1 && AppUtils?.checkValue(data?.video_url)) ||
          (data?.video_type === 2 && AppUtils?.checkValue(data?.video))) && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "calc(100vh - 3.5rem)",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <ReactPlayer
                width={"100%"}
                height={"100%"}
                className="banner_video_wrap"
                playing={isPlaying}
                muted={isMute}
                loop={true}
                url={
                  data?.video_type === 1
                    ? data?.video_url
                    : `${UrlHelper.imgPath}${data?.video}`
                }
              />
            </Box>
            <IconButton
              sx={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                bgcolor: `${Colors.white} !important`,
                borderRadius: "0.5rem",
                zIndex: "1000",
              }}
              onClick={() => setIsMute(!isMute)}
            >
              <CsIcon
                icon={
                  !isMute ? "octicon:unmute-16" : "mingcute:volume-mute-fill"
                }
                fontSize={25}
                color={Colors.green}
              />
            </IconButton>
            <Box
              sx={{
                height: { xs: "80vh", sm: "100vh" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "flex-start", sm: "center" },
                position: "relative",
              }}
            >
              <Fade triggerOnce>
                <Box sx={{ pl: { xs: 2, sm: 4 }, pr: 2, textAlign: "center" }}>
                  <Typography
                    className="welcome_tagline"
                    sx={{
                      color: Colors.white,
                      fontWeight: 600,
                      pl: 4,
                      fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                    }}
                  >
                    {data?.tag_line}
                  </Typography>
                </Box>
              </Fade>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", md: "end" },
                  justifyContent: { md: "end" },
                  flexDirection: { xs: "column-reverse", md: "row" },
                  pl: { xs: 4, md: 0 },
                  pr: { xs: 2, md: 0 },
                  position: "relative",
                  width: { xs: "auto", md: "54rem" },
                  mb: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    position: { md: "absolute" },
                    right: "25rem",
                    top: "1rem",
                  }}
                >
                  <Zoom triggerOnce>
                    <IconButton
                      sx={{
                        bgcolor: `${Colors.white} !important`,
                        mt: { xs: 2, md: 0 },
                      }}
                      size="large"
                      onClick={() => setIsPlaying(!isPlaying)}
                    >
                      <CsIcon
                        icon={isPlaying ? "material-symbols:pause" : "mdi:play"}
                        color={Colors.green}
                      />
                    </IconButton>
                    <Divider
                      sx={{
                        borderWidth: "1px",
                        borderColor: Colors.white,
                        height: "4rem",
                        mt: { xs: 1, md: 4 },
                      }}
                    />
                  </Zoom>
                </Box>

                <Fade direction="right" triggerOnce>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Divider
                      sx={{ borderColor: Colors.white, borderWidth: "1px" }}
                    />
                    <Typography
                      sx={{
                        color: Colors.white,
                        fontSize: "0.9rem",
                        width: { xs: "auto", sm: "16rem" },
                        pl: 1,
                        fontWeight: 400,
                      }}
                    >
                      {data.description}
                    </Typography>
                  </Box>
                </Fade>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: "-4rem", sm: "4rem" },
                  right: { xs: "1rem", sm: "2rem" },
                }}
              >
                <Zoom triggerOnce>
                  <IconButton
                    size="small"
                    onClick={() =>
                      window.open(
                        `tel:+${logoData?.contact_us?.country_code}${logoData?.contact_us?.phone_number}`
                      )
                    }
                  >
                    <Box
                      component={"img"}
                      src="/images/icons/call.svg"
                      sx={{
                        width: "4rem",
                        height: "4rem",
                        position: "relative",
                        left: "1.25rem",
                        zIndex: 99,
                      }}
                    />
                    <Typography
                      sx={{
                        borderTopRightRadius: "20px",
                        bgcolor: `${Colors.green} !important`,
                        pl: 3,
                        pr: 2,
                        py: 1.2,
                        color: Colors.white,
                      }}
                    >
                      Contact Now
                    </Typography>
                  </IconButton>
                </Zoom>
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
};

export default Welcome;
