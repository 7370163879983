// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { process_data } from "../../../../Store/HomePage/Process";
import { fetchProcessData } from "../../../../Store/HomePage/Process/ProcessAction";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const sectors = [
  {
    bgcolor: "#23252a",
    icon: "lucide:package-open",
    number: 5,
  },
  {
    bgcolor: "#a55226",
    icon: "bx:like",
    number: 4,
  },
  {
    bgcolor: "#d07335",
    icon: "tabler:refresh",
    number: 3,
  },
  {
    bgcolor: "#db8a45",
    icon: "uil:process",
    number: 2,
  },
  {
    bgcolor: "#ecb36f",
    icon: "carbon:crop-growth",
    number: 1,
  },
];

const Process = () => {
  // State
  const [currentAngle, setCurrentAngle] = useState<number>(0);
  const [activeSector, setActiveSector] = useState<number>(5);
  const [reverseArray, setReverseArray] = useState<any[]>([]);

  // Hooks
  const data = useSelector(process_data);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProcessData(dispatch);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSector = (activeSector - 1 + sectors.length) % sectors.length;
      handleSectorClick(nextSector);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeSector]);

  const calculateTransform = (index: any, isActive: any, isPrevious: any) => {
    const baseAngle = index * 72;
    const offsetAngle = currentAngle + baseAngle;
    const translateX = isActive ? -0 : isPrevious ? -60 : 160;
    const translateY = isActive ? -100 : isPrevious ? -230 : 10;
    return `rotate(${offsetAngle}deg) translate(${translateX}px, ${translateY}px) rotate(${-offsetAngle}deg)`;
  };

  const handleSectorClick = (index: number) => {
    const sectorsCount = sectors.length;
    const stepsDifference = index - activeSector;
    let rotateSteps = (stepsDifference + sectorsCount) % sectorsCount;
    rotateSteps =
      rotateSteps > sectorsCount / 2 ? rotateSteps - sectorsCount : rotateSteps;
    const newAngle = currentAngle - rotateSteps * 72;

    setCurrentAngle(newAngle);
    setActiveSector(index);
  };

  useEffect(() => {
    if (Array.isArray(data?.sections) && data?.sections.length > 0) {
      setReverseArray([...data?.sections].reverse());
    }
  }, [data]);

  return (
    <Box sx={{ overflow: "hidden", mt: 2, position: "relative" }}>
      <CsImgBox
        src={"/images/Pages/Leaf1.png"}
        imgSx={{ width: "5rem", height: "auto" }}
        boxSx={{ right: 0 }}
      />
      <Fade direction="down" triggerOnce>
        <Typography
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem" },
            fontWeight: 500,
            color: Colors.text,
            pl: { xs: 2, sm: 4 },
          }}
        >
          {data?.section_title}
        </Typography>
      </Fade>
      <Box
        className="main-box-process"
        sx={{
          display: "flex",
          // alignItems: { xs: "end", md: "center" },
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          height: { md: "45rem" },
        }}
      >
        <Fade
          direction="right"
          triggerOnce
          className="res-wheel"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Box
            className="res-wheel-inner"
            sx={{
              width: "fit-content",
              position: "relative",
              mt: "7rem",
              transform: {
                xs: "scale(0.7)",
                sm: "scale(1)",
                md: "scale(1.2)",
              },
              left: { xs: "-11rem", sm: "-8rem", md: "-3rem", xl: "0rem" },

              // top: {
              //   xs: "1rem",
              //   sm: "5rem",
              //   md: "8rem",
              //   lg: "10rem",
              // },
            }}
          >
            <Box
              sx={{
                width: "12.5rem",
                height: "12.5rem",
                position: "absolute",
                right: "-11rem",
                top: "2rem",
              }}
            >
              {reverseArray?.length > 0 &&
                reverseArray?.map((item: any, index: number) => {
                  const isActive = activeSector === index;
                  const isPrevious =
                    activeSector === (index + 1) % reverseArray.length;
                  const isNext =
                    activeSector ===
                    (index - 1 + reverseArray.length) % reverseArray.length;
                  const transformStyle = calculateTransform(
                    index,
                    isActive,
                    isPrevious
                  );

                  return (
                    <Box
                      key={index}
                      sx={{
                        p: 2,
                        maxWidth: "12rem",
                        position: "absolute",
                        transform: transformStyle,
                        transition: "transform 0.3s, opacity 0.3s",
                        opacity: isActive ? 1 : isPrevious || isNext ? 0.3 : 0,
                        pointerEvents: isActive ? "auto" : "none",
                        width: "max-width",
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.text,
                          fontWeight: 600,
                          fontSize: "1.1rem",
                        }}
                      >
                        {sectors[index]?.number}. {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: Colors.descTxt2,
                          fontWeight: 500,
                        }}
                      >
                        {item?.description}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
            <Box sx={{ position: "relative", m: 4 }}>
              <Box
                sx={{
                  height: "5rem",
                  width: "5rem",
                  bgcolor: Colors.white,
                  borderRadius: "100%",
                  p: 1,
                  position: "absolute",
                  zIndex: 1,
                  right: "38%",
                  top: { xs: "9rem", sm: "9.5rem" },
                  boxShadow: `0 0 0.625rem 0 ${Colors.black}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Box
                  component={"img"}
                  src="/images/Pages/Footer.jpg"
                  sx={{ height: "auto", width: "100%" }}
                />
              </Box>
              <Box
                id="clickable-wheel"
                sx={{
                  transform: `rotate(${currentAngle}deg)`,
                  transition: "transform 0.3s ease-out",
                  position: "relative",
                  width: "25rem",
                  height: "25rem",
                }}
              >
                {Array.isArray(sectors) &&
                  sectors?.length > 0 &&
                  sectors?.map((item: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={[
                          {
                            transform: `rotate(${
                              (360 / sectors.length) * index
                            }deg) scale(${activeSector === index ? 1.15 : 1})`,
                            position: "absolute",
                            width: "25rem",
                            height: "25rem",
                            borderRadius: "100%",
                            clip: "rect(0, 25rem, 11.875rem, 12.5rem)",
                            cursor: "pointer",
                          },
                          activeSector === index && {
                            zIndex: 1,
                          },
                        ]}
                        onClick={() => handleSectorClick(index)}
                      >
                        <Box
                          sx={{
                            transform: "rotate(76deg)",
                            bgcolor: item?.bgcolor,
                            position: "absolute",
                            width: "400px",
                            height: "400px",
                            borderRadius: "100%",
                            clip: "rect(0, 12.5rem, 11.875rem, 0)",
                          }}
                        >
                          <Box
                            component={CsIcon}
                            icon={item.icon}
                            color={Colors.white}
                            fontSize={40}
                            sx={{
                              transform: "rotate(-76deg)",
                              position: "absolute",
                              left: "6rem",
                              top: "4rem",
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Fade>

        <Box sx={{ ml: { xs: "auto", md: "unset" }, mt: "auto" }}>
          <Fade direction="left" triggerOnce>
            <Box
              component={"img"}
              className="spice_img"
              src={data?.image && `${UrlHelper.imgPath}${data?.image}`}
              alt={data?.image_ALT_text ? data?.image_ALT_text : "Red Chilli"}
              sx={{
                width: { xs: "-webkit-fill-available", sm: "auto" },
                height: "20rem",
                transform: "scaleX(-1)",
              }}
            />
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};

export default Process;
