// ** Third Party Imports
import { combineReducers } from "redux";

// ** Slice Imports
import DetailsSlice from "./DetailsPage";
import BannerSlice from "./Banner";

// Combine the reducers
const ProductDetailsReducer: any = combineReducers({
  details: DetailsSlice,
  banner: BannerSlice,
});

export default ProductDetailsReducer;
