// ** React Imoprts
import { useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

// ** Cusotm Imports
import CsImgBox from "../../components/CsImgBox";

// ** Third Party Imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// ** Third Party Imports
import { logo_data, pages } from "../../Store/SEO";

// ** Icon Imports
import CsIcon from "../../components/Icon";

// ** Store Imports
import { contact_data } from "../../Store/ContactUsPage/Section";
import { footer_product } from "../../Store/HomePage/Product";
import { fetchContactUs } from "../../Store/ContactUsPage/Section/ContactAction";
import { getProducts } from "../../Store/HomePage/Product/ProductAction";

// ** Helper Imports
import Colors from "../../Helper/Colors";
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";
import CsButton from "../../components/CsButton";
import Marquee from "../../pages/Home/Components/Marquee";

const Footer = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allPages = useSelector(pages);
  const contactData = useSelector(contact_data);
  const allProducts = useSelector(footer_product);
  const logoData = useSelector(logo_data);
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (
      AppUtils.checkValue(contactData) &&
      Object?.keys(contactData)?.length === 0
    ) {
      fetchContactUs(dispatch);
    }
    if (Array.isArray(allProducts) && allProducts?.length > 0) {
      return;
    } else {
      getProducts(
        {
          search: "",
          status: 0,
          startToken: 1,
          endToken: 10,
          certifications: [],
          typeOfSpices: [],
          country: [],
        },
        dispatch,
        true
      );
    }
  }, []);

  const FooterList = (
    item: any,
    isPage: boolean,
    index: number,
    isProduct: boolean
  ) => {
    return (
      <Box
        key={index}
        sx={{
          display: "flex",
          alignItems: "center",
          my: 2,
          cursor: "pointer",
          width: "fit-content",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            "& > p": { color: Colors.green },
            "& > svg": { color: `${Colors.green} !important` },
            transform: "scale(1.01)",
          },
        }}
        onClick={() => {
          if (isPage) {
            if (isProduct) {
              navigate(`/product/${item?.url_slug}`);
            } else {
              navigate(
                item?.default_key === "Home" ? "/" : `/${item?.url_slug}`
              );
            }
          } else {
            navigate(
              `/${allPages?.find(
                (item: any) => item?.default_key === "AllProducts"
              )?.url_slug
              }`
            );
          }
        }}
      >
        <CsIcon
          icon={"charm:chevrons-right"}
          fontSize={"1rem"}
          color={Colors.text}
        />
        <Typography
          sx={[
            {
              pl: 1,
              fontSize: "1rem",
              fontWeight: 500,
              color: isProduct
                ? item?.url_slug === id
                  ? Colors.green
                  : Colors.text
                : location?.pathname === "/" && item?.url_slug === "/"
                  ? Colors.green
                  : item?.url_slug === location?.pathname.replace("/", "")
                    ? Colors.green
                    : Colors.text,
            },
            isPage &&
            !isProduct && {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: { xs: "25ch", md: "30ch" },
            },
          ]}
        >
          {isPage ? (isProduct ? item?.product_name : item?.title) : item}
        </Typography>
      </Box>
    );
  };

  return (
    <>{location.pathname.split('/')[1] !== 'product' && <Marquee />}
      <Box sx={{ bgcolor: Colors.bgcolor, position: "relative" }}>
        <Box sx={{ width: "80%", m: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "center", sm: "end" },
            }}
          >
            <Box
              component={"img"}
              src={
                AppUtils.checkValue(logoData?.logo) && logoData?.logo
                  ? `${UrlHelper.imgPath}${logoData?.logo}`
                  : "/images/icons/LOGO.svg"
              }
              alt={
                AppUtils.checkValue(logoData?.logo_alt_text)
                  ? logoData?.logo_alt_text
                  : "Vora Spices Logo"
              }
              onClick={() => {
                const homeRoute = allPages && allPages.length > 0 && allPages.find((pages: any) => pages.default_key === 'Home').url_slug
                if (allPages && allPages.length > 0 && homeRoute != "/") {
                  navigate(`/${homeRoute}`)
                } else {
                  navigate(`/`)
                }
              }}
              sx={{ width: "10rem", height: "auto", my: 2, cursor: "pointer" }}
            />
            <CsImgBox
              src={"/images/Pages/MaroonDot.png"}
              imgSx={{ width: "3rem", height: "auto" }}
              boxSx={{ position: "relative", width: { sm: "15rem" } }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "start", sm: "center" },
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {AppUtils.checkValue(logoData?.contact_us?.description) && (
              <Typography
                sx={{
                  fontWeight: 500,
                  color: Colors.text,
                  width: { sm: "40rem" },
                }}
              >
                {logoData?.contact_us?.description}
              </Typography>
            )}
            <Box
              sx={{ display: "flex", alignItems: "center", mt: { xs: 1, sm: 0 } }}
            >
              {AppUtils.checkValue(
                contactData?.contact_info?.social_media?.fb_link
              ) && (
                  <IconButton
                    size="small"
                    onClick={() =>
                      window.open(
                        contactData?.contact_info?.social_media?.fb_link,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"basil:facebook-solid"}
                      color={Colors.text}
                      fontSize={30}
                    />
                  </IconButton>
                )}
              {AppUtils.checkValue(
                contactData?.contact_info?.social_media?.insta_link
              ) && (
                  <IconButton
                    size="small"
                    onClick={() =>
                      window.open(
                        contactData?.contact_info?.social_media?.insta_link,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"lets-icons:insta-duotone-line"}
                      color={Colors.text}
                      fontSize={30}
                    />
                  </IconButton>
                )}
              {AppUtils.checkValue(
                contactData?.contact_info?.social_media?.linkedin_link
              ) && (
                  <IconButton
                    size="small"
                    onClick={() =>
                      window.open(
                        contactData?.contact_info?.social_media?.linkedin_link,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"mdi:linkedin"}
                      color={Colors.text}
                      fontSize={30}
                    />
                  </IconButton>
                )}
              {AppUtils.checkValue(
                contactData?.contact_info?.social_media?.twitter_link
              ) && (
                  <IconButton
                    size="small"
                    onClick={() =>
                      window.open(
                        contactData?.contact_info?.social_media?.twitter_link,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"prime:twitter"}
                      color={Colors.text}
                      fontSize={23}
                    />
                  </IconButton>
                )}
            </Box>
          </Box>
          <Grid container sx={{ mt: { xs: 2, sm: 4 } }}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  color: Colors.text,
                  mb: 2,
                }}
              >
                Products
              </Typography>
              <CsButton
                label="Show all product"
                variant="contained"
                onClick={() => navigate(`/${allPages?.find((item: any) => item?.default_key === "AllProducts")?.url_slug}`)}
              />
              {Array.isArray(allProducts) &&
                allProducts?.length > 0 &&
                allProducts?.map((item: any, index: number) =>
                  item?.is_publish && index < 10 && FooterList(item, true, index, true)
                )}

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  color: Colors.text,
                  mb: 2,
                }}
              >
                Quick Links
              </Typography>
              {Array.isArray(allPages) &&
                allPages?.length > 0 &&
                allPages.map((item: any, index: number) =>
                  FooterList(item, true, index, false)
                )}
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    color: Colors.text,
                    mb: 2,
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              {/* Us Address */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%", md: "100%" },
                  mt: { xs: 3, md: 0 },
                }}
              >
                {AppUtils.checkValue(logoData?.contact_us?.section_title) && (
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 700,
                      color: Colors.text,
                      mb: 2,
                    }}
                  >
                    {logoData?.contact_us?.section_title}
                  </Typography>
                )}
                {AppUtils.checkValue(logoData?.contact_us?.address) && (
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      mr: "1rem",
                      color: Colors.text,
                      transition: "all 0.2s ease-in-out",
                      cursor: "pointer",
                      "&:hover": {
                        color: Colors.green,
                        transform: "scale(1.01)",
                      },
                    }}
                    onClick={() =>
                      window.open(
                        `http://maps.google.com/?q=${logoData?.contact_us?.address}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    {logoData?.contact_us?.address}
                  </Typography>
                )}
                {AppUtils.checkValue(logoData?.contact_us?.phone_number) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 3,
                      mb: 1,
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        "& > p": { color: Colors.green },
                        "& > svg": { color: `${Colors.green} !important` },
                        transform: "scale(1.01)",
                      },
                    }}
                    onClick={() =>
                      window.open(`tel:+${logoData?.contact_us?.country_code}${logoData?.contact_us?.phone_number}`)
                    }
                  >
                    <CsIcon
                      icon={"eva:phone-call-fill"}
                      color={Colors.text}
                      fontSize={"1rem"}
                    />
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: Colors.text,
                        pl: 2,
                      }}
                    >
                      +{logoData?.contact_us?.country_code} {logoData?.contact_us?.phone_number}
                    </Typography>
                  </Box>
                )}
                {AppUtils.checkValue(logoData?.contact_us?.email) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      mb: 1,
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        "& > p": { color: Colors.green },
                        "& > svg": { color: `${Colors.green} !important` },
                        transform: "scale(1.01)",
                      },
                    }}
                    onClick={() =>
                      window.open(
                        `mailto:${logoData?.contact_us?.email}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"octicon:mail-16"}
                      color={Colors.text}
                      fontSize={"1rem"}
                    />
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: Colors.text,
                        pl: 2,
                      }}
                    >
                      {logoData?.contact_us?.email}
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Our Address */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%", md: "100%" },
                  mt: 3,
                }}
              >
                {AppUtils.checkValue(logoData?.contact_us?.second_address) && (
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 700,
                      color: Colors.text,
                      mb: 2,
                    }}
                  >
                    {logoData?.contact_us?.second_section_title}
                  </Typography>
                )}
                {AppUtils.checkValue(logoData?.contact_us?.second_address) && (
                  <Typography
                    sx={{
                      mr: "1rem",
                      fontSize: "1rem",
                      fontWeight: 500,
                      color: Colors.text,
                      transition: "all 0.2s ease-in-out",
                      cursor: "pointer",
                      "&:hover": {
                        color: Colors.green,
                        transform: "scale(1.01)",
                      },
                    }}
                    onClick={() =>
                      window.open(
                        `http://maps.google.com/?q=${logoData?.contact_us?.second_address}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    {logoData?.contact_us?.second_address}
                  </Typography>
                )}
                {AppUtils.checkValue(
                  logoData?.contact_us?.second_phone_number
                ) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 3,
                        mb: 1,
                        cursor: "pointer",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          "& > p": { color: Colors.green },
                          "& > svg": { color: `${Colors.green} !important` },
                          transform: "scale(1.01)",
                        },
                      }}
                      onClick={() =>
                        window.open(
                          `tel:+${logoData?.contact_us?.second_country_code}${logoData?.contact_us?.second_phone_number}`
                        )
                      }
                    >
                      <CsIcon
                        icon={"eva:phone-call-fill"}
                        color={Colors.text}
                        fontSize={"1rem"}
                      />
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 500,
                          color: Colors.text,
                          pl: 2,
                        }}
                      >
                        +{logoData?.contact_us?.second_country_code} {logoData?.contact_us?.second_phone_number}
                      </Typography>
                    </Box>
                  )}
                {AppUtils.checkValue(logoData?.contact_us?.second_email) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      mb: 1,
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        "& > p": { color: Colors.green },
                        "& > svg": { color: `${Colors.green} !important` },
                        transform: "scale(1.01)",
                      },
                    }}
                    onClick={() =>
                      window.open(
                        `mailto:${logoData?.contact_us?.second_email}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    <CsIcon
                      icon={"octicon:mail-16"}
                      color={Colors.text}
                      fontSize={"1rem"}
                    />
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: Colors.text,
                        pl: 2,
                      }}
                    >
                      {logoData?.contact_us?.second_email}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CsImgBox
              src={"/images/Pages/BlurLeaf.png"}
              imgSx={{ width: { xs: "7rem", sm: "10rem" }, height: "auto" }}
              boxSx={{
                left: { xs: "-2rem", sm: "-3rem" },
                position: { xs: "relative", md: "relative" },
              }}
            />
            <CsImgBox
              src={"/images/Pages/Leaf3.png"}
              imgSx={{ width: { xs: "4rem", sm: "6rem" }, height: "auto" }}
              boxSx={{ right: "0", position: "relative" }}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              position: "relative",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              px: 2,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: "40%",
                left: "35%",
              }}
            >
              <Box
                component={"img"}
                src="/images/Pages/Footer.jpg"
                alt="Vora Spices"
                sx={{
                  width: "auto",
                  height: { xs: "4rem", sm: "8rem", md: "16rem" },
                  bgcolor: Colors.bgcolor,
                  overflow: "hidden",
                  borderRadius: "100%",
                  animation: "spin 2.5s linear infinite",
                  "@keyframes spin": {
                    "0%": { transform: "rotate(0deg)" },
                    "100%": { transform: "rotate(360deg)" },
                  },
                }}
              />
            </Box>
            <Box
              component={"img"}
              src="/images/icons/VoraSpices.svg"
              sx={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
