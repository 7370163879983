const mode: any = {
  PRODUCTION: "https://voraspices.tjcg.in",
  // DEVELOPMENT: "https://76a0-103-105-234-239.ngrok-free.app",
  // DEVELOPMENT: " http://localhost:8000",
  DEVELOPMENT: "https://voraadmin.tjcg.in",
};

let appMode: any = process.env.REACT_APP_MODE;

if (!appMode || !mode.hasOwnProperty(appMode)) {
  console.error("Invalid or missing app mode. Defaulting to development.");
  appMode = "DEVELOPMENT";
}

const UrlHelper = {
  serverUrl: mode[appMode],
  imgPath: `${mode[appMode]}/api/preview/`,
  customerUrl: `https://voracustomer.tjcg.in`,
};

export default UrlHelper;
