// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";
import Colors from "../../../../Helper/Colors";

const OurMission = () => {
  // State
  const [isShow, setIsShow] = useState<boolean>(false);

  // Hooks
  const aboutUsData = useSelector(about_banner_data);

  return (
    <Grid
      container
      sx={{
        my: "5rem",
        bgcolor: Colors.bgcolor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box sx={{ pl: { xs: "1rem", sm: "1.5rem", lg: "10rem" } }}>
          <Fade direction="left" triggerOnce>
            <Typography sx={{ fontSize: { xs: "2rem", sm: "3rem" } }}>
              <span style={{ color: Colors.green }}>
                {aboutUsData?.second_section?.title.split(" ")?.[0]}
              </span>&nbsp;
              <span style={{ color: Colors.text }}>
                {aboutUsData?.second_section?.title.split(" ")?.[1]}
              </span>
            </Typography>
          </Fade>

          <Fade direction="up" triggerOnce>
            <Typography
              sx={{ color: Colors.text, my: "1.25rem", fontSize: "0.9rem" }}
            >
              {isShow
                ? aboutUsData?.second_section?.description
                : aboutUsData?.second_section?.description.length > 200
                  ? `${aboutUsData?.second_section?.description.slice(0, 200)}...`
                  : aboutUsData?.second_section?.description}
            </Typography>
          </Fade>{
            aboutUsData?.second_section?.description.length > 200 &&
            <Zoom triggerOnce>
              <LoadingButton
                size="small"
                sx={{
                  color: Colors.green,
                  borderBottom: `0.0625rem solid ${Colors.green}`,
                  textTransform: "none",
                  borderRadius: "0",
                }}
                onClick={() => setIsShow(!isShow)}
              >
                Read {isShow ? "Less" : "More"}
              </LoadingButton>
            </Zoom>
          }
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Fade direction="right" triggerOnce>
          <Box
            component={"img"}
            src={aboutUsData?.second_section?.image && `${UrlHelper.imgPath}${aboutUsData?.second_section?.image}`}
            alt={
              aboutUsData?.second_section?.image_ALT_text
                ? aboutUsData?.second_section?.image_ALT_text
                : "Our Mission"
            }
            sx={{ width: "100%", height: "auto`" }}
          />
        </Fade>
      </Grid>
    </Grid>
  );
};

export default OurMission;
