// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const signatureState: { signatureData: any } = {
  signatureData: {},
};

const SignatureSlice = createSlice({
  name: "signature",
  initialState: signatureState,
  reducers: {
    getSignatureData(state: any, action: any) {
      state.signatureData = action.payload;
    },
  },
});

export const { getSignatureData, setLoading }: any = SignatureSlice.actions;

export default SignatureSlice.reducer;

export const signature_data = (state: any) =>
  state.home.signature.signatureData;
