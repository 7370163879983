// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const qualityState: { qualityData: any } = {
  qualityData: {},
};

const QualitySlice = createSlice({
  name: "quality",
  initialState: qualityState,
  reducers: {
    getQualityData(state: any, action: any) {
      state.qualityData = action.payload;
    },
  },
});

export const { getQualityData, setLoading }: any = QualitySlice.actions;

export default QualitySlice.reducer;

export const quality_data = (state: any) => state.home.quality.qualityData;
