// ** React Imports
import { useCallback, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// ** Custom Imoprts
import CsImgBox from "../../../../components/CsImgBox";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

// ** Store Imports
import { blog_details_data } from "../../../../Store/Blogs";
import { fetchBlogDetails } from "../../../../Store/Blogs/BlogsAction";
import { pages } from "../../../../Store/SEO";

// ** Helpers Import
import UrlHelper from "../../../../Helper/Url";
import AppUtils from "../../../../Helper/AppUtils";

const Details = () => {
  // Hooks
  const data = useSelector(blog_details_data);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const allPages = useSelector(pages);

  const getDetails = useCallback(async () => {
    if (AppUtils.checkValue(id)) {
      const res = await fetchBlogDetails({ url_slug: id }, dispatch);
      !res?.status &&
        navigate(
          `/${allPages?.find((item: any) => item?.default_key === "Blogs")
            ?.url_slug
          }`
        );
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (AppUtils.checkValue(id)) {
      getDetails();
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <CsImgBox
        src={"/images/Pages/BDVectore1.png"}
        imgSx={{ width: "10rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "10rem",
          top: "10rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore2.png"}
        imgSx={{ width: "9rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "-10px",
          top: "62rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BlurBrownDot.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "3rem",
          top: "75rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore3.png"}
        imgSx={{ width: "6rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          left: "0",
          top: "81rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/RedDot.png"}
        imgSx={{ width: "1rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: { sm: "5rem", lg: "20rem" },
          top: "115rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore4.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          left: "0",
          bottom: "40rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore5.png"}
        imgSx={{ width: "11rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "0",
          bottom: "0",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore6.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "0",
          bottom: "20rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BDVectore7.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          height: "max-content",
          right: "0",
          bottom: "40rem",
          display: { xs: "none", md: "block" },
        }}
      />
      <Box sx={{ py: "5rem", position: "relative", width: '80%', m: "auto" }}>
        <Box
          sx={{
            color: Colors.text,
            fontSize: { xs: "2rem", sm: "3rem" },
            width: "-webkit-fill-available",
            "& > div": {
              width: "-webkit-fill-available",
              whiteSpace: "unset !important",
            },
          }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {data?.blog_title ? data?.blog_title : "N/A"}
          </Fade>
        </Box>
        <Fade triggerOnce direction="up">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: "0.5rem" }}>
              <CsIcon
                icon={"material-symbols:date-range"}
                fontSize={"1.2rem"}
                color={Colors.black}
              />
              <Typography
                sx={{ color: Colors.black, fontSize: "0.9rem", ml: "0.25rem" }}
              >
                {data?.createdAt
                  ? moment(data?.createdAt).format("DD MMM YYYY")
                  : "N/A"}
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                height: "1.2rem",
                width: "0.25rem",
                borderColor: Colors.black,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", ml: "0.5rem" }}>
              <CsIcon
                icon={"bitcoin-icons:clock-outline"}
                fontSize={"1.2rem"}
                color={Colors.black}
              />
              <Typography
                sx={{ color: Colors.black, fontSize: "0.9rem", ml: "0.25rem" }}
              >
                {data?.createdAt
                  ? moment(data?.createdAt).format("hh:mm A")
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Fade>

        <Fade triggerOnce direction="up">
          <Box
            component={"img"}
            src={data?.blog_image && `${UrlHelper.imgPath}${data?.blog_image}`}
            alt={
              data?.blog_image_alt_text
                ? data?.blog_image_alt_text
                : data?.blog_title
            }
            sx={{
              my: "1rem",
              width: "100%",
              height: "70vh",
              objectFit: 'cover',
              borderRadius: "1.25rem",
            }}
          />
        </Fade>
        <Fade triggerOnce direction="left">
          <Typography
            // sx={{
            //   color: Colors.text,
            //   fontSize: { xs: "1.5rem", sm: "2rem" },
            //   ml: "0.25rem",
            //   mb: "0.5rem",
            // }}
            dangerouslySetInnerHTML={{
              __html: data?.blog_description,
            }}
          />
        </Fade>

        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ my: "3rem", mx: "0" }}
        />
      </Box>
    </Box>
  );
};

export default Details;
