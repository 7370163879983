// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// ** Third Party Imports
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const ProcessOverview = () => {
  // Hooks
  const data: any = useSelector(custom_solution_data);

  return (
    <>
      <Slide triggerOnce direction="down">
      </Slide>
      <Grid container spacing={4} sx={{ width: "100%", m: 0, alignItems: 'center' }}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem" },
              color: Colors.black,
              my: { sm: 4 },
              ml: 4,
              mt: { xs: 4, sm: 4 },
            }}
          >
            {data?.second_section?.title}
          </Typography>
          {Array.isArray(data?.second_section?.sub_sections) &&
            data?.second_section?.sub_sections?.length > 0 &&
            data?.second_section?.sub_sections?.map(
              (item: any, index: number) => {
                return (
                  <Box key={index} sx={{ display: "flex", mb: 2 }}>
                    <Slide triggerOnce direction="left">
                      <Typography
                        sx={{
                          fontSize: "1.8rem",
                          fontWeight: 500,
                          color: Colors.black,
                        }}
                      >{`0${index + 1}`}</Typography>
                    </Slide>
                    <Box sx={{ ml: 2, pr: { xs: 2, sm: 0 } }}>
                      <Slide triggerOnce direction="down">
                        <Typography
                          sx={{
                            fontSize: "1.5rem",
                            fontWeight: 500,
                            color: Colors.black,
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Slide>
                      <Fade triggerOnce direction="up">
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: Colors.descTxt,
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Fade>
                    </Box>
                  </Box>
                );
              }
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Fade triggerOnce direction="right">
            <Box
              component={"img"}
              src={data?.second_section?.image && `${UrlHelper.imgPath}${data?.second_section?.image}`}
              alt={
                data?.second_section?.image_ALT_text
                  ? data?.second_section?.image_ALT_text
                  : "Process Overview"
              }
              sx={{ width: "100%", height: "auto" }}
            />
          </Fade>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessOverview;
