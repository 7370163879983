// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";

// ** Icon Imports
import CsIcon from "../Icon";

// ** Helper Imports
import Colors from "../../Helper/Colors";
import { useSelector } from "react-redux";
import { pages } from "../../Store/SEO";
interface Props {
  title: string;
  src: string;
}

const CsHeader = (props: any) => {
  // Props
  const { title, src }: Props = props;
  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages)

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundImage: `${Colors.overlay}, url(${src})`,
        height: { xs: "13rem", sm: "20rem" },
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <Box sx={{ pl: 4 }}>
        {props?.isError ? (
          <Typography sx={{ color: Colors.white, fontSize: "1rem" }}>
            Page Not Found
          </Typography>
        ) : (
          <Breadcrumbs
            separator={
              <CsIcon
                icon={"mingcute:arrows-right-line"}
                color={Colors.white}
              />
            }
            sx={{
              "& .MuiBreadcrumbs-separator": { mx: "0.2rem" },
              ml: "-1rem",
            }}
          >
            <LoadingButton
              size="small"
              sx={{
                color: Colors.white,
                borderRadius: "0.5rem",
                fontWeight: 400,
              }}
              onClick={() => {
                const homeRoute = allPages && allPages.length > 0 && allPages.find((pages: any) => pages.default_key === 'Home').url_slug
                if (homeRoute != "/") {
                  console.log("homeRoute ::", homeRoute);
                  navigate(`/${homeRoute}`)
                } else {
                  navigate(`/`)
                }
              }}
            >
              Home
            </LoadingButton>
            <LoadingButton
              size="small"
              sx={{
                color: Colors.white,
                borderRadius: "0.5rem",
                fontWeight: 400,
              }}
            >
              {title}
            </LoadingButton>
          </Breadcrumbs>
        )}
        <Typography
          sx={{
            fontSize: { xs: "1.8rem", sm: "3rem" },
            color: Colors.white,
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default CsHeader;
