// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getProcessData } from ".";

export const fetchProcessData = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getOurProcess",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getProcessData(res?.data));
  }
};
