// ** MUI Imports
import Box from "@mui/material/Box";

const CsImgBox = (props: any) => {
  // Props
  const { src, imgSx, boxSx } = props;

  return (
    <Box sx={[{ position: "absolute" }, boxSx]}>
      <Box
        component={"img"}
        src={src}
        sx={{
          // "@keyframes upDown": {
          //   "0%": { transform: "translateY(0)" },
          //   "50%": { transform: "translateY(-10px)" },
          //   "100%": { transform: "translateY(0)" },
          // },
          // animation: "upDown 2s infinite",
          ...imgSx,
        }}
      />
    </Box>
  );
};

export default CsImgBox;
