// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Thierd party Imports
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import ContactInfo from "./Components/ContactInfo";
import Inquiry from "./Components/Inquiry";

// Store Imports
import { contact_data } from "../../Store/ContactUsPage/Section";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";

const ContactUs = () => {
  // Hooks
  const bannerData = useSelector(contact_data);
  const seoData = useSelector(seo_data);

  const userIp = async () => {
    const res = await AppUtils.getIp();

    return res;
  };

  useEffect(() => {
    AppUtils.setVisitorData(userIp(), "contact_us", "Contact us", "CONTACT_US");
    AppUtils.setDailyVisitor({ pageKey: 'contact_us' });
  }, []);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "ContactUs" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <meta name="keywords" content={item?.meta_keywords} />
                )}
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <script type="application/ld+json">
                    {item?.meta_schema}
                  </script>
                )}
              </Helmet>
            )
        )}
      <Box sx={{ overflowX: "hidden" }}>
        {
          bannerData && bannerData?.title && bannerData?.image &&
          <CsHeader
            title={bannerData?.title}
            src={`${UrlHelper.imgPath}${bannerData?.image}`}
          />
        }
        <Inquiry />
        <ContactInfo />
      </Box>
    </>
  );
};

export default ContactUs;
