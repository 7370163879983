// ** MUI Imports
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";

// ** Third Party Imports
import { useDispatch } from "react-redux";

// ** Custom Imports
import TextField from "@mui/material/TextField";

// ** Store Imports
import { setCountryCodeMenu } from "../../Store/ContactUsPage/Section";

// ** Helper Imports
import Colors from "../../Helper/Colors";

const CsAutoComplete = (props: any) => {
  // Props
  const { boxSx, sx, label, error, helperText, onOpen, onClose, placeholder, setSearch  } = props;

  // Hoooks
  const dispatch = useDispatch();

  return (
    <Box sx={boxSx}>
      <Autocomplete
        {...props}
        autoComplete="off"
        sx={{
          ...sx,
          "& .MuiChip-root": {
            color: Colors.white,
            bgcolor: Colors.green,
            "& > svg": {
              color: `${Colors.white} !important`,
            },
          },
          "& > div > label": {
            "&.Mui-focused": {
              color: Colors.green,
            },
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: error
              ? `${Colors.error} !important`
              : `${Colors.green} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "0.5rem",
            borderColor: `${error ? Colors.error : Colors.black} !important`,
          },
        }}
        renderInput={(params: any) => <TextField {...params} label={label} placeholder={placeholder} />}
        onOpen={() => {
          dispatch(setCountryCodeMenu(true));
          document.body.style.overflow = "hidden";

          if (onOpen) {
            onOpen();
          }
        }}
        onInputChange={(event, value) => {
          if (setSearch) {
            setSearch(value);
          }
        }}
        onClose={() => {
          dispatch(setCountryCodeMenu(false));
          document.body.style.overflow = "unset";

          if (onClose) {
            onClose();
          }
        }}
      />
      {error && (
        <FormHelperText sx={{ color: Colors.error, pl: 1 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CsAutoComplete;
