// ** React Imports
import { useEffect, useState } from "react";

// ** Thrid Party Imports
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import Navbar from "./navbar";
import Footer from "./footer";
import InqieryPopup from "../pages/InqieryPopup/index"

import { fetchLogoData, fetchSeoData } from "../Store/SEO/SEOAction";
import { logo_data, pages } from "../Store/SEO";
import moment from "moment";
import { inquiry_popup_open, setOpen } from "../Store/InquieryPopup";

const Default = (props: any) => {
  // Props
  const { children } = props;

  // Hooks
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const logoData = useSelector(logo_data);
  const showInquiryPopup = useSelector(inquiry_popup_open)
  const localtion = useLocation()
  const allPages = useSelector(pages)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    fetchSeoData(dispatch);
    fetchLogoData(dispatch);
  }, []);


  const handlePopupClose = (val?: boolean) => {
    const today = moment().startOf("day").format("YYYY-MM-DD");
    const Expire = "true";
    localStorage.setItem("today", today);
    localStorage.setItem("Expire", Expire);
    dispatch(setOpen(val))
  };

  useEffect(() => {
    const today = moment().startOf('day').format('YYYY-MM-DD');
    const storedToday = localStorage.getItem('today');
    const storedEmail = localStorage.getItem('email');
    const expire = localStorage.getItem('Expire');

    const showPopupAfterDuration = () => {
      const durationInSeconds = logoData?.quickInquiry?.duration;
      const durationInMilliseconds = durationInSeconds * 1000;

      setTimeout(() => {
        if (!allPages || allPages.length <= 0) {
          dispatch(setOpen(false))
        } else if (allPages &&
          allPages.length > 0 &&
          ![...allPages].some((page: any) =>
            (page.default_key === "Home" && page.url_slug === "/" && page.url_slug === window.location.pathname) ||
            (page.default_key !== "Home" && page.url_slug === window.location.pathname.replace('/', ''))
          )) {
          dispatch(setOpen(false))
        } else {
          dispatch(setOpen(true))
        }
      }, durationInMilliseconds);
    };

    const showPopupAfterExpireTime = () => {
      const durationInMinutes = logoData?.quickInquiry?.expired_time;
      const durationInExpireTime = durationInMinutes * 60 * 1000;

      setTimeout(() => {
        if (!allPages || allPages.length <= 0) {
          dispatch(setOpen(false))
        } else if (allPages &&
          allPages.length > 0 &&
          ![...allPages].some((page: any) =>
            (page.default_key === "Home" && page.url_slug === "/" && page.url_slug === window.location.pathname) ||
            (page.default_key !== "Home" && page.url_slug === window.location.pathname.replace('/', ''))
          )) {
          dispatch(setOpen(false))
        } else {
          dispatch(setOpen(true))
        }
      }, durationInExpireTime);
    };

    if (Object.keys(logoData).length > 0) {
      if (!storedToday || (storedToday !== today)) {
        if (!storedEmail) {
          showPopupAfterDuration();
        }
      } else if (expire === "true" && !storedEmail) {
        showPopupAfterExpireTime();
      }
    }

  }, [logoData, allPages, localtion.pathname])
  return (
    <>
      <Navbar />
      {children}
      <Footer />

      <InqieryPopup
        showInquiryPopup={showInquiryPopup}
        setShowInquiryPopup={handlePopupClose}
      />
    </>
  );
};

export default Default;
