// ** React Impports
import { useState } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { Fade, JackInTheBox, Zoom } from "react-awesome-reveal";
import { useDispatch } from "react-redux";

// ** Custom Imports
import CsTextField from "../../../../components/CsTextField";
import CsButton from "../../../../components/CsButton";
import CsImgBox from "../../../../components/CsImgBox";
import CsAutoComplete from "../../../../components/CsAutoComplete";
import CsMobileField from "../../../../components/CsMobileInput";

// ** Store Imports
import { setInquiryForm } from "../../../../Store/ContactUsPage/Inquiry/InquiryAction";
import { setMsg } from "../../../../Store/SEO";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

interface Inquiry {
  name: string;
  email: string;
  contact_number: string;
  company_name: string;
  reason_for_inquiry: string;
  message: string;
  country_code: number;
}

interface InitStdType {
  value: string;
  label: string;
  key: string;
}

const Inquiry = () => {

  const initialStd: InitStdType = {
    value: "91",
    label: "+91",
    key: "IN",
  }
  // State
  const [stdCode, setStdCode] = useState<InitStdType>(initialStd);
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const dispatch = useDispatch();

  const initialValues: Inquiry = {
    name: "",
    email: "",
    contact_number: "",
    company_name: "",
    reason_for_inquiry: "",
    message: "",
    country_code: 91,
  };

  const shema = yup.object({
    name: yup.string().required("Full name is required."),
    company_name: yup.string().required("Company name is required."),
    email: yup
      .string()
      .required("Email is required.")
      .email("Please enter valid email"),
    contact_number: yup.string().required("Contact number is required."),
    reason_for_inquiry: yup
      .string()
      .required("Please select reason for inquiry."),
    message: yup.string().required("Message is required."),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: shema,
    onSubmit: async (value: Inquiry) => {
      setLoading(true);
      const res = await setInquiryForm({
        ...value,
        company_name: value.company_name.trim(),
        reason_for_inquiry: value.reason_for_inquiry.trim(),
        message: value.message.trim(),
        name: value.name.trim(),
      });
      if (res?.status) {
        formik.resetForm();
        setStdCode(initialStd);
        dispatch(setMsg({ open: true, message: res?.message, isError: false }));
      } else {
        if (res?.message && res?.message?.length > 0) {
          res?.message?.map((item: any) => {
            formik.setFieldTouched(item?.field, true, false);
            formik.setFieldError(item?.field, item?.message);
          });
        }
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CsImgBox
          src={"/images/Pages/BlackPaperGrp3.png"}
          imgSx={{ width: "6rem", height: "auto" }}
          boxSx={{ top: "2rem", display: { xs: "none", sm: "block" } }}
        />
        <CsImgBox
          src={"/images/Pages/BlackPaperGrp3.png"}
          imgSx={{ width: "6rem", height: "auto" }}
          boxSx={{
            bottom: "0",
            right: "1rem",
            display: { xs: "none", sm: "block" },
          }}
        />
        <Box sx={{ width: "80%", m: "auto" }}>
          <Box sx={{ textAlign: "center", my: 2 }}>
            <JackInTheBox triggerOnce>
              <Typography
                sx={{
                  fontSize: { xs: "2rem", sm: "3rem" },
                  color: Colors.black,
                }}
              >
                Inquiry Form
              </Typography>
            </JackInTheBox>
          </Box>
          <Fade direction="up" triggerOnce>
            <Box
              sx={{
                border: `0.0625rem solid ${Colors.black}`,
                borderRadius: "1.8125rem",
                p: 4,
              }}
            >
              <Grid container sx={{ width: "100%", m: 0 }}>
                <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                  <Fade direction="left" triggerOnce>
                    <CsTextField
                      label={"Full Name"}
                      placeholder="Enter Full Name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                  <Fade direction="right" triggerOnce>
                    <CsTextField
                      label={"Email Address"}
                      placeholder="Enter Email Address"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                  <Fade direction="left" triggerOnce>
                    <CsMobileField
                      label="Contact Number"
                      fullWidth
                      id={"contact_number"}
                      name="contact_number"
                      placeholder={"Contact Number"}
                      type="text"
                      size="small"
                      value={formik.values.contact_number}
                      onChange={(e: any) => {
                        e.currentTarget.value = e.currentTarget.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 10);
                        formik.handleChange(e);
                      }}
                      error={
                        formik.touched.contact_number &&
                        Boolean(formik.errors.contact_number)
                      }
                      helperText={
                        formik.touched.contact_number &&
                        formik.errors.contact_number
                      }
                      stdcode={stdCode}
                      stdCodeChange={(val: {
                        value: string;
                        label: string;
                        country_code_initials: string;
                        key: string;
                      }) => {
                        formik.setFieldValue(
                          "country_code",
                          parseInt(val?.value)
                        );
                        setStdCode(val);
                      }}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                  <Fade direction="right" triggerOnce>
                    <CsTextField
                      label={"Comapny Name"}
                      placeholder="Enter Company Name"
                      name="company_name"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company_name &&
                        Boolean(formik.errors.company_name)
                      }
                      helperText={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} sx={{ pb: 3 }}>
                  <Fade direction="up" triggerOnce>
                    <CsAutoComplete
                      label={"Reason for inquiry"}
                      name="country"
                      options={["For Product"]}
                      value={formik.values.reason_for_inquiry}
                      onChange={(e: any, value: any) =>
                        formik.setFieldValue("reason_for_inquiry", value)
                      }
                      error={
                        formik.touched.reason_for_inquiry &&
                        Boolean(formik.errors.reason_for_inquiry)
                      }
                      helperText={
                        formik.touched.reason_for_inquiry &&
                        formik.errors.reason_for_inquiry
                      }
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} sx={{ pb: 3 }}>
                  <Fade direction="up" triggerOnce>
                    <CsTextField
                      label={"Message"}
                      placeholder="Enter Message"
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.message && Boolean(formik.errors.message)
                      }
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12}>
                  <Zoom triggerOnce>
                    <CsButton
                      label="Submit"
                      variant="contained"
                      sx={{ mb: 2, textTransform: "none" }}
                      onClick={() => formik.handleSubmit()}
                      loading={loading}
                    />
                  </Zoom>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Box>
      </Box>
    </>
  );
};

export default Inquiry;
