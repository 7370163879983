// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

// ** Thrid Party Imports
import Favicon from "react-favicon";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import AllRoutes from "../Routes";

// ** Store Imports
import { logo_data, message, setMsg } from "../Store/SEO";
import { fetchLogoData, fetchSeoData } from "../Store/SEO/SEOAction";
import { setAllCollapsed } from "../Store/HomePage/Journey";
import { setAllFold } from "../Store/HomePage/Flavors";

// ** Icon Imports
import CsIcon from "../components/Icon";

// ** Helper Imports
import Colors from "../Helper/Colors";
import useLenis from "../components/Lenis/lenis";
import AppUtils from "../Helper/AppUtils";
import useDisableKeys from "../components/CsKeyDisable";
import UrlHelper from "../Helper/Url";
import moment from "moment";

const AllPages = () => <AllRoutes />;

const LayoutWrapper = () => {
  // Hooks
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const msg = useSelector(message);
  const logoData = useSelector(logo_data);
  const { scrollToTop } = useLenis();


  useEffect(() => {
    dispatch(setAllCollapsed(true));
    dispatch(setAllFold(true));
    window.scrollTo(0, 0);
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    fetchSeoData(dispatch);
    fetchLogoData(dispatch);
  }, []);

  // use Disable Keys
  useDisableKeys();

  return (
    <>
      <Favicon
        url={
          logoData?.favicon
            ? `${UrlHelper?.imgPath}/${logoData?.favicon}`
            : `/images/icons/LogoWhite.svg`
        }
      />

      <Box
        onMouseDown={(event: any) => {
          if (event.button === 1) {
            event.preventDefault();
          }
        }}
      >
        <AllPages />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={msg?.open}
        onClose={() =>
          dispatch(
            setMsg({
              open: false,
              message: "",
              isError: msg?.isError,
            })
          )
        }
        action={
          <IconButton
            onClick={() =>
              dispatch(
                setMsg({
                  open: false,
                  message: "",
                  color: msg?.isError,
                })
              )
            }
          >
            <CsIcon icon={"gg:close-o"} color={Colors.white} />
          </IconButton>
        }
        autoHideDuration={2000}
        message={
          AppUtils.checkValue(msg?.message)
            ? msg?.message
            : "Something went wrong !"
        }
        sx={{
          "& .MuiPaper-root": {
            bgcolor: msg?.isError ? Colors.error : Colors.green,
            borderRadius: "0.5rem",
          },
          zIndex: 999999,
        }}
      />

    </>
  );
};

export default LayoutWrapper;
