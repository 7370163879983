// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import Select from "react-select";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles"

// ** Third Party Imports
import { useDispatch } from "react-redux";

// ** Custom Imports
import CsTextField from "../CsTextField";

// ** Store Imports
import { setCountryCodeMenu } from "../../Store/ContactUsPage/Section";

// Helper Files
import countryCodes from "../../Helper/CountryCodes";

// ** Styles Imports
import "./style.css";
import Colors from "../../Helper/Colors";
import { useMediaQuery } from "@mui/material";

const CsMobileField = (props: any) => {
  // State
  const [imgUrl, setImgUrl] = useState<any>("");

  // Hooks
  const dispatch = useDispatch();
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const colourStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        display: "flex",
        backgroundColor: isFocused || isSelected ? Colors.green : "transparent",
        color: isFocused || isSelected ? Colors.white : Colors.black,
      };
    },
    menuPortal: (base: any): any => ({
      ...base,
      zIndex: 9999,
      textAlign: "center",
      maxHeight: "10rem",
      boxShadow: "0 0.125rem 0.25rem 0.0625rem $appDarkGryClr !important",
      backgroundColor: "transparent",
      width: "6rem",
    }),
  };

  // change textfield events
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const spaceRegex = /^\s/;
    if (!spaceRegex.test(event.target.value)) {
      if (props?.onChange) {
        props.onChange(event);
      }
    }
  };

  // change std code events
  const handleClickCodeItem = (val: any) => {
    props.stdCodeChange(val);
  };

  useEffect(() => {
    const newArr = countryCodes.find((val) => val.key === props.stdcode.key);
    setImgUrl(newArr?.image);
    props.stdcode.image = newArr?.image;
  }, [props.stdcode]);

  return (
    <>
      <CsTextField
        sx={props.sx}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        label={props.label}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={handleChange}
        autoComplete="off"
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <Box
                sx={{
                  backgroundColor: "inherit",
                  "& >div": {
                    "& >div": {
                      width: { xs: "5rem", sm: "8.25rem" },
                      border: "none",
                      boxShadow: "none !important",
                      backgroundColor: "inherit",
                      "& >div": {
                        "& >div": {
                          display: "flex",
                          color: "inherit",
                          p: {xs: 0, sm: 0.5}, 
                          "& >input": {
                            color: "inherit",
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <Select
                  isDisabled={props?.disabled}
                  menuPortalTarget={document.body}
                  id={props?.extraid ? props?.extraid : "cus-mob-std-select"}
                  placeholder=""
                  value={props.stdcode}
                  formatOptionLabel={(option) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={option?.image ? option?.image : imgUrl}
                        style={{ width: sm ? "0.9rem" : "1.5rem", height: sm ? "0.9rem" : "1.5rem" }}
                        alt="Country Flags"
                      />
                      <span>{option?.label}</span>
                    </Box>
                  )}
                  onChange={(e) => handleClickCodeItem(e)}
                  onMenuOpen={() => {
                    dispatch(setCountryCodeMenu(true));
                    document.body.style.overflow = "hidden";
                  }}
                  onMenuClose={() => {
                    dispatch(setCountryCodeMenu(false));
                    document.body.style.overflow = "unset";
                  }}
                  options={countryCodes}
                  styles={colourStyles}
                  maxMenuHeight={160}
                />
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default CsMobileField;
