// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import DeeperAnalysis from "./Components/DeeperAnalysis";
import AllProduct from "./Components/allProduct";

// ** Store Imports
import { product_banner_data } from "../../Store/Product/Banner";
import { fetchProductBanner } from "../../Store/Product/Banner/BannerAction";
import { seo_data } from "../../Store/SEO";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";
import UrlHelper from "../../Helper/Url";

const AllProducts = () => {
  // Hooks
  const data: any = useSelector(product_banner_data);
  const seoData = useSelector(seo_data);
  const dispatch = useDispatch();

  const userIp = async () => {
    const res = await AppUtils.getIp();
    return res;
  };

  useEffect(() => {
    AppUtils.setVisitorData(
      userIp(),
      "all_products",
      "All Products",
      "ALL_PRODUCTS"
    );
    AppUtils.setDailyVisitor({ pageKey: 'all_product' });
  }, []);

  useEffect(() => {
    if (Object.keys(data).length <= 0) {
      fetchProductBanner(dispatch);
    }
  }, [data]);

  return (
    <>
      {Array.isArray(seoData) &&
        seoData.length > 0 &&
        seoData.map(
          (item: any) =>
            item?.default_key === "AllProducts" && (
              <Helmet>
                <meta name="description" content={item?.meta_description} />
                <title>{item?.page_title}</title>
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <meta name="keywords" content={item?.meta_keywords} />
                )}
                {AppUtils.checkValue(item?.meta_keywords) && (
                  <script type="application/ld+json">
                    {item?.meta_schema}
                  </script>
                )}
              </Helmet>
            )
        )}
      <Box sx={{ overflowX: "hidden" }}>
        {
          data && data?.image && data?.title &&
          <CsHeader
            title={data?.title}
            src={`${UrlHelper.imgPath}${data?.image}`}
          />
        }
        <AllProduct />
        <DeeperAnalysis />
      </Box>
    </>
  );
};

export default AllProducts;
