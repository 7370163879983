// ** React Imports
import { useCallback, useEffect, useRef, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// ** Third Party Imports
import { Fade, JackInTheBox } from "react-awesome-reveal";
import { SVGMap } from "react-svg-map";
import World from "@svg-maps/world";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

// ** Custom Imports
import CustomDot from "./CustomDot";
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { is_show, setIsShow } from "../../../../Store/SEO";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Styles Imports
import "react-svg-map/lib/index.css";
import "../../../../index.css";

// ** Hooks Imports
import { useIsInViewport } from "../hooks/useWindowSize";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

const MarketSpecific = () => {
  // State
  const [activeStep, setActiveStep] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [openIndex, setOpenIndex] = useState(null)

  // Hooks
  const isShow = useSelector(is_show);
  const dispatch = useDispatch();
  const theme = useTheme()
  const mapSecRef = useRef(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isInViewport = useIsInViewport(mapSecRef);

  const earthData = [
    {
      country: "US",
      countryCode: "us",
      title: "Discover the Authentic Flavors of India",
      sub_title:
        "Elevate Your Culinary Creations with Premium Indian Spices, Spice Blends, Seasonings",
      description: `<p>
      The United States, a melting pot of global cuisines, has always
      embraced the vibrant and diverse flavors of India. From the bustling
      streets of New York City to the scenic landscapes of California,
      Indian spices have found a home in American kitchens, celebrated for
      their ability to transform simple ingredients into extraordinary
      dishes.
    </p>
    <p style="margin: 16px 0;">
      <b>Authenticity in Every Jar</b>
    </p>
    <p>
      Our collection of Indian spices brings the authentic tastes of India
      right to your doorstep. Experience the true flavors of Indian
      cuisine with our range of spices whole and ground, traditional
      Indian Spice blends along with the best custom spice mixes,
      including the rich and smoky Paprika, the warm and aromatic
      Turmeric, and the robust and earthy Cumin. Sourced directly from the
      fertile lands of India, our spices go through rigorous quality
      checks to ensure that you receive only the best.
    </p>
    <p style="margin: 16px 0;">
      <b>
        A Journey of Flavors (we can show that spices are used in all
        cuisines not just Indian cuisine. For American people, we can also
        add spice mixes for Burgers, Wraps, and so on)
      </b>
    </p>
    <p>
      Indian cuisine is not just about the heat; it's about a harmony of
      flavors that dance on your palate. With our spices, embark on a
      culinary journey that takes you through the aromatic biryanis of
      Hyderabad, the spicy street foods of Mumbai, and the rich curries of
      Punjab. Whether you're a professional chef looking to add
      authenticity to your dishes or a home cook experimenting with new
      flavors, our spices are your companions on this flavorful
      expedition.
    </p>
    <p style="margin: 16px 0;">
      <b>Health Meets Taste</b>
    </p>
    <p>
      In addition to their unmatched flavors, Indian spices are renowned
      for their health benefits. Turmeric's anti-inflammatory properties,
      Cinnamon's blood sugar regulation, and Ginger's digestive aid are
      just the tip of the iceberg. Incorporate our spices into your diet
      and enjoy the perfect blend of taste and wellness.
    </p>
    <p style="margin: 16px 0;">
      <b>Sustainability and Ethics</b>
    </p>
    <p>
      We believe in sustainability and ethical sourcing. Our spices are
      not just products; they are a testament to our commitment to the
      environment and the farmers who cultivate them. By choosing our
      spices, you're supporting sustainable agricultural practices and
      contributing to the well-being of farming communities in India.
    </p>
    <p style="margin: 16px 0;">
      <b>Your Culinary Partner</b>
    </p>
    <p>
      At Vora Spices, we're more than just suppliers (processors); we're
      your partners in culinary creativity. Our team is dedicated to
      providing you with not only the finest Indian spices but also
      recipes, tips, and insights to inspire your cooking. Let's make
      every meal a celebration of taste and tradition.
    </p>
  `,
    },
    {
      country: "UK",
      countryCode: "gb",
      title: "Infuse Your Dishes with the Spirit of India",
      sub_title: "Bringing the Vibrant Tastes of India to the UK",
      description: `
  <p>
    The UK's culinary scene is a fascinating blend of traditional British dishes and a growing appetite for diverse, global flavors. Indian cuisine, with its complex spice blends and rich flavors, has long been a part of this landscape, evolving from the beloved curry houses to sophisticated fusion gastronomy. Our collection of Indian spices is here to inspire your culinary journey, offering authenticity and quality to both traditional and innovative creations.
  </p>
  <p style="margin: 16px 0;">
    <b>
      A Pinch of History, A Dash of Innovation (is this para needed)
    </b>
  </p>
  <p>
    Britain's love affair with Indian cuisine goes back centuries, weaving itself into the fabric of British culinary traditions. Our spices pay homage to this shared history while inviting chefs and food enthusiasts to explore new horizons. From the classic flavors of Garam Masala and Turmeric to the exotic notes of Cardamom and Saffron, our range is a tribute to India's culinary legacy and a toolkit for culinary innovation.
  </p>
  <p style="margin: 16px 0;">
    <b>Sustainable Flavors, Ethical Choices</b>
  </p>
  <p>
    In today's conscious market, British consumers and businesses are not just looking for quality; they're seeking ethical and sustainable choices. Our spices are sourced directly from Indian farmers practicing sustainable agriculture, ensuring that with every purchase, you're supporting fair trade and eco-friendly practices. It's about making choices that taste good and feel good.
  </p>
  <p style="margin: 16px 0;">
    <b>For the Love of Curry and Beyond</b>
  </p>
  <p>
    While the UK's love for curry is well-known, Indian spices offer a world of flavors beyond the curry pot. Our content includes recipes and tips to incorporate these spices into everyday cooking, from breakfast scrambles to tea-time treats, showcasing the versatility of Indian spices in enhancing the familiar and exploring the new.
  </p>
  <p style="margin: 16px 0;">
    <b>Health Meets Heritage</b>
  </p>
  <p>
    The health benefits of Indian spices are as celebrated as their flavors. Turmeric's anti-inflammatory properties, Cinnamon's antioxidant power, and Fenugreek's ability to balance blood sugar are just a few examples. We blend tradition with science, offering spices that not only elevate your dishes but also contribute to a healthy lifestyle.
  </p>
  <p style="margin: 16px 0;">
    <b>Your Culinary Companion</b>
  </p>
  <p>
    At Vora Spices, we see ourselves as more than just suppliers (processors); we are culinary companions on your journey to explore and embrace the flavors of India. With our spices, we invite you to create dishes that tell stories, celebrate cultures, and bring people together.
  </p>
`,
    },
    {
      country: "Europe",
      countryCode: "pl",
      title: "A Symphony of Flavors",
      sub_title: "Elevate European Cuisines with Authentic Indian Spices",
      description: `
      <p>
        Europe's culinary scene is a mosaic of flavors, deeply rooted in history yet always evolving. From the rustic kitchens of the Mediterranean to the avant-garde restaurants of Scandinavia, each region offers a unique taste experience. Our collection of Indian spices introduces a new dimension to this rich tapestry, offering chefs and food enthusiasts the ingredients to innovate while remaining true to their culinary heritage.
      </p>
      <p style="margin: 16px 0;">
        <b>
        Tradition Meets Innovation
        </b>
      </p>
      <p>
        In Europe, where culinary traditions are cherished, Indian spices provide an exciting palette for innovation. Imagine transforming a classic French ratatouille with a hint of ( Indian Hot Spice Mix) Garam Masala, or adding depth to an Italian risotto with finely ground Cardamom. Our spices are a bridge between the old and the new, enabling you to create dishes that are both familiar and surprising.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability and Purity</b>
      </p>
      <p>
       With Europe leading the global charge towards sustainability and organic food production, our spices fit right in. Sourced from sustainable farms in India (where integrated pest management systems are followed and farmers are being trained to adopt pesticide free farming, our products not only bring authenticity to your dishes but also align with your ethical and environmental values. We offer a range of organic, non-GMO spices that support health and well-being, without compromising on flavor.
      </p>
      <p style="margin: 16px 0;">
        <b>The Art of Blending</b>
      </p>
      <p>
        Blending spices is an art, and with our products, you have the perfect palette. Our range includes both single-origin spices and bespoke blends, crafted to bring out the best in European ingredients. Whether it’s adding warmth to a Spanish paella with our Smoked Paprika or enriching a German Sauerbraten with our robust Clove, our spices are designed to complement and elevate.
      </p>
      <p style="margin: 16px 0;">
        <b>Flavors Without Borders</b>
      </p>
      <p>
        Europe’s culinary diversity is its strength, and our Indian spices are meant to celebrate this. We encourage chefs and home cooks alike to explore the boundless possibilities that our spices offer. From the aromatic streets of India to the bustling markets of Europe, our spices are a testament to the power of flavor to connect cultures and people.
      </p>
      <p style="margin: 16px 0;">
        <b>A Partnership in Taste</b>
      </p>
      <p>
        At Vora Spices, we don’t just supply spices; we offer a partnership in creating unforgettable culinary experiences. With our expertise and your passion, we can bring the vibrant flavors of India to Europe’s dining tables, creating dishes that are a feast for the senses.
      </p>
    `,
    },
    {
      country: "UAE",
      countryCode: "ae",
      title: "Spice Routes Reimagined",
      sub_title: "Indulge in the Richness of Indian Spices",
      description: `
      <p>
        EuThe UAE stands at the crossroads of East and West, a melting pot of cultures with a storied history deeply intertwined with the spice trade. Today, this tradition of culinary excellence continues, with the UAE's kitchens embracing flavors from around the globe. Our collection of premium Indian spices is carefully selected to meet the discerning tastes of the UAE, bringing a touch of tradition and a splash of innovation to every dish.
      </p>
      <p style="margin: 16px 0;">
        <b>Luxury Meets Tradition</b>
      </p>
      <p>
        In the UAE, where luxury and tradition blend seamlessly, our spices offer an unparalleled culinary experience. From the royal flavors of Saffron to the robust warmth of Cumin, our spices are a testament to India's rich culinary heritage. Each spice is a promise of purity, sourced to add depth and authenticity to your luxurious culinary creations.
      </p>
      <p style="margin: 16px 0;">
        <b>A Culinary Adventure</b>
      </p>
      <p>
       The UAE's cosmopolitan palette is always in search of unique and exotic flavors. Our range invites chefs and food enthusiasts to embark on a culinary adventure, exploring the spices that have captivated imaginations for centuries. Whether it's elevating a traditional Emirati dish, a Middle eastern cuisine or crafting an innovative fusion cuisine, our spices are your companions on this flavorful journey.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability and Quality</b>
      </p>
      <p>
        Reflecting the UAE's commitment to sustainability and quality, our spices are sourced from farms that prioritize eco-friendly practices and the well-being of their communities. We share the UAE's vision of a sustainable future, offering spices that not only taste good but also do good.
      </p>
      <p style="margin: 16px 0;">
        <b>The Art of Hospitality</b>
      </p>
      <p>
        Hospitality is at the heart of UAE culture, with food playing a central role in bringing people together. Our spices are designed to enhance this tradition, adding warmth and flavor to every meal. From a simple family dinner to a grand banquet, our spices make every occasion special, celebrating the UAE's renowned hospitality.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Partner in Flavor</b>
      </p>
      <p>
        At Vora Spices, we pride ourselves on being more than just a supplier; we are your partner in bringing the vibrant flavors of India to the UAE. With our expertise and your culinary skills, we can create unforgettable dining experiences that resonate with the sophisticated tastes of the UAE.
      </p>
    `,
    },
    {
      country: "China",
      countryCode: "cn",
      title: "A New Chapter in the Spice Odyssey",
      sub_title: "Embark on a Culinary Journey with Indian Spices",
      description: `
      <p>
China's illustrious culinary history, marked by a profound appreciation for depth of flavor and regional diversity, finds a harmonious complement in the vibrant array of Indian spices from Vora Spices. Our collection is meticulously curated to resonate with the sophisticated palates of China, introducing an array of flavors that bridge traditional Chinese techniques with the bold and diverse essence of Indian spice heritage.
      </p>
      <p style="margin: 16px 0;">
        <b>Tradition Enhanced with Global Flavors</b>
      </p>
      <p>
        In China, where culinary tradition is an art form passed down through generations, our Indian spices offer a canvas for innovation. From the delicate balance of Sichuan peppercorns to the zest of Indian Turmeric, our spices are selected to inspire new creations while paying homage to China's rich culinary legacy.
      </p>
      <p style="margin: 16px 0;">
        <b>Culinary Innovation Meets Sustainability</b>
      </p>
      <p>
       Reflecting China's growing commitment to sustainability and health, Vora Spices ensures that each spice, from the lush gardens of India, is a testament to ethical sourcing and environmental stewardship. Our spices invite Chinese chefs and food enthusiasts to explore sustainable culinary adventures that do not compromise on taste or quality.
      </p>
      <p style="margin: 16px 0;">
        <b>The Art of Chinese Hospitality</b>
      </p>
      <p>
       Our spices are crafted to enhance the Chinese tradition of hospitality, where meals are a heartfelt expression of welcome and friendship. Whether it's a family gathering or a formal banquet, our spices add warmth and complexity, making every dish an occasion to remember.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Partner in Flavor Exploration</b>
      </p>
      <p>
        At Vora Spices, we are dedicated to being more than just a supplier; we are your partner in integrating the vibrant flavors of India into the heart of Chinese cuisine. Together, we can create unforgettable dining experiences that resonate with the sophisticated tastes of China.
      </p>
    `,
    },
    {
      country: "Germany",
      countryCode: "de",
      title: "Culinary Excellence Through Spices",
      sub_title: "Savor the Essence of Indian Tradition in Every Dish",
      description: `
      <p>
Germany's culinary landscape, known for its precision and depth, finds a perfect complement in the diverse array of Indian spices. Our selection is meticulously chosen to suit the refined palates of Germany, marrying the robust flavors of Indian cuisine with Germany's rich culinary traditions.
      </p>
      <p style="margin: 16px 0;">
        <b>Tradition Meets Quality</b>
      </p>
      <p>
        In Germany, where quality and tradition are paramount, our Indian spices bring authenticity and excellence to your culinary creations. From the aromatic depths of Cardamom to the rich warmth of Turmeric, each spice is a testament to India's heritage, ready to enhance the flavour in  traditional recipes or inspire new culinary innovations. Our Spice Mix for the Doner Kebab Industry is a hot selling product.
      </p>
      <p style="margin: 16px 0;">
        <b>A Journey of Flavors</b>
      </p>
      <p>
       German cuisine, celebrated for its hearty and comforting dishes, is poised for a transformation with our premium Indian spices. Embark on a flavor journey that transcends borders, bringing the vibrant, health-enhancing spices of India to the heart of German cooking.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability at the Core</b>
      </p>
      <p>
        Aligning with Germany's commitment to sustainability, our spices are sourced following eco-friendly practices, ensuring that our offerings not only elevate your dishes but also contribute to the well-being of the planet.
      </p>
      <p style="margin: 16px 0;">
        <b>Enhancing German Hospitality</b>
      </p>
      <p>
       Our spices are designed to enrich the German tradition of hospitality, where food is an expression of care and connection. Whether it's a festive family gathering or a gourmet dining experience, our spices add a touch of warmth and sophistication.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Culinary Partner</b>
      </p>
      <p>
       At Vora Spices, we are dedicated to bringing the authentic flavors of India to Germany, supporting chefs and food enthusiasts as they explore the rich tapestry of Indian spices. Together, we can create unforgettable experiences that bridge cultures through the universal language of food.
      </p>
    `,
    },
    {
      country: "Saudi Arabia",
      countryCode: "sa",
      title: "Tradition and Flavor in Harmony",
      sub_title: "Experience the Rich Traditions of Indian Spices",
      description: `
      <p>
Saudi Arabia's culinary heritage, known for its rich flavors and aromatic dishes, finds an exquisite complement in the diverse array of Indian spices. Our selection is thoughtfully curated to align with the refined tastes of Saudi Arabia, blending the profound flavors of Indian cuisine with the kingdom's cherished culinary traditions.
      </p>
      <p style="margin: 16px 0;">
        <b>Quality Meets Tradition</b>
      </p>
      <p>
        In Saudi Arabia, where culinary tradition is a reflection of the country's rich cultural heritage, our Indian spices offer authenticity and unmatched quality. From the fragrant Cardamom that elevates Arabic coffee to the Turmeric that enriches traditional stews, each spice is a celebration of India's and Saudi Arabia's shared love for deeply flavorful and aromatic dishes.
      </p>
      <p style="margin: 16px 0;">
        <b>A Culinary Journey</b>
      </p>
      <p>
       Saudi cuisine, celebrated for its generosity and hospitality, is poised for enrichment with our premium Indian spices. Embark on a gastronomic journey that brings the vibrant, health-enhancing spices of India into the heart of Saudi cooking, from classic Kabsa to aromatic Bukhari rice.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability at Our Heart</b>
      </p>
      <p>
        Echoing Saudi Arabia's growing emphasis on sustainability, our spices are ethically sourced, supporting both the planet and the people who cultivate them. We ensure that our offerings elevate your culinary creations while contributing positively to the global community.
      </p>
      <p style="margin: 16px 0;">
        <b>Enhancing Saudi Hospitality</b>
      </p>
      <p>
       Our spices are crafted to enhance the Saudi tradition of hospitality, where meals are a celebration of family and community. Whether for a daily family meal or a lavish feast, our spices introduce warmth and depth, making every gathering more memorable.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Culinary Partner</b>
      </p>
      <p>
       At Vora Spices, we are committed to bringing the authentic flavors of India to Saudi Arabia, aiding chefs and food enthusiasts in their exploration of Indian spices. Together, we aim to create unforgettable dining experiences that bridge cultures and celebrate the rich tapestry of flavors.
      </p>
    `,
    },
    {
      country: "Netherlands",
      countryCode: "nl",
      title: "A Fusion of Flavors: From India to the Dutch Table",
      sub_title:
        "Embrace the Authentic Essence of Indian Spices in the Netherlands",
      description: `
      <p>
The Netherlands, with its rich tradition of exploring global cuisines and a keen focus on sustainability, finds a perfect culinary partner in the authentic flavors of Indian spices. Our collection is thoughtfully curated to cater to the sophisticated palate of the Dutch, blending the timeless traditions of Indian cuisine with the innovative spirit of Dutch cooking.
      </p>
      <p style="margin: 16px 0;">
        <b>Tradition Meets Innovation</b>
      </p>
      <p>
        In the heart of the Netherlands, where innovation thrives alongside a deep respect for tradition, our Indian spices serve as a bridge between cultures. From the aromatic allure of Cardamom to the golden glow of Turmeric, each spice in our repertoire is selected for its ability to elevate Dutch culinary creations, offering a taste of India's rich heritage with every dish.
      </p>
      <p style="margin: 16px 0;">
        <b>A Culinary Voyage</b>
      </p>
      <p>
       Dutch cuisine, celebrated for its hearty comfort food and openness to international flavors, is poised for a transformation with our premium Indian spices. We invite you on a gastronomic journey that transcends geographical boundaries, infusing your dishes with the vibrant, life-enhancing flavors of India, from traditional stamppot to innovative fusion dishes.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability at Our Core</b>
      </p>
      <p>
        Aligning with the Netherlands' commitment to a sustainable future, our spices are sourced from farmers practicing eco-friendly agriculture. This dedication ensures that our offerings not only enrich your culinary endeavors but also support the well-being of our planet and its people.
      </p>
      <p style="margin: 16px 0;">
        <b>Enhancing Dutch Hospitality</b>
      </p>
      <p>
       Our spices are crafted to complement the Dutch tradition of gezelligheid, adding warmth and depth to meals shared with loved ones. Whether it's a cozy family dinner or a grand gathering, our spices bring people together, making every meal a special occasion.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Culinary Companion</b>
      </p>
      <p>
       At Vora Spices, we are dedicated to bringing the authentic flavors of India to the Dutch table, supporting chefs and food enthusiasts as they explore the rich tapestry of Indian spices. Together, we can create unforgettable dining experiences that celebrate the fusion of cultures through the universal language of food.
      </p>
    `,
    },
    {
      country: "Canada",
      countryCode: "ca",
      title: "A New Chapter in the Spice Odyssey",
      sub_title: "Embark on a Culinary Journey with Indian Spices",
      description: `
      <p>
Canada's diverse and inclusive culinary scene, celebrated for embracing global flavors, finds a perfect match in the rich palette of Indian spices from Vora Spices. We meticulously curate our collection to resonate with the eclectic tastes of Canada, blending the vibrant traditions of Indian spice heritage with the innovative spirit of Canadian cuisine.
      </p>
      <p style="margin: 16px 0;">
        <b>Tradition Meets Innovation in Canadian Kitchens</b>
      </p>
      <p>
        In Canada, where the culinary landscape is a mosaic of global influences, our Indian spices serve as a bridge between cultures. From the smoky depths of Garam Masala to the vibrant hues of Turmeric, our spices are chosen to inspire culinary creativity, enhancing both traditional dishes and modern fusions with the authentic essence of India.
      </p>
      <p style="margin: 16px 0;">
        <b>Sustainability at the Heart of Culinary Excellence</b>
      </p>
      <p>
       Reflecting Canada's commitment to sustainability and health, Vora Spices ensures that each spice, sourced from the verdant fields of India, upholds our dedication to ethical sourcing and environmental stewardship. Our collection invites Canadian chefs and food enthusiasts to embark on sustainable culinary adventures that marry exquisite taste with integrity.
      </p>
      <p style="margin: 16px 0;">
        <b>Enhancing the Canadian Tradition of Hospitality</b>
      </p>
      <p>
        Our spices are crafted to enrich the Canadian tradition of gathering, sharing, and celebrating through food. Whether it's a cozy family meal or a grand celebration, our spices add a layer of warmth and complexity, making every meal a memorable experience that honors the diverse fabric of Canadian culture.
      </p>
      <p style="margin: 16px 0;">
        <b>Your Partner in Culinary Exploration</b>
      </p>
      <p>
       At Vora Spices, we are more than just a supplier; we are your partner in bringing the vibrant flavors of India to Canadian tables. Together, we can create unforgettable dining experiences that resonate with the sophisticated and diverse tastes of Canada.
      </p>
    `,
    },
  ];

  useEffect(() => {
    const map = document.querySelector(".svg-map");
    const path = document.getElementById(earthData[activeStep]?.countryCode);
    if (path) {
      path.style.fill = Colors.green;
      path.classList.remove("blink");
      const icon = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      icon.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "href",
        "/path/to/icon.svg"
      );
      icon.setAttribute("width", "20");
      icon.setAttribute("height", "20");
      icon.setAttribute("x", "0");
      icon.setAttribute("y", "0");
      path.appendChild(icon);
    }

    const previous = earthData.filter((_, i) => i !== activeStep);
    previous.forEach((val) => {
      const previousPath = document.getElementById(val?.countryCode);
      if (previousPath) {
        previousPath.classList.add("blink");
        previousPath.style.fill = "#a1d99b";
        const oldIcon = previousPath.querySelector("image");
        if (oldIcon) {
          previousPath.removeChild(oldIcon);
        }
      }
    });

    const updateViewBox = () => {
      if (window.innerWidth < 580) {
        if (map) {
          map.setAttribute("viewBox", "0 0 1000 500");
        }
      } else {
        if (map) {
          map.setAttribute("viewBox", "0 0 1000 750");
        }
      }
    };
    updateViewBox();
    window.addEventListener("resize", updateViewBox);
    return () => {
      window.removeEventListener("resize", updateViewBox);
    };
  }, [activeStep]);

  const handleLocationClick = (event) => {
    const countryCode = event.target.id;
    const index = earthData.findIndex(
      (item) => item.countryCode === countryCode
    );

    if (index !== -1) {
      setPreviousIndex(index);
      setActiveStep(index);
    } else {
      setActiveStep(previousIndex);
    }
  };


  // const calculateHeightOfDesc = () => {
  //   if (!isShow) {
  //     const heights = [
  //       '10rem',   // activeStep 0
  //       isMobile ? '12rem' : '11rem',  // activeStep 1
  //       '11rem',   // activeStep 2
  //       isMobile ? '12rem' : '11rem',  // activeStep 3
  //       isMobile ? '12rem' : '11rem',  // activeStep 4
  //       isMobile ? '11rem' : '9rem',   // activeStep 5
  //       isMobile ? '9.2rem' : '9rem',  // activeStep 6
  //       isMobile ? '9.5rem' : '9.3rem',// activeStep 7
  //       '10rem'    // activeStep 8
  //     ];

  //     return heights[activeStep];
  //   }
  // }

  useEffect(() => {
    if (activeStep !== openIndex) {
      dispatch(setIsShow(false));
    } else {
      dispatch(setIsShow(openIndex !== null));
    }
  }, [openIndex, activeStep, dispatch]);


  useEffect(() => {
    if (!isInViewport) {
      const timeOut = setTimeout(() => dispatch(setIsShow(false)), 10)
      return () => clearTimeout(timeOut)
    }
  }, [isInViewport]);

  return (
    <Box ref={mapSecRef}>
      <CsImgBox
        src={"/images/Pages/Leaf1.png"}
        imgSx={{ width: "5rem", height: "auto" }}
        boxSx={{ position: "relative", pl: 4, top: "3rem" }}
      />
      <CsImgBox
        src={"/images/Pages/MaroonDot.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{ position: "relative", textAlign: "end" }}
      />
      <JackInTheBox triggerOnce>
        <Typography
          sx={{
            color: Colors.text,
            textAlign: "center",
            fontSize: { xs: "1.5rem", sm: "2rem" },
            fontWeight: 500,
            mb: 4,
            lineHeight: "unset",
          }}
        >
          Market Specific
        </Typography>
      </JackInTheBox>
      <Fade direction="up" triggerOnce>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            bgcolor: Colors.bgMarketSpecific,
            px: { xs: 4, md: 10 },
            py: 4,
            flexDirection: "column",
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation="horizontal"
            sx={{
              mt: { xs: 2, md: 0 },
              color: Colors.white,
              display: { xs: "grid", md: "flex" },
              // overflowX: { xs: "scroll", md: "unset" },
              gridTemplateColumns: { xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)", md: "unset" },
              flexWrap: "wrap",
              width: "100%",
              scrollbarWidth: "none",
              "& .MuiStepLabel-label": {
                width: "max-content",
              },
              "& .MuiStepConnector-line": {
                width: { xs: "0rem", md: "100%" },
              },
              "& .MuiStepConnector-root": {
                display: { xs: "none", md: "block" }
              }
            }}
          >
            {Array.isArray(earthData) &&
              earthData?.length > 0 &&
              earthData?.map((item, index) => (
                <Step sx={{ color: `${Colors.white} !important` }} key={index}>
                  <StepLabel
                    StepIconComponent={CustomDot}
                    sx={{
                      "& > span > span": {
                        color: `${Colors.white} !important`,
                        fontSize: "1.2rem",
                      },
                      py: 0,
                    }}
                    onClick={() => {
                      setActiveStep(index);
                      const path = document.getElementById(item?.countryCode);
                      const previous = earthData.filter(
                        (val, i) => i !== index
                      );
                      previous.forEach((val) => {
                        const previousPath = document.getElementById(
                          val?.countryCode
                        );
                        if (previousPath) {
                          previousPath.classList.add("blink");
                          previousPath.style.fill = "#a1d99b";
                        }
                      });

                      if (path) {
                        path.classList.remove("blink");
                        path.style.fill = Colors.green;
                      }
                    }}
                  >
                    {item?.country}
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
          <Grid
            container
            sx={{
              width: "100%",
              mt: 2,
              flexDirection: { xs: "column-reverse", md: "unset" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={8}>
              <SVGMap
                map={World}
                className="svg-map"
                onLocationClick={handleLocationClick}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Fade direction="up" triggerOnce>
                <Box sx={{ px: 2 }}>
                  <Typography variant="h5" sx={{ color: Colors.white, mb: 2 }}>
                    {earthData[activeStep].title}
                  </Typography>
                  <Typography sx={{ color: Colors.white, mb: 2 }}>
                    {earthData[activeStep].sub_title}
                  </Typography>
                  <Box
                    sx={[
                      {
                        "& > p": { color: Colors.white, fontSize: "0.9rem" },
                        height: isShow && openIndex === activeStep ? "20rem" : '9rem',
                        // height: isShow ? "20rem" : "10rem",
                        overflow: isShow && openIndex === activeStep ? "auto" : "hidden",
                      },
                    ]}
                    dangerouslySetInnerHTML={{
                      __html: earthData[activeStep]?.description,
                    }}
                  />
                  <Box sx={{
                    textAlign: "center",
                    boxShadow: isShow && openIndex === activeStep ? "none" : "-0px -25px 10px 10px #23252A",
                    zIndex: 99,
                    position: 'relative',
                  }}>
                    <IconButton
                      sx={{ bgcolor: `${Colors.green} !important`, mt: 1 }}
                      onClick={() => openIndex === 0 || openIndex ? setOpenIndex(null) : setOpenIndex(activeStep)}
                    >
                      <CsIcon
                        style={{
                          transition: "all 0.2s ease-in-out",
                          transform: isShow && openIndex === activeStep ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                        icon={"iconoir:page-down"}
                        color={Colors.white}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
};

export default MarketSpecific;
