// ** MUI Imports
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// ** Third Party Imports
import { Fade, JackInTheBox } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { custom_solution_data } from "../../../../Store/CustomSolution/Section";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const Logistical = () => {
  // Hooks
  const data = useSelector(custom_solution_data);

  return (
    <>
      <Box sx={{ width: "80%", m: "0 auto 48px auto" }}>
        <JackInTheBox>
          <Typography
            sx={{ fontSize: { xs: "1.5rem", sm: "2rem", color: Colors.black } }}
          >
            {data?.seventh_section_title}
          </Typography>
        </JackInTheBox>
        <Grid
          container
          columnSpacing={8}
          rowSpacing={4}
          sx={{ justifyContent: "center", my: { xs: 2, sm: 4 } }}
        >
          {Array.isArray(data?.seventh_section) &&
            data?.seventh_section?.length > 0 &&
            data?.seventh_section?.map((item: any, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={5} md={4}>
                  <Fade triggerOnce direction="up">
                    <Box
                      sx={{
                        position: "relative",
                        border:
                          index === 1
                            ? "none"
                            : `0.0625rem solid ${Colors.border}`,
                        p: 4,
                        bgcolor: index === 1 ? "#FF8A20" : "#fff",
                      }}
                    >
                      <Box
                        component={"img"}
                        src="/images/icons/Fold.svg"
                        alt="Paper Fold"
                        sx={{
                          position: "absolute",
                          top: {
                            xs: index === 1 ? "-1px" : "-2px",
                            sm: index === 1 ? "0px" : "-1px",
                          },
                          right: { xs: "-2px", sm: index === 1 ? 0 : "-1px" },
                          width: "auto",
                          height: "5rem",
                        }}
                      />
                      <Box
                        sx={{
                          borderRadius: "100%",
                          p: 2,
                          border: `0.125rem solid ${
                            index === 1 ? Colors.white : Colors.border
                          }`,
                          width: "max-content",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={`${UrlHelper.imgPath}${item?.image}`}
                          alt={
                            item?.image_ALT_text
                              ? item?.image_ALT_text
                              : item?.title
                          }
                          sx={{ width: "3.125rem", height: "3.125rem" }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          color: index === 1 ? Colors.white : Colors.descTxt,
                          my: 2,
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Tooltip
                        title={item?.description}
                        arrow
                        componentsProps={{
                          arrow: { sx: { color: Colors.green } },
                          tooltip: {
                            sx: { bgcolor: Colors.green, fontSize: "0.8rem" },
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            color: index === 1 ? Colors.white : Colors.descTxt,
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Fade>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </>
  );
};

export default Logistical;
