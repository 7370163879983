// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Custom Imports
import CsButton from "../../../../components/CsButton";
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { signature_data } from "../../../../Store/HomePage/Signature";
import { fetchSignatureData } from "../../../../Store/HomePage/Signature/SignatureAction";
import { pages } from "../../../../Store/SEO";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";

const Signature = () => {
  // Hooks
  const data = useSelector(signature_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allPages = useSelector(pages);

  useEffect(() => {
    fetchSignatureData(dispatch);
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <CsImgBox
        src={"/images/Pages/BlackPaperGrp.png"}
        imgSx={{ width: { xs: "2rem", sm: "4rem" }, height: "auto" }}
        boxSx={{
          width: "fit-content",
          right: 0,
        }}
      />
      {/* <Container maxWidth="lg" sx={{ my: 4 }}></Container> */}
      <Box sx={{ textAlign: "center", width: "100%" }}>
        <Typography
          sx={{
            color: Colors.text,
            my: "20px",
            fontSize: { xs: "2rem", sm: "3rem" },
          }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {data?.title}
          </Fade>
        </Typography>

        <Fade direction="up" triggerOnce>
          <Typography
            sx={{
              color: Colors.descTxt,
              px: { xs: 2, sm: 8 },
              fontSize: "0.9rem",
            }}
          >
            {data?.description}
          </Typography>
        </Fade>
        <Zoom triggerOnce>
          <CsButton
            label="Explore Now"
            variant="outlined"
            sx={{ my: 4 }}
            onClick={() =>
              navigate(
                `/${allPages?.find(
                  (item: any) => item?.default_key === "AllProducts"
                )?.url_slug
                }`
              )
            }
          />
        </Zoom>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Fade direction="up" triggerOnce>
          <Box
            className="signature_img"
            component={"img"}
            src={data?.image && `${UrlHelper.imgPath}${data?.image}`}
            alt={
              data?.thumbnail_image_ALT_text
                ? data?.thumbnail_image_ALT_text
                : "Signature"
            }
            sx={{
              width: { xs: "100%", md: "auto" },
              height: { sm: "31.25rem" },
            }}
          />
        </Fade>
      </Box>
    </Box>
  );
};

export default Signature;
