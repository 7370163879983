// ** React Imports
import { useEffect, useState } from "react";

// ** Third Party Imports
import Lenis from "@studio-freight/lenis";
import { useSelector } from "react-redux";

// ** Store Imports
import { all_collapsed } from "../../Store/HomePage/Journey";
import { all_fold } from "../../Store/HomePage/Flavors";
import { is_open, is_show } from "../../Store/SEO";
import { is_country_code_menu } from "../../Store/ContactUsPage/Section";
import { inquiry_popup_open } from "../../Store/InquieryPopup";

const useLenis = () => {
  // State
  const [lenis, setLenis] = useState<Lenis | null>(null);

  // Hooks
  const allCollapsed = useSelector(all_collapsed);
  const allFold = useSelector(all_fold);
  const isOpen = useSelector(is_open);
  const isCountryMenuOpen = useSelector(is_country_code_menu);
  const isShow = useSelector(is_show);
  const showInquiryPopup = useSelector(inquiry_popup_open)


  useEffect(() => {
    if (!isOpen && !lenis) {
      const newLenis = new Lenis({
        duration: 1.2,
        easing: (t: number) => Math.min(1, 1.001 - 2 ** (-10 * t)),
        orientation: "vertical",
        gestureOrientation: "both",
        smoothWheel: true,
        wheelMultiplier: 0.3,
        touchMultiplier: 0.3,
        infinite: false,
      });
      setLenis(newLenis);
    } else if (isOpen && lenis) {
      lenis.destroy();
      setLenis(null);
    }
  }, [isOpen, lenis]);


  useEffect(() => {
    if (lenis) {
      if (showInquiryPopup) {
        lenis.destroy();
      } else {
        const newLenis = new Lenis({
          duration: 1.2,
          easing: (t: number) => Math.min(1, 1.001 - 2 ** (-10 * t)),
          orientation: "vertical",
          gestureOrientation: "both",
          smoothWheel: true,
          wheelMultiplier: 0.3,
          touchMultiplier: 0.3,
          infinite: false,
        });
        setLenis(newLenis);
      }
    } else return;
  }, [showInquiryPopup]);

  useEffect(() => {
    if (lenis) {
      if (isCountryMenuOpen) {
        lenis.destroy();
      } else {
        const newLenis = new Lenis({
          duration: 1.2,
          easing: (t: number) => Math.min(1, 1.001 - 2 ** (-10 * t)),
          orientation: "vertical",
          gestureOrientation: "both",
          smoothWheel: true,
          wheelMultiplier: 0.3,
          touchMultiplier: 0.3,
          infinite: false,
        });
        setLenis(newLenis);
      }
    } else return;
  }, [isCountryMenuOpen]);

  useEffect(() => {
    if (lenis) {
      if (isShow) {
        lenis.destroy();
      } else {
        const newLenis = new Lenis({
          duration: 1.2,
          easing: (t: number) => Math.min(1, 1.001 - 2 ** (-10 * t)),
          orientation: "vertical",
          gestureOrientation: "both",
          smoothWheel: true,
          wheelMultiplier: 0.3,
          touchMultiplier: 0.3,
          infinite: false,
        });
        setLenis(newLenis);
      }
    } else return;
  }, [isShow]);

  useEffect(() => {
    if (allCollapsed && lenis) {
      lenis.start();
    } else if (lenis) {
      lenis.stop();
    }
  }, [allCollapsed, lenis]);

  useEffect(() => {
    if (allFold && lenis) {
      lenis.start();
    } else if (lenis) {
      lenis.stop();
    }
  }, [allFold, lenis]);

  const raf = (time: number) => {
    lenis?.raf(time);
    requestAnimationFrame(raf);
  };

  useEffect(() => {
    if (lenis) {
      requestAnimationFrame(raf);
    }
  }, [lenis]);

  const scrollToTop = () => {
    if (lenis) {
      lenis.scrollTo(0, { immediate: true });
    }
  };

  return { lenis, scrollToTop };
};

export default useLenis;
