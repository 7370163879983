// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const journeyState: { journeyData: any; allCollapsed: boolean } = {
  journeyData: {},
  allCollapsed: true,
};

const JourneySlice = createSlice({
  name: "journey",
  initialState: journeyState,
  reducers: {
    getJourneyData(state: any, action: any) {
      state.journeyData = action.payload;
    },
    setAllCollapsed(state: any, action: any) {
      state.allCollapsed = action.payload;
    },
  },
});

export const { getJourneyData, setLoading, setAllCollapsed }: any =
  JourneySlice.actions;

export default JourneySlice.reducer;

export const journey_data = (state: any) => state.home.journey.journeyData;

export const all_collapsed = (state: any) => state.home.journey.allCollapsed;
