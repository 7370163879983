// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import {
  journey_data,
  setAllCollapsed,
} from "../../../../Store/HomePage/Journey";
import { fetchJourneyData } from "../../../../Store/HomePage/Journey/JourneyAction";

// ** Styles Imports
import "./style.css";
import "swiper/css";
import "swiper/css/navigation";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";
import useWindowSize from "../hooks/useWindowSize";

const Journy = () => {
  // Hooks
  const data = useSelector(journey_data);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  useEffect(() => {
    fetchJourneyData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const cards = Array.from(
      document.querySelectorAll(".journy-card-item_section")
    ).map((card) => card as HTMLElement);
    let currentIndex = 0;
    const cardSection = document.querySelector(
      ".journy-card-section_section"
    ) as HTMLElement;
    let cardSectionFullyInView = false;
    let isFliping = false;

    const isElementFullyInViewport = (el: HTMLElement): boolean => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const adjustCardWidth = (direction: string) => {
      if (!cardSectionFullyInView || isFliping) return;

      isFliping = true;
      let currentWidth = parseInt(
        getComputedStyle(cards[currentIndex]).width,
        10
      );
      if (direction === "down") {
        if (currentWidth > 70) {
          cards[currentIndex].style.width = `${Math.max(
            70,
            currentWidth - 375
          )}px`;
          if (Math.max(70, currentWidth - 375) === 70) {
            cards[currentIndex].classList.add("vertical");
          }
        } else if (currentIndex < cards.length - 1) {
          currentIndex++;
        } else {
          cardSectionFullyInView = false;
        }
        dispatch(
          setAllCollapsed(
            cards.every((card) => card.classList.contains("vertical"))
          )
        );
      }
      if (direction === "up") {
        if (currentIndex === 0 && currentWidth > 208) {
          cards[currentIndex].style.width = `${Math.min(
            375,
            currentWidth + 375
          )}px`;
          cards[currentIndex].classList.remove("vertical");
          cardSectionFullyInView = false;
        }
        if (currentWidth < 208) {
          cards[currentIndex].style.width = `${Math.min(
            375,
            currentWidth + 375
          )}px`;
          // setTimeout(() => {
          cards[currentIndex].classList.remove("vertical");
          // }, 200);
        } else if (currentIndex > 0) {
          currentIndex--;
        } else {
          cardSectionFullyInView = false;
        }
        dispatch(
          setAllCollapsed(
            cards.every((card) => !card.classList.contains("vertical"))
          )
        );
      }
      setTimeout(() => (isFliping = false), 100);
    };

    const scrollListener = () => {
      if (windowSize?.width > 570) {
        cardSectionFullyInView = isElementFullyInViewport(cardSection);
      }
    };

    const wheelListener = (e: WheelEvent) => {
      if (cardSectionFullyInView) {
        e.preventDefault();
        adjustCardWidth(e.deltaY > 0 ? "down" : "up");
      }
    };

    const keydownListener = (e: KeyboardEvent) => {
      if (cardSectionFullyInView) {
        if (e.key === "ArrowUp") {
          e.preventDefault();
          adjustCardWidth("up");
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          adjustCardWidth("down");
        }
      }
    };

    const touchStartListener = (e: TouchEvent) => {
      if (cardSectionFullyInView) {
        e.preventDefault();
        const touch = e.touches[0];
        const startY = touch.clientY;

        const touchMoveListener = (moveEvent: TouchEvent) => {
          const moveTouch = moveEvent.touches[0];
          const moveY = moveTouch.clientY;
          if (startY - moveY > 5) {
            adjustCardWidth("down");
          } else if (moveY - startY > 5) {
            adjustCardWidth("up");
          }
          window.removeEventListener("touchmove", touchMoveListener);
        };

        window.addEventListener("touchmove", touchMoveListener, {
          passive: true,
        });
      }
    };

    window.addEventListener("scroll", scrollListener, { passive: true });
    window.addEventListener("wheel", wheelListener, { passive: false });
    window.addEventListener("keydown", keydownListener);
    window.addEventListener("touchstart", touchStartListener, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", scrollListener);
      window.removeEventListener("wheel", wheelListener);
      window.removeEventListener("keydown", keydownListener);
      window.removeEventListener("touchstart", touchStartListener);
    };
  }, [data, windowSize?.width]);

  return (
    <Box className="vertical-cards-scroller-sec">
      <CsImgBox
        src={"/images/Pages/BlackPaper.png"}
        imgSx={{ width: "3rem", height: "auto" }}
        boxSx={{
          position: "relative",
          width: "fit-content",
          pl: { sm: 4 },
          display: { xs: "none", sm: "block" },
          mt: 4,
        }}
      />
      <Box sx={{ pl: { xs: 2, sm: 8 } }} className="main-wrapper_section">
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            my: 4,
            pr: { xs: 4, sm: 0 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Fade direction="left" triggerOnce>
              <Typography
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  fontWeight: 500,
                  color: Colors.text,
                  // width: { xs: "auto", sm: "32rem" },
                }}
              >
                {data?.section_title}
              </Typography>
            </Fade>
            <CsImgBox
              src={"/images/Pages/BlurGreenDot.png"}
              imgSx={{ width: "1.5rem", height: "auto" }}
              boxSx={{
                position: "relative",
                width: "fit-content",
                pr: { sm: 2 },
                display: { xs: "none", sm: "block" },
              }}
            />
          </Box>
          <CsImgBox
            src={"/images/Pages/MaroonDot.png"}
            imgSx={{ width: "3rem", height: "auto" }}
            boxSx={{
              position: "relative",
              width: "fit-content",
              pr: { sm: 2 },
              display: { xs: "none", sm: "block" },
            }}
          />
        </Box>
        {windowSize?.width > 570 ? (
          <Box
            className="journy-card-section_section"
            sx={{
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              p: 0,
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              {Array.isArray(data?.sub_sections) &&
                data?.sub_sections?.length > 0 &&
                data?.sub_sections?.map((item: any, index: number) => (
                  <Fade direction="right" triggerOnce key={index}>
                    <Box
                      className="journy-card-item_section"
                      sx={{
                        position: "relative",
                        height: "29.375rem",
                        overflow: "hidden",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "0.625rem",
                        border: `0.0625rem solid ${Colors.border}`,
                        borderRight:
                          index === data?.sub_sections.length - 1
                            ? `0.0625rem solid ${Colors.border}`
                            : "none",
                        transition: "0.5s ease",
                        backgroundImage: `url(${UrlHelper.imgPath + item?.background_image
                          })`,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          className="journy-cards_title"
                          sx={{
                            fontSize: "1.7rem",
                            fontWeight: "600",
                            color: Colors.white,
                            px: 2,
                            textShadow: "1px 1px #000",
                          }}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          className="journy-cards_description"
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "500",
                            mt: "1rem",
                            lineHeight: "1.2",
                            color: Colors.white,
                            px: 2,
                            textShadow: "1px 1px #000",
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Fade>
                ))}
            </Box>
          </Box>
        ) : (
          <Box sx={{ pr: 2 }}>
            <Swiper
              spaceBetween={30}
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              pagination={{
                clickable: true,
                renderBullet: function (index: number, className: any) {
                  return (
                    '<span class="' + className + '">' + (index + 1) + "</span>"
                  );
                },
              }}
              slidesPerView={"auto"}
              modules={[Pagination]}
              direction="horizontal"
              breakpoints={{
                320: { slidesPerView: 1 },
                570: { slidesPerView: 2 },
              }}
            >
              {Array.isArray(data?.sub_sections) &&
                data?.sub_sections?.length > 0 &&
                data?.sub_sections?.map((item: any, index: number) => (
                  <SwiperSlide
                    key={index}
                  // style={{ width: "22.5rem", margin: 0 }}
                  >
                    <Fade direction="right" triggerOnce key={index}>
                      <Box
                        // className="journy-card-item_section"
                        sx={{
                          position: "relative",
                          height: "29.375rem",
                          overflow: "hidden",
                          // width: "22.5rem",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "0.625rem",
                          border: `0.0625rem solid ${Colors.border}`,
                          borderRight:
                            index === data?.sub_sections.length - 1
                              ? `0.0625rem solid ${Colors.border}`
                              : "none",
                          transition: "0.5s ease",
                          backgroundImage: `url(${UrlHelper.imgPath + item?.background_image
                            })`,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            className="journy-cards_title"
                            sx={{
                              fontSize: "1.7rem",
                              fontWeight: "600",
                              color: Colors.white,
                              px: 2,
                              textShadow: "1px 1px #000",
                            }}
                          >
                            {item?.title}
                          </Typography>
                          <Typography
                            className="journy-cards_description"
                            sx={{
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              mt: "1rem",
                              lineHeight: "1.2",
                              color: Colors.white,
                              px: 2,
                              textShadow: "1px 1px #000",
                            }}
                          >
                            {item?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Fade>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Journy;
