// ** Service Imports
import apiCall from "../../Helper/Service";

// ** Slice Imports
import {
  fetchBlogs,
  getBlogDetails,
  setHasMore,
  setIsLoading,
  getBlogSetting,
  setRecordCount,
} from ".";

export const getBlogs = async (
  passData: any,
  dispatch: any,
  isInitial: boolean
) => {
  const data = await apiCall({
    url: "blogs/getBlogList",
    data: passData,
    method: "post",
  });

  if (data?.status) {
    dispatch(fetchBlogs({ data: data?.data, isInitial }));
    dispatch(setRecordCount(data?.record_count))
  }
  !data?.status && dispatch(setHasMore(false));

  if (data?.data?.length < 10) {
    dispatch(setHasMore(false));
    dispatch(setIsLoading(false));
    return;
  }

  dispatch(setIsLoading(false));
  return data?.data;
};

export const fetchBlogDetails = async (passData: any, dispatch: any) => {
  const res = await apiCall({
    url: "blogs/getBlogsByUrlSlug",
    data: passData,
    method: "post",
  });

  if (res?.status) {
    dispatch(getBlogDetails(res?.data));
  }

  return res;
};

export const fetchBlogSetting = async (dispatch: any) => {
  const res = await apiCall({
    url: "blogs/getBlogSetting",
    data: {},
    method: "get",
  });

  if (res?.status) {
    dispatch(getBlogSetting(res?.data));
  }

  return res;
};
