// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

// ** Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsTextField from "../../components/CsTextField";
import CsButton from "../../components/CsButton";

// ** Store Imports
import { logo_data, setMsg } from "../../Store/SEO";
import { forgotPassword } from "../../Store/Auth/AuthAction";

// ** Icon Imports
import CsIcon from "../../components/Icon";

// ** Helper Imports
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";


interface Values {
  email: string;
}

const ForgotPassword = () => {
  // State
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const logoData = useSelector(logo_data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const values: Values = {
    email: "",
  };

  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .required("Please enter email")
      .email("Please enter valid email"),
  });

  const formik = useFormik({
    initialValues: values,
    validationSchema: schema,
    onSubmit: async (values: Values) => {
      setLoading(true);
      const res = await forgotPassword({ ...values, id: params?.id });
      if (res?.status) {
        dispatch(setMsg({ open: true, message: res?.message, isError: false }));
        setLoading(false);
      } else {
        dispatch(setMsg({ open: true, message: res?.message, isError: true }));
        setLoading(false);
      }
    },
  });

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          sx={{
            px: 4,
            py: 2,
            borderRadius: "0.5rem",
            width: { sm: "22rem" },
            textAlign: "center",
            boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
            zIndex: "99",
          }}
        >
          <Box
            component={"img"}
            src={
              AppUtils.checkValue(logoData?.logo) && logoData?.logo
                ? `${UrlHelper.imgPath}${logoData?.logo}`
                : "/images/icons/LOGO.svg"
            }
            alt={
              AppUtils.checkValue(logoData?.logo_alt_text)
                ? logoData?.logo_alt_text
                : "Vora Spices Logo"
            }
            sx={{ width: "10rem", height: "auto", mb: 2 }}
          />
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              letterSpacing: "0.18px",
              fontSize: "1.5rem !important",
              textAlign: "left",
            }}
          >
            Forgot Password 🔒
          </Typography>
          <Typography sx={{ my: 2, textAlign: "left" }}>
            Enter your email and we'll send you instructions to reset your
            password
          </Typography>
          <Box component={"form"} autoComplete="off">
            <CsTextField
              autoFocus
              fullWidth
              sx={{ mb: 4 }}
              id="email"
              placeholder="Enter Email"
              label="Email"
              type={"email"}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <CsButton
              fullWidth
              label="Send reset link"
              variant="contained"
              sx={{ mb: 2, textTransform: "none" }}
              onClick={() => formik.handleSubmit()}
              loading={loading}
            />
            <CsButton
              fullWidth
              label="Back to login"
              variant="outlined"
              sx={{ my: 2, textTransform: "none" }}
              startIcon={<CsIcon icon={"ion:chevron-back-outline"} />}
              onClick={() => {
                formik.resetForm();
                navigate("/login");
              }}
              loading={loading}
            />
          </Box>
        </Card>
      </Box>
      <Box
        component={"img"}
        src="/images/icons/VoraSpices.svg"
        alt="Logo Image"
        sx={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: "50%",
          top: "50%",
          transform: "translateY(-50%)",
          filter: "blur(4px)",
        }}
      />
    </Box>
  );
};

export default ForgotPassword;
