// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";

// ** Custom Imports
import CsHeader from "../../components/CsHeader";
import CsButton from "../../components/CsButton";

const Error404 = () => {
  // Hooks
  const navigate = useNavigate();

  return (
    <>
      <CsHeader
        title="404 Error!"
        src="/images/Pages/MarketInsight.png"
        isError={true}
      />
      <Box sx={{ textAlign: "center", mt: 8 }}>
        <Typography variant="h3" sx={{ textAlign: "center", px: 2 }}>
          It seems the page you are looking for does not exist.
        </Typography>
        <Box
          component={"img"}
          src={"/images/Pages/404.png"}
          sx={{ width: "100%", height: "auto", my: 2 }}
        />
        <CsButton
          label="Return to Home Page"
          variant="contained"
          sx={{ my: 4, textTransform: "none" }}
          onClick={() => navigate("/")}
        />
      </Box>
    </>
  );
};

export default Error404;
