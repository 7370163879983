// ** React Imports
import { memo, useMemo } from "react";

// ** Third Party Imports
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const Map = () => {
  // Hooks
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const center = useMemo(() => ({ lat: 19.189943, lng: 72.838208 }), []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAg1DPUqnLyuFzwZBshW75XAq-8hIHH66g",
  });

  return (
    isLoaded && (
      <GoogleMap center={center} zoom={13} mapContainerStyle={containerStyle}>
        <Marker position={center} />
      </GoogleMap>
    )
  );
};

export default memo(Map);
