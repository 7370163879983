// ** Service Imports
import apiCall from "../../../Helper/Service";

export const setInquiryForm = async (passData: any) => {
  const res = await apiCall({
    url: "contactUs/submitInquiry",
    data: passData,
    method: "post",
  });

  return res;
};
