// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const detailsState: { detailsData: any; ratioData: any, isLoading: boolean, allCategories: any[] } = {
  detailsData: {},
  ratioData: [],
  isLoading: false,
  allCategories: []
};

const DetailsSlice = createSlice({
  name: "details",
  initialState: detailsState,
  reducers: {
    getProductDetails(state: any, action: any) {
      state.detailsData = action.payload;
    },
    setRatioData(state: any, action: any) {
      state.ratioData = action.payload;
    },
    setIsLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    setAllCategories(state: any, action: any) {
      state.allCategories = action.payload;
    },
    setIsSubmited(state: any, action: any) {
      state.detailsData = {
        ...state.detailsData,
        is_submited: action.payload,
      };
    },
  },
});

export const { getProductDetails, setIsSubmited, setRatioData, setIsLoading, setAllCategories }: any =
  DetailsSlice.actions;

export default DetailsSlice.reducer;

export const product_details_data = (state: any) =>
  state.product.details.detailsData;

export const ratio_data = (state: any) => state.product.details.ratioData;

export const is_loading = (state: any) => state.product.details.isLoading;

export const all_categories = (state: any) => state.product.details.allCategories;
