// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// ** Third Party Imports
import InfiniteScroll from "react-infinite-scroll-component";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Custom Imports
import CsSelect from "../../../../components/csSelect";
import CsImgBox from "../../../../components/CsImgBox";
import CsAutoComplete from "../../../../components/CsAutoComplete";

// ** Icon Imports
import CsIcon from "../../../../components/Icon";

// ** Store Imports
import {
  all_products,
  certifications_data,
  hash_more,
  prodcut_record_count,
  type_of_spices,
} from "../../../../Store/HomePage/Product";
import {
  fetchCertifications,
  fetchTypeOfSpices,
  getProducts,
} from "../../../../Store/HomePage/Product/ProductAction";
import { product_banner_data } from "../../../../Store/Product/Banner";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";
import UrlHelper from "../../../../Helper/Url";
import countryOptions from "../../../../Helper/CountryName";
import { all_categories } from "../../../../Store/Product/DetailsPage";
import { fetchAllCategories } from "../../../../Store/Product/DetailsPage/DetailsAction";

const colors = [
  "#289D1A",
  "#D07335",
  "#23252A",
  "#ECA84B",
  "#7A934E",
  "#D26549",
  "#50B944",
  "#C28324",
  "#525458",
];

const AllProduct = () => {
  // State
  const dataLimits = 10;
  const [spicesOption, setSpiceOption] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [categoryOpt, setCategoryOpt] = useState<any[]>([]);
  const [typeOfSpice, setTypeOfSpice] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsData = useSelector(all_products);
  const totalRecords = useSelector(prodcut_record_count);
  const typeOfSpices = useSelector(type_of_spices);
  const allCetagories = useSelector(all_categories)
  const isMore = useSelector(hash_more);
  const bannerData = useSelector(product_banner_data);

  const handleHover = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);

    return colors[randomIndex];
  };

  const handleChange = useCallback(() => {
    getProducts(
      {
        search: "",
        status: 0,
        startToken: 1,
        endToken: dataLimits,
        certifications: [],
        typeOfSpices: typeOfSpice?.map((item: any) => item?.value),
        country: country?.map((item: any) => item?.short_code),
        categoryId: category.length > 0 ? category.map((c) => c.value) : [],
      },
      dispatch,
      true
    );
  }, [typeOfSpice, country, category]);

  const fetchMoreData = () => {
    getProducts(
      {
        search: "",
        status: 0,
        startToken: Math.ceil(productsData?.length / dataLimits) + 1,
        endToken: dataLimits,
        certifications: [],
        typeOfSpices: typeOfSpice,
        country: country,
        categoryId: category.length > 0 ? category.map((c) => c.value) : [],
      },
      dispatch,
      false
    );
  };

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  useEffect(() => {
    if (Array.isArray(typeOfSpices) && typeOfSpices.length === 0) {
      fetchTypeOfSpices(dispatch);
    } else {
      setSpiceOption(
        typeOfSpices.map((item: any, index: number) => {
          return { value: item?._id, title: item?.type_name };
        })
      );
    }
  }, [typeOfSpices]);

  useEffect(() => {
    if (Array.isArray(allCetagories) && allCetagories.length === 0) {
      fetchAllCategories(dispatch);
    } else {
      setCategoryOpt(
        allCetagories.map((item: any, index: number) => {
          return { value: item?._id, title: item?.category_name };
        })
      );
    }
  }, [allCetagories]);

 

  return (
    <Box sx={{ mt: "1.25rem", position: "relative" }}>
      <Box sx={{ width: "80%", m: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Slide triggerOnce direction="down">
              <Typography
                sx={{
                  color: Colors.text,
                  fontSize: { xs: "2rem", sm: "3rem" },
                }}
              >
                {bannerData?.page_title}
              </Typography>
            </Slide>

            <Fade triggerOnce direction="up">
              <Typography
                sx={{
                  color: Colors.descTxt,
                  fontSize: "0.9rem",
                  mt: "10px",
                }}
              >
                {bannerData?.description}
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Fade triggerOnce direction="right">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CsAutoComplete
                    label={"Select Category"}
                    multiple={true}
                    getOptionLabel={(option: any) => option.title || ""}
                    options={categoryOpt ?? []}
                    value={category}
                    onChange={(e: any, value: any) => setCategory(value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CsAutoComplete
                    label={"Types of spices"}
                    multiple={true}
                    getOptionLabel={(option: any) => option.title || ""}
                    options={spicesOption ?? []}
                    value={typeOfSpice}
                    onChange={(e: any, value: any) => setTypeOfSpice(value)}
                  />
                </Grid>
                <Grid item xs={12} >
                  <CsAutoComplete
                    label={"Country Preferences"}
                    multiple={true}
                    getOptionLabel={(option: any) => option.country_name || ""}
                    options={countryOptions ?? []}
                    value={country}
                    onChange={(e: any, value: any) => setCountry(value)}
                  />
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <CsImgBox
        src={"/images/Pages/BluarLeaf1.png"}
        imgSx={{ width: "8rem", height: "8rem" }}
        boxSx={{
          position: "relative",
          top: "8rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/Leaf1.png"}
        imgSx={{ width: "5rem", height: "auto" }}
        boxSx={{
          top: { xs: "20%", md: "10%" },
          right: "0",
        }}
      />
      <CsImgBox
        src={"/images/Pages/BlurBrownDot.png"}
        imgSx={{ width: "5rem", height: "auto" }}
        boxSx={{
          top: { md: "18%" },
          right: "25rem",
          display: { xs: "none", sm: "block" },
        }}
      />

      <CsImgBox
        src={"/images/Pages/BluarLeaf1.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          top: { md: "40%" },
          right: "0",
        }}
      />
      <CsImgBox
        src={"/images/Pages/BlurLeaf.png"}
        imgSx={{ width: "8rem", height: "auto", zIndex: 1 }}
        boxSx={{
          top: { xs: "50%", md: "69%" },
          left: "0",
        }}
      />
      <CsImgBox
        src={"/images/Pages/AllProductmain.png"}
        imgSx={{ width: "12rem", height: "auto", zIndex: 1 }}
        boxSx={{
          top: "64%",
          right: "0",
        }}
      />
      <CsImgBox
        src={"/images/Pages/Leaf.png"}
        imgSx={{ width: "8rem", height: "auto" }}
        boxSx={{
          top: { md: "59%" },
          right: "25rem",
          display: { xs: "none", sm: "block" },
        }}
      />
      <CsImgBox
        src={"/images/Pages/BrownDot.png"}
        imgSx={{ width: "2rem", height: "auto" }}
        boxSx={{
          top: { xs: "80%", md: "95%" },
          left: { xs: "1rem", sm: "3rem" },
        }}
      />
      <Box sx={{ my: 8 }}>
        <InfiniteScroll
          dataLength={productsData?.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={<></>}
        >
          <Grid container spacing={4} sx={{ zIndex: 2 }}>
            {Array.isArray(productsData) &&
              productsData?.length > 0 &&
              productsData.filter(product => product.is_publish === true)
                .map((product, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Fade triggerOnce direction="up" className="products_fade">
                      <Card
                        className="product_cards"
                        sx={{
                          width: "18rem",
                          height: "29rem",
                          border: `0.0625rem solid ${Colors.border}`,
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                          boxShadow: "0px 0px 2.5rem rgba(0, 0, 0, 0.1)",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: handleHover(),
                            transition: "background-color 0.5s ease",
                            borderRadius: "20px",
                            width: "-webkit-fill-available",
                            height: "60%",
                            p: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            "&:hover": {
                              bgcolor: handleHover(),
                            },
                          }}
                        >
                          <Box sx={{ position: "absolute", top: "3rem" }}>
                            <Box
                              component={"img"}
                              src={product.product_image && `${UrlHelper.imgPath}${product.product_image}`}
                              alt={
                                product?.product_image_alt_text
                                  ? product?.product_image_alt_text
                                  : product?.product_name
                              }
                              sx={{
                                width: "10.5rem",
                                height: "10.5rem",
                                borderRadius: "20px",
                                objectFit: "cover",
                                boxShadow: "0 0 10px 0 #fff",
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: Colors.white,
                              fontSize: "1.2rem",
                              mt: 2,
                            }}
                          >
                            {product?.product_name}
                          </Typography>
                          <Typography
                            sx={{
                              color: Colors.white,
                              fontSize: "0.7rem",
                              my: "1rem",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              width: "inherit",
                              " & > p": { m: 0 },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: product?.product_description,
                            }}
                          />
                          <Zoom triggerOnce>
                            <Button
                              size="large"
                              variant="outlined"
                              sx={{
                                mt: 1,
                                color: Colors.white,
                                textTransform: "none",
                                borderRadius: "8px",
                                border: `2px solid ${Colors.white} !important`,
                                transition: "all 0.2s ease",
                                ".MuiButton-icon": {
                                  transition: "all 0.2s ease",
                                },
                                "&:hover": {
                                  transform: "scale(1.05)",
                                  ".MuiButton-icon": {
                                    transform: "rotate(45deg)",
                                    ml: 2,
                                  },
                                },
                              }}
                              endIcon={
                                <CsIcon
                                  icon={"material-symbols:arrows-more-up-rounded"}
                                />
                              }
                              onClick={() =>
                                navigate(`/product/${product?.url_slug}`)
                              }
                            >
                              Know More
                            </Button>
                          </Zoom>
                        </Box>
                      </Card>
                    </Fade>
                  </Grid>
                ))}
          </Grid>
          {
            totalRecords > productsData.length &&
            <Box width={'100%'} display={'flex'} my={5}>
              <Button
                size="large"
                sx={{
                  mx: 'auto',
                  color: Colors.green,
                  textTransform: "none",
                }}
                onClick={() => fetchMoreData()}
                endIcon={<CsIcon icon={"ic:baseline-downloading"} />}
              >
                Load Mode
              </Button>
            </Box>
          }
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default AllProduct;
