// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import {
  fetchProducts,
  getCertifications,
  getProductData,
  getTypeofSpices,
  setHasMore,
  setIsLoading,
  setRecordCount,
} from ".";

export const fetchProductData = async (dispatch: any) => {
  const res = await apiCall({
    url: "product/getProductList",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getProductData(res?.data));
    dispatch(setRecordCount(res?.record_count))
  }
};

export const getProducts = async (
  passData: any,
  dispatch: any,
  isInitial: boolean
) => {
  const data = await apiCall({
    url: "product/getProductList",
    data: passData,
    method: "post",
  });

  data?.status &&
    dispatch(fetchProducts({ data: data?.data, isInitial: isInitial }));
  dispatch(setRecordCount(data?.record_count))

  !data?.status && dispatch(setHasMore(false));

  if (data?.data?.length < 10) {
    dispatch(setHasMore(false));
    dispatch(setIsLoading(false));

    return;
  }

  dispatch(setIsLoading(false));

  return data?.data;
};

export const fetchTypeOfSpices = async (dispatch: any) => {
  const res = await apiCall({
    url: "product/getTypeOfSpicesList",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getTypeofSpices(res?.data));
  }
};

export const fetchCertifications = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getCertificationsList",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getCertifications(res?.data));
  }
};

export const getProductsList = async (passData: any, dispatch: any) => {
  dispatch(setIsLoading(true));
  const data = await apiCall({
    url: "product/getProductList",
    data: passData,
    method: "post",
  });

  if (data?.status) {
    return data?.data;
  }
  dispatch(setIsLoading(false));
};
