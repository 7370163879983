// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getQualityData } from ".";

export const fetchQualityData = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getSustainabilityAndQuality",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getQualityData(res?.data));
  }
};
