// ** React Imports
import { useEffect } from "react";

// ** Third Party Imports
import { useDispatch } from "react-redux";

// ** Store Imports
import { fetchLogoData } from "../Store/SEO/SEOAction";

const BlankLayout = (props: any) => {
  // Props
  const { children } = props;

  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLogoData(dispatch);
  }, []);

  return <>{children}</>;
};

export default BlankLayout;
