// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { getBannerData } from ".";

export const fetchBannerData = async (dispatch: any) => {
  const res = await apiCall({
    url: "homePage/getBannerSection",
    data: {},
    method: "post",
  });

  if (res?.status) {
    dispatch(getBannerData(res?.data));
  }
};

export const setVisitor = async (passData: any) => {
  const res = await apiCall({
    url: "SEO/storeVisitor",
    data: passData,
    method: "post",
  });

  return res;
};

export const setDailyVisitor = async (passData: any) => {
  try {
    const res = await apiCall({
      url: "SEO/setDailyVisitor",
      data: passData,
      method: "post",
    });
    return res;
  } catch (error) {
    console.log('error :: ', error)
  }
};
