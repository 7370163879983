// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Store Imports
import { pages } from "../../../../Store/SEO";

// ** Custom Imports
import CsButton from "../../../../components/CsButton";

// ** Helper Imports
import Colors from "../../../../Helper/Colors";

const DeeperAnalysis = () => {
  // Hooks
  const navigate = useNavigate();
  const allPages = useSelector(pages);

  return (
    <Box>
      <Box
        sx={{
          borderTop: `0.0625rem solid${Colors.border}`,
          borderBottom: `0.0625rem solid${Colors.border}`,
          my: "1.25rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            pl: { xs: 2, sm: 4 },
            p: { xs: 2, md: 4 },
            width: { xs: "auto", md: "50%" },
          }}
        >
          <Fade triggerOnce direction="left">
            <Typography sx={{ fontSize: { xs: "1.5rem", sm: "2.5rem" } }}>
              Need Deeper Analysis ?
            </Typography>
          </Fade>
          <Fade triggerOnce direction="up">
            <Typography
              sx={{
                color: Colors.descTxt,
                fontSize: { xs: "1rem", sm: "1.2rem" },
                my: 2,
              }}
            >
              Contact Us for Custom Market Research
            </Typography>
          </Fade>
          <Zoom triggerOnce>
            <CsButton
              label="Contact Us"
              variant="contained"
              onClick={() =>
                navigate(
                  `/${allPages?.find(
                    (item: any) => item?.default_key === "ContactUs"
                  )?.url_slug
                  }`
                )
              }
              sx={{ mb: { xs: 0, md: 4 }, mt: 2 }}
            />
          </Zoom>
        </Box>

        <Box
          sx={{
            width: { xs: "auto", md: "50%" },
            height: "auto",
            pt: { xs: 0, md: 4 },
          }}
        >
          <Fade triggerOnce direction="right">
            <Box
              component={"img"}
              src={"/images/Pages/DiscoverDifference.png"}
              alt={`Image for Signature`}
              sx={{ width: "100%", height: "100%" }}
            />
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};

export default DeeperAnalysis;
