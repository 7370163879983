// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// ** Custom Imports
import CsImgBox from "../../components/CsImgBox";
import CsTextField from "../../components/CsTextField";
import CsButton from "../../components/CsButton";
import CsMobileField from "../../components/CsMobileInput";
import CsIcon from "../../components/Icon";
import Marquee from "../Home/Components/Marquee";

// ** Store Imports
import { product_details_data } from "../../Store/Product/DetailsPage";
import { auth_data } from "../../Store/Auth";
import {
  cloneInquiry,
  fetchProductDetails,
  setProductInquiryForm,
} from "../../Store/Product/DetailsPage/DetailsAction";
import { pages, setMsg } from "../../Store/SEO";

// ** Helper Imports
import Colors from "../../Helper/Colors";
import UrlHelper from "../../Helper/Url";
import AppUtils from "../../Helper/AppUtils";
import { Helmet } from "react-helmet";

interface Inquiry {
  name: string;
  email: string;
  contact_number: string;
  country: any;
  message: string;
  company_name: string;
  country_code: number;
}

interface InitialStd {
  value: string;
  label: string;
  key: string;
  image: string;
  country_name: string;
  currency: string;
  currency_symbol: string;
}

const Product = () => {
  // State
  const [showBox, setShowBox] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<number>();

  // const [selectedCardIndex, setSelectedCardIndex] = useState<number>(1);

  const initialStd = {
    key: "IN",
    value: "91",
    label: "+91",
    image: "/images/countryFlages/IN_+91.svg",
    country_name: "India",
    currency: "INR",
    currency_symbol: "₹",
  };

  const [stdCode, setStdCode] = useState<InitialStd>(initialStd);
  const [loading, setLoading] = useState<boolean>(false);
  const [months, setMonths] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState<number>(1);

  // Media Query
  const sm = useMediaQuery("(max-width: 650px)");

  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const data = useSelector(product_details_data);
  const allPages = useSelector(pages);
  const authData = useSelector(auth_data);
  // const cardsData = [
  //   { title: "Whole", imgSrc: "/images/Pages/Whole.png" },
  //   { title: "Ground", imgSrc: "/images/Pages/Ground.png" },
  //   { title: "Tea Bag Cut", imgSrc: "/images/Pages/TeaBagCut.png" },
  // ];

  const initialValues: Inquiry = {
    name: "",
    email: "",
    contact_number: "",
    message: "",
    country: null,
    company_name: "",
    country_code: 91,
  };

  const schema = yup.object({
    name: yup.string().required("Please enter yout name."),
    company_name: yup.string().required("Please enter company."),
    email: yup
      .string()
      .required("Please enter Email")
      .email("Please enter valid email"),
    contact_number: yup.string().required("Please enter Contact number"),
    message: yup.string().required("Please enter Message"),
    // country: yup.mixed().required("Please select country"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: Inquiry) => {
      setLoading(true);
      const res = await setProductInquiryForm({
        ...value,
        name: value.name.trim(),
        message: value.message.trim(),
        country_code: value.country_code
          ? value.country_code
          : parseInt(stdCode.value),
        country: value.country
          ? value.country
          : {
            short_code: stdCode?.key,
            country_name: stdCode?.country_name,
            symbol: stdCode?.currency_symbol,
            currency: stdCode?.currency,
          },
        product_id: data?._id,
      });
      if (res?.status) {
        formik.resetForm();
        setShowBox(!showBox)
        setStdCode(initialStd);
        dispatch(setMsg({ open: true, message: res?.message, isError: false }));
      } else {
        AppUtils.checkValue(res?.message) &&
          Array.isArray(res?.message) &&
          res?.message?.length > 0 &&
          res?.message?.map((item: any) => {
            formik.setFieldTouched(item?.field, true, false);
            formik.setFieldError(item?.field, item?.message);
          });
      }
      setLoading(false);
    },
  });

  const getDetails = useCallback(async () => {
    if (AppUtils.checkValue(params?.id)) {
      const res = await fetchProductDetails(
        { url_slug: params?.id, customer_id: authData?._id },
        dispatch
      );
      !res?.status &&
        navigate(
          `/${allPages?.find((item: any) => item?.default_key === "AllProducts")
            ?.url_slug
          }`
        );
    }
  }, [params?.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (
      AppUtils.checkValue(data?.crop_season) &&
      Array.isArray(data?.crop_season) &&
      data?.crop_season?.length > 0
    ) {
      if (sm) {
        setMonths([
          { value: 1, label: data?.crop_season[0]?.month },
          {
            value: data?.crop_season?.length,
            label: data.crop_season[data.crop_season.length - 1]?.month,
          },
        ]);
      } else {
        setMonths(
          data.crop_season.map((item: any, i: number) => ({
            value: i + 1,
            label: item?.month,
          }))
        );
      }
    }
  }, [data, sm]);

  const size = (section: any) => {
    switch (section.section_column) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 12;
    }
  };

  const handleAccordianChange = (panel: any) => (event: any, isExpanded: any) =>
    setIsOpen(isExpanded ? panel : null);

  return (
    <>
      <Helmet>
        <meta name="description" content={data?.seo?.meta_description} />
        <title>{data?.seo?.meta_title}</title>
        {AppUtils.checkValue(data?.seo?.meta_keywords) && (
          <meta name="keywords" content={data?.seo?.meta_keywords} />
        )}
        {AppUtils.checkValue(data?.seo?.meta_keywords) && (
          <script type="application/ld+json">
            {data?.seo?.meta_schema}
          </script>
        )}
      </Helmet>

      <Box sx={{ overflowX: "hidden" }}>
        <Box
          component={'img'}
          src={data?.product_image && `${UrlHelper.imgPath}${data?.product_image}`}
          sx={{
            width: "100%",
            // backgroundImage: `${Colors.overlay}, url(${UrlHelper.imgPath}${data?.product_image})`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            height: { xs: "auto", lg: "100vh" },
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* <Typography
          sx={{
            color: Colors.white,
            fontSize: { xs: "5rem", sm: "8rem" },
            ml: { xs: "2rem", sm: "5rem", md: "10rem" },
          }}
        >
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            {data?.product_name}
          </Fade>
        </Typography> */}

          {/* <Fade triggerOnce>
            <Box
              sx={{
                pl: { xs: 1.5, sm: 3, md: 4 },
                pr: 2,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: Colors.white,
                  fontWeight: 600,
                  pl: 4,
                  fontSize: { xs: "2.5rem", sm: "6rem", md: "8rem" },
                }}
              >
                {data?.tag_line}
              </Typography>
            </Box>
          </Fade> */}
        </Box>
        <Box sx={{ width: '80%', m: 'auto' }}>
          {/* <Fade direction="up" triggerOnce>
          <Grid container sx={{ width: "100%", m: 0, mt: 4 }}>
            {Array.isArray(cardsData) &&
              cardsData?.length > 0 &&
              cardsData?.map((item: any, index: number) =>
                cards(item, index, false)
              )}
          </Grid>
        </Fade>
        <Divider sx={{ my: "2.125rem", borderWidth: "2px" }} /> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CsImgBox
              src={"/images/Pages/YellowDotGrp.png"}
              imgSx={{ width: "3rem", height: "auto" }}
              boxSx={{ left: "1rem" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                position: "relative"
              }}
            >
              <Typography
                sx={{
                  color: Colors.text,
                  textAlign: "start",
                  fontSize: { xs: "1.7rem", sm: "3.5rem" },
                }}
              >
                <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                  {data?.product_name}
                </Fade>
              </Typography>
              <CsImgBox
                src={"/images/Pages/BluarLeaf1.png"}
                imgSx={{ width: "8rem", height: "auto" }}
                boxSx={{ position: "relative" }}
              />
            </Box>
          </Box>
          <Fade direction="up" triggerOnce>
            <Typography
              dangerouslySetInnerHTML={{
                __html: data?.product_description,
              }}
              sx={{ mb: 4 }}
            />
          </Fade>

          {/* Crop Season */}
          <Box
            sx={{
              border: `0.0625rem solid ${Colors.green}`,
              p: "1rem",
              borderRadius: "0.625rem",
              mb: 4
            }}
          >
            <Grid
              container
              sx={{
                bgcolor: Colors.bgcolor,
                m: 0,
                width: "100%",
                boxShadow: `0 0 5px 0 ${Colors.green}`,
                borderRadius: "0.625rem",
              }}
            >
              <Grid item xs={12} sx={{ p: 2 }}>
                <Fade direction="up" triggerOnce>
                  <Typography
                    variant="h5"
                    sx={{ color: Colors.text, my: "1.25rem" }}
                  >
                    Crop Season
                  </Typography>
                  <Box sx={{ mx: 2 }}>
                    <Slider
                      defaultValue={4}
                      step={1}
                      marks={months}
                      min={1}
                      value={sliderValue}
                      onChange={(e: any, val: any) => setSliderValue(val)}
                      max={data?.crop_season?.length}
                      valueLabelFormat={(value) => {
                        if (
                          AppUtils.checkValue(data?.crop_season) &&
                          Array.isArray(data?.crop_season) &&
                          data?.crop_season?.length > 0
                        ) {
                          return data?.crop_season[value - 1]?.month;
                        }
                      }}
                      valueLabelDisplay="auto"
                      sx={{
                        color: Colors.green,
                        "& .MuiSlider-valueLabel": {
                          bgcolor: Colors.green,
                          borderRadius: "8px",
                        },
                        "& .MuiSlider-thumb:hover": {
                          boxShadow: Colors.boxShadow,
                        },
                      }}
                    />
                  </Box>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Fade direction="up" triggerOnce>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ color: Colors.text, my: "1.25rem" }}
                      >
                        Grades
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ color: Colors.text, my: "1.25rem" }}
                      >
                        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                          {data?.crop_season?.length > 0
                            ? data?.crop_season[sliderValue - 1]?.grade
                            : ""}
                        </Fade>
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      sx={{ borderWidth: "0.0625rem", height: "auto" }}
                    />
                  </Box>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Fade direction="up" triggerOnce>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ color: Colors.text, my: "1.25rem" }}
                      >
                        Special Remarks
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ color: Colors.text, my: "1.25rem" }}
                      >
                        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                          {data?.crop_season?.length > 0
                            ? data?.crop_season[sliderValue - 1]?.special_remark
                            : ""}
                        </Fade>
                      </Typography>
                    </Box>
                  </Box>
                </Fade>
              </Grid>
            </Grid>
          </Box>

          {/* Marquee  */}
          <Box sx={{
            position: "absolute", left: "0px", right: "0px"
          }}>
            <Marquee />
          </Box>

          {/* Usage Tips */}
          <Card
            sx={{
              px: 4,
              py: 2,
              borderRadius: "0.875rem",
              boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
              mt: 26,
            }}
          >
            <Typography sx={{ fontSize: { xs: "1rem", sm: "1.5rem" }, fontWeight: 600 }}>
              <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                {data?.usage_tips_title}
              </Fade>
            </Typography>
            <Fade direction="up" triggerOnce>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data?.usage_tips,
                }}
              />
            </Fade>
          </Card>

          {/* Origin Stories */}
          <Card
            sx={{
              px: 4,
              py: 2,
              borderRadius: "0.875rem",
              boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
              mt: 6,
              mb: 2
            }}
          >
            <Typography sx={{ fontSize: { xs: "1rem", sm: "1.5rem" }, fontWeight: 600 }}>
              <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                {data?.origin_story_title}
              </Fade>
            </Typography>

            <Fade direction="up" triggerOnce>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data?.origin_story,
                }}
              />
            </Fade>
          </Card>

          {/* Section  */}
          <Grid container spacing={5} sx={{ mt: "-18px" }}>
            {data?.sections?.length > 0 && data?.sections.map((section: any, index: any) => (
              <Grid item xs={12} md={size(section)}
                key={index}
                style={{ display: "flex", flexDirection: "column" }}>
                <Card
                  sx={{
                    mt: 1,
                    borderRadius: "0.875rem",
                    boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
                    height: '100%',
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ py: 2, px: 4 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '1rem', sm: '1.5rem' },
                        fontWeight: 600,
                        marginBottom: 2,
                      }}
                    >
                      {section.section_title}
                    </Typography>
                    <Fade direction="up" triggerOnce>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: section.section_description,
                        }}
                      />
                    </Fade>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Questions */}
          <Card
            sx={{
              py: 2,
              borderRadius: "0.875rem",
              boxShadow: `0 0 1rem 0 rgba(31, 166, 15, 0.2)`,
              mt: 6,
            }}
          >
            <Box sx={{ py: 2, px: 4 }}>
              <Typography
                sx={{
                  fontSize: { xs: '1rem', sm: '1.5rem' },
                  fontWeight: 600,
                  marginBottom: 2,
                }}
              >
                {data?.question?.title}
              </Typography>

              <Fade direction="up" triggerOnce>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: data?.question?.description,
                  }}
                />
              </Fade>
            </Box>
          </Card>

          {/* FAQs  */}
          <Box sx={{ mb: 4, mt: 4 }}>
            <Typography variant="h4" sx={{ textAlign: "center", my: 2 }}>
              {data?.faq_title}
            </Typography>
            {Array.isArray(data?.faq) &&
              data?.faq.length > 0 &&
              data?.faq.map((item: any, i: number) => {
                return (
                  <Fade direction="up" triggerOnce key={i}>
                    <Accordion
                      sx={{
                        my: "0 !important",
                        borderRadius: "0.625rem !important",
                        bgcolor: Colors.bgcolor,
                        overflow: "hidden",
                        border: `0.0625rem solid ${Colors.green}`,
                        borderBottom:
                          i === data?.faq?.length - 1
                            ? `0.0625rem solid ${Colors.green}`
                            : "none",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.01)",
                        },
                      }}
                      expanded={isOpen === i}
                      onChange={handleAccordianChange(i)}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<CsIcon icon={"mingcute:down-line"} />}
                      >
                        <Typography sx={{ fontWeight: 600, color: Colors.text }}>
                          {item?.title}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails sx={{ "& > p": { color: Colors.text } }}>
                        {item?.description}
                      </AccordionDetails>
                    </Accordion>
                  </Fade>
                );
              })}
          </Box>

          {/* Send Inquiries Check Box */}
          <Fade direction="up" triggerOnce>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: "1.25rem",
                position: "relative",
              }}
            >
              <FormControlLabel
                sx={{
                  "& > span": { fontWeight: 600 },
                  "& .MuiSvgIcon-root": { fontSize: 40 },
                  "& .Mui-checked": { color: `${Colors.green} !important` },
                  "& .Mui-disabled": { color: `${Colors.lightGrey} !important` },
                }}
                control={
                  <Checkbox
                    disabled={
                      AppUtils.checkValue(authData) &&
                      Object?.keys(authData)?.length > 0 &&
                      data?.is_submited
                    }
                    checked={
                      AppUtils.checkValue(authData) &&
                        Object?.keys(authData)?.length > 0
                        ? data?.is_submited
                          ? true
                          : false
                        : showBox
                    }
                    onChange={async () => {
                      if (
                        AppUtils.checkValue(authData) &&
                        Object?.keys(authData)?.length > 0
                      ) {
                        if (!data?.is_submited) {
                          const res = await cloneInquiry(
                            { product_id: data?._id },
                            dispatch
                          );
                          if (res?.status) {
                            dispatch(
                              setMsg({
                                open: true,
                                message: res?.message,
                                isError: false,
                              })
                            );
                          } else {
                            dispatch(
                              setMsg({
                                open: true,
                                message: res?.message,
                                isError: true,
                              })
                            );
                          }
                        }
                      } else {
                        setShowBox(!showBox);
                        !showBox && formik.resetForm();
                      }
                    }}
                  />
                }
                label="Interested? Send your Inquiries now."
              />

              <CsImgBox
                src={"/images/Pages/Leaf3.png"}
                imgSx={{ width: "8rem", height: "auto" }}
                boxSx={{ right: 0, top: 0, display: { xs: "none", sm: "block" } }}
              />
            </Box>
          </Fade>

          {/* Inquirey Popup  */}
          <Box sx={{ position: "relative" }}>
            <Collapse in={showBox} timeout="auto">
              <Box
                sx={{
                  border: `0.0625rem solid ${Colors.black}`,
                  borderRadius: "1.8125rem",
                  px: { xs: 4, sm: 6 },
                  py: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: Colors.text, my: "1.25rem", width: "max-content" }}
                >
                  <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                    Interested In :
                  </Fade>
                </Typography>
                {/* <Fade direction="up" triggerOnce>
                <Grid container>
                  {Array.isArray(cardsData) &&
                    cardsData?.length > 0 &&
                    cardsData?.map((card: any, index: number) =>
                      cards(card, index, true)
                    )}
                </Grid>
              </Fade> */}
                {/* <Typography
                variant="h5"
                sx={{ color: Colors.text, my: "20px", width: "max-content" }}
              >
                <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
                  Contact Details :
                </Fade>
              </Typography> */}
                <Grid container sx={{ width: "100%", m: 0 }}>
                  <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                    <Fade direction="left" triggerOnce>
                      <CsTextField
                        label={"Full Name"}
                        placeholder="Enter Full Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                    <Fade direction="right" triggerOnce>
                      <CsTextField
                        label={"Email Address"}
                        placeholder="Enter Email Address"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                    <Fade direction="left" triggerOnce>
                      <CsMobileField
                        label="Contact Number"
                        fullWidth
                        id={"contact_number"}
                        name="contact_number"
                        placeholder={"Contact Number"}
                        type="text"
                        size="small"
                        value={formik.values.contact_number}
                        onChange={(e: any) => {
                          e.currentTarget.value = e.currentTarget.value
                            .replace(/[^0-9]/g, "")
                            .slice(0, 10);
                          formik.handleChange(e);
                        }}
                        error={
                          formik.touched.contact_number &&
                          Boolean(formik.errors.contact_number)
                        }
                        helperText={
                          formik.touched.contact_number &&
                          formik.errors.contact_number
                        }
                        stdcode={stdCode}
                        stdCodeChange={(val: any) => {
                          formik.setFieldValue(
                            "country_code",
                            parseInt(val?.value)
                          );
                          formik.setFieldValue("country", {
                            short_code: val?.key,
                            country_name: val?.country_name,
                            symbol: val?.currency_symbol,
                            currency: val?.currency,
                          });
                          setStdCode(val);
                        }}
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                    <Fade direction="right" triggerOnce>
                      <CsTextField
                        label={"Comapny Name"}
                        placeholder="Enter Company Name"
                        name="company_name"
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.company_name &&
                          Boolean(formik.errors.company_name)
                        }
                        helperText={
                          formik.touched.company_name &&
                          formik.errors.company_name
                        }
                      />
                    </Fade>
                  </Grid>
                  {/* <Grid item xs={12} sx={{ pb: 3 }}>
                  <Fade direction="up" triggerOnce>
                    <CsAutoComplete
                      label={"Select Country"}
                      getOptionLabel={(option: any) =>
                        option.country_name || ""
                      }
                      name="country"
                      options={countryOptions ?? []}
                      value={formik.values.country}
                      onChange={(e: any, value: any) =>
                        formik.setFieldValue("country", value)
                      }
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                    />
                  </Fade>
                </Grid> */}

                  <Grid item xs={12} sx={{ pb: 3 }}>
                    <Fade direction="up" triggerOnce>
                      <CsTextField
                        label={"Message"}
                        placeholder="Enter Message"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.message && Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item sx={{ ml: "auto" }}>
                    <Zoom triggerOnce>
                      <CsButton
                        label="Submit"
                        variant="contained"
                        sx={{ mb: 2, textTransform: "none" }}
                        onClick={() => formik.handleSubmit()}
                        loading={loading}
                      />
                    </Zoom>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
            <CsImgBox
              src={"/images/Pages/YellowDotGrp.png"}
              imgSx={{ width: "3rem", height: "auto" }}
              boxSx={{ right: 0 }}
            />
            <CsImgBox
              src={"/images/Pages/BlurLeaf.png"}
              imgSx={{ width: { xs: "8rem", sm: "10rem" }, height: "auto" }}
              boxSx={{
                bottom: showBox ? "2rem" : 0,
                left: "10rem",
                position: "relative",
                width: "max-content",
              }}
            />
          </Box>
          <CsImgBox
            src={"/images/Pages/YellowDot2.png"}
            imgSx={{ width: "4rem", height: "auto" }}
            boxSx={{
              position: "relative",
              left: "3.125rem",
              width: "max-content",
            }}
          />
        </Box>
      </Box >
    </>
  );
};

export default Product;
