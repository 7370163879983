// ** MUI Imports
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

// ** Colors Imports
import Colors from "../../Helper/Colors";

const CsTextField = (props: TextFieldProps) => {
  // Props
  const { label, InputLabelProps, placeholder, error, helperText, sx } = props;

  // change textfield events
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const spaceRegex = /^\s/;
    if(!spaceRegex.test(event.target.value)){
      if (props?.onChange) {
        props.onChange(event);
      }
    }
  };

  return (
    <>
      <Box sx={sx}>
        <Box
          sx={{
            border: `0.0625rem solid ${error ? Colors.error : Colors.black}`,
            borderRadius: "0.9375rem",
            p: 2,
          }}
        >
          <Typography
            sx={{
              color: error ? Colors.error : Colors.descTxt,
              fontSize: "0.9rem",
              textAlign: "left",
            }}
          >
            {label}
          </Typography>
          <TextField
            {...props}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            variant="standard"
            size="small"
            placeholder={placeholder}
            InputLabelProps={{ shrink: true, ...InputLabelProps }}
            sx={{
              border: "none !important",
              "& .MuiFormLabel-root": { display: "none" },
              "& .MuiFormHelperText-root": { display: "none" },
              "& > div": {
                "&::before": { border: "none !important" },
                "&::after": { border: "none !important" },
              },
            }}
          />
        </Box>
        {error && (
          <FormHelperText sx={{ color: Colors.error, pl: 1 }}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

export default CsTextField;
