// ** React Imports
import React, { useState } from "react";

// ** MUI Imports
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Third Party Imports
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Store Imports
import { auth_data } from "../../../Store/Auth";

// ** Icon Imports
import CsIcon from "../../../components/Icon";

// ** Helper Imports
import Colors from "../../../Helper/Colors";
import AppUtils from "../../../Helper/AppUtils";

const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: Colors.green,
  boxShadow: `0 0 0 0.125rem #fff`,
}));

const Profile = (props: any) => {
  //  Props
  const { menu, setMenu } = props;

  // Hooks
  const data = useSelector(auth_data);
  const navigate = useNavigate();
  const dashboardURL = process.env.REACT_APP_DASHBOARD_URL

  return (
    <>
      <Badge
        overlap="circular"
        onClick={(event: any) => setMenu(event.currentTarget)}
        sx={{ ml: 2, cursor: "pointer" }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar
          alt="profile"
          onClick={(event: any) => setMenu(event.currentTarget)}
          sx={{ width: 40, height: 40 }}
          src={"/images/pages/1.png"}
        >
          {(data?.name)
            .split(" ")
            .map((str: string) => str.charAt(0).toUpperCase())}
        </Avatar>
      </Badge>
      <Menu
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
        sx={{ "& .MuiMenu-paper": { mt: 1 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ px: 2, pb: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                alt="profile"
                src={"/images/pages/1.png"}
                sx={{ width: "2.5rem", height: "2.5rem" }}
              >
                {(data?.name)
                  .split(" ")
                  .map((str: string) => str.charAt(0).toUpperCase())}
              </Avatar>
            </Badge>
            <Box
              sx={{
                display: "flex",
                ml: 3,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {data?.name ? data?.name : "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.8rem", wordBreak: "break-word" }}
              >
                {data?.email ? data?.email : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <MenuItem
          onClick={() => {
            setMenu(null);
            window.open(`${dashboardURL}/login?token=${encodeURIComponent(data?.token)}`)
          }}
          sx={{
            mt: 1,
            py: 1,
            "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" },
          }}
        >
          <CsIcon icon="radix-icons:dashboard" />
          Go To Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenu(null);
            AppUtils.removeLocalStorageItem("VORA_CUSTOMER");
            AppUtils.removeLocalStorageItem("VORA_F_CUST");
            navigate(`/login`);
          }}
          sx={{
            mt: 1,
            py: 1,
            "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" },
          }}
        >
          <CsIcon icon="mdi:logout-variant" />
          Logout
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setMenu(null);
            navigate(`/change-password`);
          }}
          sx={{
            mt: 1,
            py: 1,
            "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" },
          }}
        >
          <CsIcon icon="mdi:lock-reset" />
          Change Password
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default Profile;
