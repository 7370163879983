// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface Initial {
  contactData: any;
  isCountryMenuOpen: boolean;
}

const contactState: Initial = {
  contactData: {},
  isCountryMenuOpen: false,
};

const ContactSlice = createSlice({
  name: "section",
  initialState: contactState,
  reducers: {
    getContactData(state: any, action: any) {
      state.contactData = action.payload;
    },
    setCountryCodeMenu(state: any, action: any) {
      state.isCountryMenuOpen = action.payload;
    },
  },
});

export const { getContactData, setCountryCodeMenu }: any = ContactSlice.actions;

export default ContactSlice.reducer;

export const contact_data = (state: any) => state.contact.section.contactData;

export const is_country_code_menu = (state: any) =>
  state.contact.section.isCountryMenuOpen;
