// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const bannerState: { aboutBannerData: any } = {
  aboutBannerData: {},
};

const AboutBannerSlice = createSlice({
  name: "aboutBanner",
  initialState: bannerState,
  reducers: {
    getAboutBannerData(state: any, action: any) {
      state.aboutBannerData = action.payload;
    },
  },
});

export const { getAboutBannerData }: any = AboutBannerSlice.actions;

export default AboutBannerSlice.reducer;

export const about_banner_data = (state: any) =>
  state.about.aboutBanner.aboutBannerData;
