// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// ** Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// ** Custom Imports
import CsTextField from "../../components/CsTextField";
import CsIcon from "../../components/Icon";
import CsButton from "../../components/CsButton";

// ** Store Imports
import { changePassword } from "../../Store/Auth/AuthAction";
import { setMsg } from "../../Store/SEO";

interface Values {
  new_password: string;
  confirm_password: string;
}

const Password = (props: any) => {
  // Props
  const { open, setOpen } = props;

  // State
  const [showPass, setShowPass] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const initialValues: Values = {
    new_password: "",
    confirm_password: "",
  };

  const schema = yup.object({
    new_password: yup
      .string()
      .trim()
      .required("Please enter New password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        "New password Must contain a minimum of 8 characters, at least 1 uppercase character, 1 lowercase character, 1 number, and 1 special case character"
      ),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password")], "Passwords must match")
      .required("Please enter confirm password"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: Values) => {
      setLoading(true);
      const res = await changePassword({ ...values, token: params?.id }, dispatch);
      if (res?.status) {
        navigate("/login");
      } 
      setOpen(false);
      setLoading(false);
    },
  });

  return (
    <Dialog
      open={open}
      sx={{ "& .MuiPaper-root": { borderRadius: "0.5rem", width: "25rem" } }}
    >
      <Typography sx={{ fontSize: "1.2rem", p: "1rem" }}>
        Set New Password
      </Typography>
      <Divider />
      <Box
        component={"form"}
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ px: "1.5rem", pb: 4, pt: 2 }}
      >
        <CsTextField
          autoFocus
          fullWidth
          sx={{ mb: 4 }}
          id="new_password"
          placeholder="Enter New Password"
          label="New Password"
          type={showPass ? "text" : "password"}
          name="new_password"
          value={formik.values.new_password}
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                  <CsIcon
                    icon={showPass ? "mdi:eye-outline" : "mdi:eye-off-outline"}
                    fontSize={30}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={
            formik.touched.new_password && Boolean(formik.errors.new_password)
          }
          helperText={formik.touched.new_password && formik.errors.new_password}
        />
        <CsTextField
          autoFocus
          fullWidth
          sx={{ mb: 4 }}
          id="confirm_password"
          placeholder="Enter Confirm Password"
          label="Confirm Password"
          type={isShow ? "text" : "password"}
          name="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setIsShow(!isShow)}>
                  <CsIcon
                    icon={isShow ? "mdi:eye-outline" : "mdi:eye-off-outline"}
                    fontSize={30}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={
            formik.touched.confirm_password &&
            Boolean(formik.errors.confirm_password)
          }
          helperText={
            formik.touched.confirm_password && formik.errors.confirm_password
          }
        />
        <CsButton
          label="Submit"
          type="submit"
          sx={{ textTransform: "none" }}
          fullWidth
          variant="contained"
          loading={loading}
        />
      </Box>
    </Dialog>
  );
};

export default Password;
