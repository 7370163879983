const Colors = {
  white: "#fff",
  black: "#000",
  green: "#1FA60F",
  text: "#061738",
  bgcolor: "#f0ffe8",
  lightGrey: "#B7B7B7",
  border: "#CDCDCD",
  descTxt: "#5E5E5E",
  boxShadow: `0px 0px 0px 8px rgb(31, 166, 15, 0.16)`,
  bgMarketSpecific: "#23252A",
  descTxt2: "#565656",
  overlay:
    "linear-gradient(180deg, rgba(255,255,255,0) 40%, rgba(0,0,0,1) 100%)",
  cream: "#FAD9A9",
  error: "#d32f2f",
};

export default Colors;
