// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Third Party Imports
import { Fade, Zoom } from "react-awesome-reveal";
import { useSelector } from "react-redux";

// ** Custom Imports
import CsImgBox from "../../../../components/CsImgBox";

// ** Store Imports
import { about_banner_data } from "../../../../Store/AboutUsPage/Banner";

// ** Helper Imports
import UrlHelper from "../../../../Helper/Url";
import Colors from "../../../../Helper/Colors";

const VisionFuture = () => {
  // State
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);

  // Hooks
  const aboutUsData = useSelector(about_banner_data);

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Fade direction="left" triggerOnce>
            <Box
              component={"img"}
              src={aboutUsData?.third_section?.image && `${UrlHelper.imgPath}${aboutUsData?.third_section?.image}`}
              alt={
                aboutUsData?.third_section?.image_ALT_text
                  ? aboutUsData?.third_section?.image_ALT_text
                  : "Vision Future"
              }
              sx={{ width: "100%", height: "auto`" }}
            />
          </Fade>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <CsImgBox
            src={"/images/Pages/Leaf1.png"}
            imgSx={{ width: "6rem", height: "auto" }}
            boxSx={{
              height: "max-content",
              top: "7rem",
              right: "18rem",
            }}
          />
          <Box
            sx={{
              pl: { xs: "1rem", sm: "1.5rem", md: "5rem" },
              pr: { xs: 0, md: "5rem" },
            }}
          >
            <Fade direction="right" triggerOnce>
              <Typography
                sx={{
                  color: Colors.text,
                  fontSize: { xs: "2rem", sm: "3rem" },
                }}
              >
                {aboutUsData?.third_section?.title}
              </Typography>
            </Fade>
            <Fade direction="up" triggerOnce>
              <Typography
                sx={{
                  color: Colors.descTxt,
                  my: "1.25rem",
                  fontSize: "0.9rem",
                }}
              >
                {isShow
                  ? aboutUsData?.third_section?.description
                  : aboutUsData?.third_section?.description.length > 200
                    ? `${aboutUsData?.third_section?.description.slice(0, 200)}...`
                    : aboutUsData?.third_section?.description}
              </Typography>
            </Fade>
            {
              aboutUsData?.third_section?.description.length > 200 &&
              <Zoom triggerOnce>
                <LoadingButton
                  size="small"
                  sx={{
                    color: Colors.green,
                    borderBottom: `0.0625rem solid ${Colors.green}`,
                    textTransform: "none",
                    borderRadius: "0",
                  }}
                  onClick={() => setIsShow(!isShow)}
                >
                  Read {isShow ? "Less" : "More"}
                </LoadingButton>
              </Zoom>
            }
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          my: "5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <CsImgBox
          src={"/images/Pages/BlackPaper.png"}
          imgSx={{ width: "6rem", height: "auto" }}
          boxSx={{
            height: "max-content",
            top: "2rem",
            left: "10rem",
          }}
        />
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box sx={{ pl: { xs: "1rem", sm: "1.5rem", lg: "10rem" } }}>
            <Fade direction="right" triggerOnce>
              <Typography
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                {aboutUsData?.fourth_section?.title}
              </Typography>
            </Fade>
            <Fade direction="up" triggerOnce>
              <Typography
                sx={{ color: Colors.descTxt, my: "20px", fontSize: "0.9rem" }}
              >
                {isMore
                  ? aboutUsData?.fourth_section?.description
                  : aboutUsData?.fourth_section?.description.length > 200
                    ? `${aboutUsData?.fourth_section?.description.slice(0, 200)}...`
                    : aboutUsData?.fourth_section?.description}
              </Typography>
            </Fade>
            {aboutUsData?.fourth_section?.description.length > 200 &&
              <Zoom triggerOnce>
                <LoadingButton
                  size="small"
                  sx={{
                    color: Colors.green,
                    borderBottom: `0.0625rem solid ${Colors.green}`,
                    textTransform: "none",
                    borderRadius: "0",
                  }}
                  onClick={() => setIsMore(!isMore)}
                >
                  Read {isMore ? "Less" : "More"}
                </LoadingButton>
              </Zoom>
            }
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Fade direction="left" triggerOnce>
            <Box
              component={"img"}
              src={aboutUsData?.fourth_section?.image && `${UrlHelper.imgPath}${aboutUsData?.fourth_section?.image}`}
              alt={
                aboutUsData?.fourth_section?.image_ALT_text
                  ? aboutUsData?.fourth_section?.image_ALT_text
                  : "Vision Future"
              }
              sx={{ width: "100%", height: "auto" }}
            />
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisionFuture;
