// ** MUI Imports
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
import useScrollTrigger from "@mui/material/useScrollTrigger";

// ** Icon Imports
import CsIcon from "../../../components/Icon";

// ** Third Party Imports
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Store Imports
import { auth_data } from "../../../Store/Auth";

// ** Helper Imports
import Colors from "../../../Helper/Colors";

const Whatsapp = (props: any) => {
  // Props
  const { data, allPages } = props;

  // Hooks
  const navigate = useNavigate();
  const authUser = useSelector(auth_data);

  // init trigger
  const trigger = useScrollTrigger({
    threshold: 500,
    disableHysteresis: true,
  });

  // init trigger
  const open = useScrollTrigger({
    threshold: 500,
    disableHysteresis: true,
  });

  return (
    <>
      <Zoom in={open}>
        <Box
          sx={{
            position: "fixed",
            bottom: 32,
            right: 100,
            zIndex: 10000,
          }}
        >
          {Object.keys(authUser).length === 0 && (
            <IconButton
              size="large"
              sx={{
                background: Colors.green,
                border: `1px solid ${Colors.green}`,
                transition: "all 0.2s ease-in-out",
                boxShadow: `0 0 10px 0 rgba(31, 166, 15, 0.5)`,
                "&:hover": {
                  transform: "scale(1.1)",
                  bgcolor: Colors.white,
                  color: Colors.white,
                  "& > svg": {
                    color: `${Colors.green} !important`,
                  },
                },
              }}
              onClick={() =>
                navigate(
                  `/${allPages?.find(
                    (item: any) => item?.default_key === "ContactUs"
                  )?.url_slug
                  }`
                )
              }
            >
              <CsIcon icon={"uil:file-edit-alt"} color={Colors.white} fontSize={30} />
            </IconButton>
          )}
        </Box>
      </Zoom>
      <Slide in={trigger} direction="left">
        <Box
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
            zIndex: 10000,
          }}
        >
          <IconButton
            size="large"
            sx={{
              background: Colors.green,
              border: `1px solid ${Colors.green}`,
              transition: "all 0.2s ease-in-out",
              boxShadow: `0 0 10px 0 rgba(31, 166, 15, 0.5)`,
              // "& > svg": {
              //   animation: `shake 0.7s infinite`,
              //   "@keyframes shake": {
              //     "0%": { transform: "scale(1)" },
              //     "25%": { transform: "scale(1.1)" },
              //     "50%": { transform: "scale(1)" },
              //     "75%": { transform: "scale(1.1)" },
              //     "100%": { transform: "scale(1)" },
              //   },
              // },
              "&:hover": {
                transform: "scale(1.1)",
                bgcolor: Colors.white,
                color: Colors.white,
                "& > svg": {
                  color: `${Colors.green} !important`,
                },
              },
            }}
            onClick={() =>
              window.open(
                `https://wa.me/+${data?.general?.wh_country_code}${data?.general?.whatsapp_number
                }?text=${encodeURIComponent(data?.general?.message)}`,
                "_blank"
              )
            }
          >
            <CsIcon
              icon={"ic:baseline-whatsapp"}
              color={Colors.white}
              fontSize={30}
            />
          </IconButton>
        </Box>
      </Slide>
    </>
  );
};

export default Whatsapp;
