import { useEffect } from "react";

const useDisableKeys = () => {
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      // if (process.env.REACT_APP_MODE === "PRODUCTION") {
      event.preventDefault();
      // }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      // if (process.env.REACT_APP_MODE === "PRODUCTION") {
      const forbiddenKeys = [
        "PageUp",
        "PageDown",
        "Home",
        "End",
        "Delete",
        // "ArrowUp",
        // "ArrowDown",
        // "ArrowLeft",
        // "ArrowRight",
        "F12",
      ];
      const forbiddenKeyCombinations = [
        { key: "I", ctrl: true, shift: true },
        { key: "C", ctrl: true, shift: true },
        { key: "J", ctrl: true, shift: true },
        { key: "s", ctrl: true },
        { key: "p", ctrl: true },
        { key: "c", ctrl: true },
        { key: "x", ctrl: true },
        { key: "u", ctrl: true },
      ];

      if (
        forbiddenKeys.includes(event.key) ||
        forbiddenKeyCombinations.some(
          (combo) =>
            event.key === combo.key &&
            (!combo.ctrl || event.ctrlKey) &&
            (!combo.shift || event.shiftKey)
        )
      ) {
        event.preventDefault();
      }
      // }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "PrintScreen" || event.key === "Snapshot") {
        navigator.clipboard.writeText("");
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
};

export default useDisableKeys;
