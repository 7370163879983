// ** React Impports
import { useState } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

// ** Third Party Imports
import { useFormik } from "formik";
import * as yup from "yup";
import { Fade, Zoom } from "react-awesome-reveal";

// ** Custom Imports
import CsTextField from "../../components/CsTextField";
import CsSelect from "../../components/csSelect";
import CsButton from "../../components/CsButton";
import CsMobileField from "../../components/CsMobileInput";
import CsIcon from "../../components/Icon";

// ** Store Imports
import { submitInquiry } from "../../Store/InquieryPopup/InquieryPopupAction";

// ** Helper Imports
import Colors from "../../Helper/Colors";
import countryCodes from "../../Helper/CountryCodes";
import CsImgBox from "../../components/CsImgBox";

interface InquiryPopupProps {
  name: string;
  email: string;
  contact_number: string;
  country: string;
  country_code: number;
}

interface InitalStd {
  value: string;
  label: string;
  key: string;
}

const InquiryPopup = (props: any) => {

  // props
  const { showInquiryPopup, setShowInquiryPopup } = props;

  // State
  const initialStd = {
    value: "91",
    label: "+91",
    key: "IN",
  }
  const [stdCode, setStdCode] = useState<InitalStd>(initialStd);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: InquiryPopupProps = {
    name: "",
    email: "",
    contact_number: "",
    country: "",
    country_code: 91,
  };

  const shema = yup.object({
    name: yup.string().required("Full name is required."),
    email: yup
      .string()
      .required("Email is required.")
      .email("Please enter valid email"),
    country: yup.string().required("Country is required."),
    contact_number: yup.string().required("Contact number is required."),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: shema,
    onSubmit: async (value: InquiryPopupProps) => {
      setLoading(true);
      const res = await submitInquiry({
        ...value,
        name: value.name.trim(),
        contact_number: value.contact_number.trim()
      });
      if (res?.status) {
        localStorage.setItem('email', value.email);
        formik.resetForm();
        setStdCode(initialStd);
        setShowInquiryPopup(false)
      }
      setLoading(false);
    },
  });

  const handleClose = () => {
    setShowInquiryPopup(false);
  };

  return (
    <>
      <Dialog open={showInquiryPopup} fullWidth maxWidth="lg" id="mui-dialog-model"
        sx={{
          "& .MuiPaper-root": {
            padding: { sm: "0rem", md: "2.3rem 2rem 3rem" },
            borderRadius: "1.5rem",
            mt: "5.5rem",
            overflowY: "auto",
          },
          zIndex: 9999
        }}
      >
        <DialogTitle sx={{ width: { xs: "85%", sm: "80%" }, m: "auto", mb: "10px" }}>
          <Fade direction="up" triggerOnce>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4">Quick Inquiry</Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CsIcon icon={"iconamoon:close"} fontSize={35} />
              </IconButton>
            </Box>
          </Fade>
        </DialogTitle>
        <DialogContent sx={{
          "& .MuiDialogContent-root": {
            padding: "0rem"
          },
          px: { xs: 0, sm: "auto" }
        }}>
          <Box sx={{ position: "relative", width: "100%" }}>
            <CsImgBox
              src={"/images/Pages/BlackPaperGrp3.png"}
              imgSx={{ width: "6rem", height: "auto" }}
              boxSx={{ top: "2rem", display: { xs: "none", sm: "block" } }}
            />
            <CsImgBox
              src={"/images/Pages/BlackPaperGrp3.png"}
              imgSx={{ width: "6rem", height: "auto" }}
              boxSx={{
                bottom: "0",
                right: "1rem",
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box sx={{ width: "80%", m: "auto" }}>
              <Fade direction="up" triggerOnce>
                <Box
                  sx={{
                    border: `0.0625rem solid ${Colors.black}`,
                    borderRadius: "1.8125rem",
                    p: { xs: "1rem", sm: "2rem" },
                  }}
                >
                  <Grid container sx={{ width: "100%", m: 0 }}>
                    {/* Full Name  */}
                    <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                      <Fade direction="left" triggerOnce>
                        <CsTextField
                          label={"Full Name"}
                          placeholder="Enter Full Name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Fade>
                    </Grid>
                    {/* Email Address  */}
                    <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                      <Fade direction="right" triggerOnce>
                        <CsTextField
                          label={"Email Address"}
                          placeholder="Enter Email Address"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Fade>
                    </Grid>
                    {/* Mobile Number  */}
                    <Grid item xs={12} sm={6} sx={{ pb: 3 }}>
                      <Fade direction="left" triggerOnce>
                        <CsMobileField
                          label="Contact Number"
                          fullWidth
                          id={"contact_number"}
                          name="contact_number"
                          placeholder={"Contact Number"}
                          type="text"
                          size="small"
                          value={formik.values.contact_number}
                          onChange={(e: any) => {
                            e.currentTarget.value = e.currentTarget.value
                              .replace(/[^0-9]/g, "")
                              .slice(0, 10);
                            formik.handleChange(e);
                          }}
                          error={
                            formik.touched.contact_number &&
                            Boolean(formik.errors.contact_number)
                          }
                          helperText={
                            formik.touched.contact_number &&
                            formik.errors.contact_number
                          }
                          stdcode={stdCode}
                          stdCodeChange={(val: {
                            value: string;
                            label: string;
                            country_code_initials: string;
                            key: string;
                          }) => {
                            formik.setFieldValue(
                              "country_code",
                              parseInt(val?.value)
                            );
                            setStdCode(val);
                          }}
                        />
                      </Fade>
                    </Grid>
                    {/* Country  */}
                    <Grid item xs={12} sm={6} sx={{ pl: { sm: 3 }, pb: 3 }}>
                      <Fade direction="left" triggerOnce>
                        <CsSelect
                          isCountrySelect
                          label={"Country"}
                          id={"country"}
                          name="country"
                          options={countryCodes?.map((option, index) => option.country_name)}
                          value={formik.values.country}
                          placeholder="Select Country"
                          onChange={(e: any, value: any) =>
                            formik.setFieldValue("country", value)
                          }
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country &&
                            formik.errors.country
                          }
                        />
                      </Fade>
                    </Grid>
                    {/* Submit  */}
                    <Grid item xs={12}>
                      <Zoom triggerOnce>
                        <CsButton
                          label="Submit"
                          variant="contained"
                          sx={{ mb: 2, textTransform: "none" }}
                          onClick={() => formik.handleSubmit()}
                          loading={loading}
                        />
                      </Zoom>
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InquiryPopup;
